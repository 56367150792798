import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Card } from 'react-bootstrap'
import { updateNotificationRuleChannel, updateNotificationRuleReceiver } from '../../../redux/reducers/notificationReducer'
import { useDispatch, useSelector } from 'react-redux'
import { MultiSelect } from 'primereact/multiselect'
import { Checkbox } from 'primereact/checkbox'

export const ReceiverChannelBlock: FC<any> = memo(({ item, name, id, itemId }) => {
  const dispatch = useDispatch()
  const { receiver, channel } = useSelector((state: any) => state.NotificationReducer)
  const inputStateId = item.map((item: any) => item.Id)
  const inputStateName = item.map((item: any) => item.Name)

  const [update, setUpdate] = useState(false)
  const [selectedChannels, setSelectedChannels] = useState<any>(item[0].Name ? inputStateId : [])
  const [all, setAll] = useState<any>({})
  const [key, setKey] = useState('')
  const [keyCheckbox, setKeyCheckbox] = useState<any>({})

  const activeChannel = useMemo(
    () =>
      channel
        .filter((item: any) => {
          const active = item.Receivers.some((item: any) => inputStateName.includes(item.Name))
          if (active) {
            return item
          }
        })
        .map((item: any) => {
          return {
            Id: item.Id,
            Name: item.Name,
          }
        }),
    [channel],
  )

  useEffect(() => {
    setUpdate(!update)
  }, [])

  useEffect(() => {
    let timeout: any = null
    if (update) {
      if (name === 'Receivers') {
        timeout = setTimeout(() => {
          dispatch(updateNotificationRuleReceiver({ data: selectedChannels, itemId, id }))
          dispatch(updateNotificationRuleChannel({ data: activeChannel, itemId, id }))
        }, 300)
      }
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [selectedChannels.length, activeChannel.length])

  const findChannel = useMemo(
    () =>
      channel?.map((item: any) => {
        return {
          label: item.Name,
          items: item.Receivers.map((item: any) => {
            return {
              value: item.Id,
              label: item.Name,
            }
          }),
        }
      }),
    [channel],
  )

  const findReceivers = useMemo(
    () =>
      receiver
        .filter((item: any) => !item?.Channel?.length)
        .map((item: any) => {
          return {
            value: item.Id,
            label: item.Name,
          }
        }),
    [receiver],
  )

  const channelsLabel = useMemo(() => {
    if (findReceivers.length) {
      return [
        ...findChannel,
        {
          label: 'Receivers outside of groups',
          items: findReceivers,
        },
      ]
    } else {
      return [...findChannel]
    }
  }, [findChannel, findReceivers])

  useEffect(() => {
    const findChennel = channelsLabel?.find((item: any) => item?.label === key)?.items
    const itemChennel = findChennel?.map((item: any) => item?.value)

    if (!keyCheckbox[key]) {
      const t = selectedChannels?.filter((item: any) => itemChennel?.includes(item))
      const test = [...(selectedChannels ?? []), ...(all[key] ?? [])]?.filter((item: any) => !t?.includes(item))
      setSelectedChannels(test)
    } else {
      setSelectedChannels(all[key]?.length ? [...(selectedChannels ?? []), ...(all[key] ?? [])] : selectedChannels)
    }
  }, [key, all])

  useEffect(() => {
    if (!selectedChannels.length) {
      setKeyCheckbox({})
    }

    const findActiveChannel = channelsLabel?.map((item: any) => {
      return {
        ...item,
        items: item?.items?.filter((el: any) => !selectedChannels?.includes(el?.value)),
      }
    })

    let test = findActiveChannel?.reduce((acc: any, rec: any) => {
      if (!rec.items?.length) {
        return {
          ...acc,
          [rec.label]: true,
        }
      } else {
        return {
          ...acc,
          [rec.label]: false,
        }
      }
    }, {})

    setKeyCheckbox(test)
  }, [selectedChannels.length])

  const onChangeHandler = (e: any) => {
    const key = e.target.value

    setKeyCheckbox((prev: any) => {
      return {
        ...prev,
        [key]: !prev[key],
      }
    })
    setKey(key)
    const findChennel = channelsLabel.find((item: any) => item.label === key)?.items
    const itemChennel = findChennel.map((item: any) => item.value)

    setAll((prev: any) => {
      return {
        ...prev,
        [key]: itemChennel,
      }
    })
  }

  const groupedItemTemplate = useCallback(
    (option: any) => {
      return (
        <li className="d-flex align-items-center country-item">
          <div className="p-checkbox p-component">
            <Checkbox inputId="binary" checked={keyCheckbox[option.label]} value={option.label} onChange={e => onChangeHandler(e)} />
            <label htmlFor="binary">{keyCheckbox[option.label]}</label>
          </div>
          <div>{option.label}</div>
        </li>
      )
    },
    [keyCheckbox],
  )

  return (
    <Card className={`accordion-custom border-none multiselect-demo`} style={{ overflow: 'visible' }}>
      <MultiSelect
        panelHeaderTemplate={() => <div></div>}
        value={selectedChannels.filter((item: any, index: number, arr: any) => arr.indexOf(item) === index)}
        options={channelsLabel}
        onChange={(e: any) => setSelectedChannels(e.value)}
        optionLabel="label"
        optionGroupLabel="label"
        optionGroupChildren="items"
        optionGroupTemplate={groupedItemTemplate}
        placeholder="Select..."
        display="chip"
      />
    </Card>
  )
})
