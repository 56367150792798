import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ConnectionString } from '../entity/configuration'
import { fetchGet, processError } from '../utils/fetch-utils'
import useVersion from './useVersion'

function useDefaultDbSettings(type: string, connectionString: ConnectionString, gatewayName: any): [boolean, any, any] {
  const dispatch = useDispatch()
  const [{ isDefaultSettings }, setIsDefaultSettings] = useState({
    isDefaultSettings: true,
  })
  const [defaultUser, setDefaultUser] = useState<ConnectionString | null>(null)

  useEffect(() => {
    const fetchDefaultUser = async () => {
      try {
        const fetchedData = await fetchGet('/api/SystemSettings/GetDefaultDBConnection', gatewayName)
        setDefaultUser(fetchedData)
      } catch (e) {
        processError(e, dispatch)
      }
    }

    fetchDefaultUser()
  }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type !== 'add' && defaultUser) {
      if (defaultUser.UserId === connectionString.UserId && defaultUser.Server === connectionString.Server && defaultUser.Port === connectionString.Port  ) {
        setIsDefaultSettings({ isDefaultSettings: true })
      } else {
        setIsDefaultSettings({ isDefaultSettings: false })
      }
    }
  }, [type, defaultUser, connectionString.UserId, connectionString.Server, connectionString.Port])

  return [
    isDefaultSettings,
    setIsDefaultSettings,
    {
      ...connectionString,
      Password: '',
      ...defaultUser,
    },
  ]

}



export type DefaultCheckboxState = { isDefaultSettings: boolean }
export function useDefaultDB(
  type: string,
  connectionString: ConnectionString,
  setConnectionString: (state: ConnectionString) => void,
  gatewayName: { gateway: string },
  inputState: any,
): [DefaultCheckboxState, (state: DefaultCheckboxState) => void] {
  const dispatch = useDispatch()
  const version: any = useVersion(gatewayName)


  const [defaultUser, setDefaultUser] = useState<ConnectionString | null>(null)
  const [isDefaultSettings, setIsDefaultSettings] = useState({
    isDefaultSettings: true,
  })


  useEffect(() => {
    const fetchDefaultUser = async () => {
      try {
        const fetchedData = await fetchGet('/api/SystemSettings/GetDefaultDBConnection', gatewayName)
        setDefaultUser({
          ...fetchedData,
          Password: '',
          flag: false,
           Database: inputState.ConnectionString.Database

        })

        if (type === 'add') {
          setConnectionString({
            ...connectionString,
            ...fetchedData,
            // Database: inputState.Name
          })
        }
      } catch (e) {
        processError(e, dispatch)
      }
    }

    fetchDefaultUser()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    function isDefaultDb(): boolean {
      if (
        connectionString.Server === defaultUser?.Server &&
        +connectionString.Port === +defaultUser?.Port &&
        connectionString.UserId === defaultUser?.UserId &&
        +connectionString.Password === +defaultUser?.Password &&
        connectionString.flag === defaultUser?.flag
      ) {
        return true
      }

      return false
    }

    if (isDefaultDb()) {
      setIsDefaultSettings({ isDefaultSettings: true })
    } else {
      setIsDefaultSettings({ isDefaultSettings: false })
    }
  }, [connectionString, defaultUser])

  const setDefaultDb = (state: DefaultCheckboxState) => {
    if (state.isDefaultSettings) {
      if (type === 'add') {
      setConnectionString({ ...connectionString, ...defaultUser,  Database: inputState.Name + '_' + version.GatewayVersion.split('.', 3).join('.') })
    } else {
        setConnectionString({ ...connectionString, ...defaultUser})
      }
    }

    setIsDefaultSettings(state)
  }

  return [isDefaultSettings, setDefaultDb]
}

export default useDefaultDbSettings
