import React, { ChangeEvent, useCallback, useState, KeyboardEvent, memo } from 'react'
import cls from '../chatBot.module.scss'
import { Button } from './Button'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { AddMessage } from '../../../redux/reducers/ChatBotReducer'
import { sendMessages } from '../servises'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'

export const Input = memo(() => {
  const dispatch = useDispatch()
  const { FetchStatus } = useSelector((state: RootState) => state.ChatBot)
  const [value, setValue] = useState<string>('')

  const sendMessage = useCallback(() => {
    if (!value.length) {
      return
    }
    dispatch(AddMessage({ text: value, role: 'user' }))
    setValue('')
    dispatch(sendMessages({ value: escape(value) }))
  }, [value])

  const onKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && value.length) {
        sendMessage()
      }
    },
    [value],
  )

  const onChangeMessage = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }, [])

  return (
    <div className={cls.wrapperInput}>
      <input
        disabled={FetchStatus.loading || FetchStatus.status !== 200}
        onKeyUp={onKeyPress}
        type="text"
        placeholder="Ask me a question..."
        value={value}
        onChange={onChangeMessage}
        className={cls.textMsg}
      />
      <Button text="SendMsg" cb={sendMessage} icon={faPaperPlane} cls={cls.pushMsg} placement="left" type="prompt" />
    </div>
  )
})
