import React from 'react'
import { ConnectionString } from '../../../entity/configuration'
import { DefaultCheckboxState } from '../../../hooks/useDefaultDbSettings'
import { buildControlsExtTwoPerLine, checkboxInput, passwordInput, textInput } from '../../../utils/controls'

interface IDbConnectionEditor {
  type: string
  state: ConnectionString
  setState: (state: ConnectionString) => void
  touched: any
  setTouched: any
  errors: any
  isDefaultSettings: DefaultCheckboxState
  setIsDefaultSettings: (state: any) => void
}

const DbConnectionEditor: React.FC<IDbConnectionEditor> = React.memo(({ type, state, setState, touched, setTouched, errors, isDefaultSettings, setIsDefaultSettings }) => {
  const dbInputs = buildControlsExtTwoPerLine(
    [textInput('Server'), textInput('Port'), textInput('UserId'), passwordInput('Password').setMode(type), textInput('Database')],
    state,
    setState,
    'database',
    touched,
    setTouched,
    errors,
  )

  const defaultCheckbox = buildControlsExtTwoPerLine([checkboxInput('isDefaultSettings')], isDefaultSettings, setIsDefaultSettings, 'database')

  if (type === 'modify' && state.flag) {
    errors.Password = true
  } else if (type === 'add' && !isDefaultSettings.isDefaultSettings) {
    errors.Password = false
  } else {
    errors.Password = false
  }

  const editor = (
    <div>
      {defaultCheckbox}
      {dbInputs}
    </div>
  )

  return editor
})

export default DbConnectionEditor
