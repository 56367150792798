import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { hideModal } from '../../redux/actions/modal-actions'
import { RootState } from '../../redux/reducers/rootReducer'
import TextInput from '../inputs/TextInput'

const ModalConfirm: React.FC = () => {
  const dispatch = useDispatch()
  const { modalData } = useSelector((state: RootState) => state.modals)
  const [inputState, setInputState] = useState({ Word: '' })

  const handleClose = () => dispatch(hideModal())
  const handleApprove = () => {
    dispatch(hideModal())
    if (modalData.onYes) {
      modalData.onYes()
    }
  }

  const wordIsIncorrect = () => {
    return inputState.Word !== 'Confirm'
  }

  const bodyText = typeof modalData.bodyText === 'function' ? modalData.bodyText() : modalData?.bodyText ?? ''

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton={true} className="color-dark font-500">
        <FormattedMessage id={modalData?.headerText ?? ''} />
      </Modal.Header>
      <Modal.Body>
        {bodyText}
        <TextInput state={inputState} setState={setInputState} name="Word" label={typeof bodyText === 'object' ? '' : 'modal.confirm.word'} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleApprove} disabled={wordIsIncorrect()}>
          <FormattedMessage id="ok" />
        </Button>
        <Button onClick={handleClose}>
          <FormattedMessage id="cancel" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(ModalConfirm)
