import { Action } from '../actions/actions'

export interface IFetchStatus {
  status?: number
  loading?: boolean
}

const INITIAL_STATE = {
  Messages: [
    {
      text:
        'Hi there 👋, how can I help?\n\n' +
        "I'm AI bot in beta stage trained on TP documentation. I can provide supplementary consultations on TP; also, sometimes I'm wrong.\n\n" +
        "So, for anything important, please contact our live tech support engineers. They'll be prompt to assist.",
      role: 'bot',
    },
  ],
  SuggestedQuestions: ['Is there a way to set up alerts for trading activity?', 'How can I view the logs in real-time?', 'How to connect the MT5 platform to Trade Processor?'],
  FetchStatus: {
    status: 200,
    loading: false,
  },
  Open: false,
}

function ChatBotReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case Action.ChatBotReset:
      return {
        ...state,
        Messages: [
          {
            text:
              'Hi there 👋, how can I help?\n\n' +
              "I'm AI bot in beta stage trained on TP documentation. I can provide supplementary consultations on TP; also, sometimes I'm wrong.\n\n" +
              "So, for anything important, please contact our live tech support engineers. They'll be prompt to assist.",
            role: 'bot',
          },
        ],
      }

    case Action.ChatBotSetMessage:
      return {
        ...state,
        Messages: [...state.Messages, action.data],
      }

    case Action.SetSuggestedQuestions:
      return {
        ...state,
        SuggestedQuestions: action.data,
      }

    case Action.SetFetchStatis:
      return {
        ...state,
        FetchStatus: {
          ...state.FetchStatus,
          ...action.data,
        },
      }
    case Action.UpdateOpen:
      return {
        ...state,
        Open: action.data,
      }
    default:
      return state
  }
}

export function AddMessage(data: { text: string; role: string }) {
  return { type: Action.ChatBotSetMessage, data }
}

export function AddSuggestedQuestions(data: string[]) {
  return { type: Action.SetSuggestedQuestions, data }
}

export function AddFetchStatus(data: IFetchStatus) {
  return { type: Action.SetFetchStatis, data }
}

export function UpdateOpen(data: boolean) {
  return { type: Action.UpdateOpen, data }
}

export function ResetMessage() {
  return { type: Action.ChatBotReset }
}

export default ChatBotReducer
