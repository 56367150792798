import { Action } from './actions'

export function showRightBar(name: string, data: any) {
  return {
    type: Action.ShowRightBar,
    payload: { name, data },
  }
}

export function hideRightBar() {
  return {
    type: Action.HideRightBar,
  }
}
