import { useCallback } from 'react'

function postfixer(postfix: string): (item: string) => string {
  return (item: string) => (Boolean(item) ? item + postfix : '')
}

function usePostfix(postfix: string): (item: string) => string {
  const func = useCallback(postfixer(postfix), [postfix])

  return func
}

export default usePostfix
