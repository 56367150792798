import React, { useState } from 'react';
import classNames from 'classnames';

function MouseOver({ children, className }) {
  const [isOver, setIsOver] = useState(false);

  return (
    <div
      className={classNames(className)}
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
    >
      {children(isOver)}
    </div>
  )
}

export default MouseOver;