import React from 'react';
import classNames from 'classnames';

const ColorPickerButton = ({ children, onClick, className, disabled }) => {
  
  const handleClick = () => {
    if (disabled) {
      return;
    }

    if (onClick) {
      onClick();
    }
  }

  return (
    <div className={classNames('color-picker_button', className)} onClick={handleClick} disabled={disabled}>
      {children}
    </div>
  )
}

export default ColorPickerButton;