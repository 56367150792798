import { Action } from '../actions/actions'

function volumeTransferReducer(
  state = {
    totalCount: 0,
    positions: [],
  },
  action: any,
) {
  switch (action.type) {
    case Action.GotVolumeTransfer:
      return {
        ...state,
        totalCount: action.data.TotalCount,
        positions: action.data.Data,
      }
    default:
      return state
  }
}

export default volumeTransferReducer
