import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GatewayEntity } from '../entity/system-settings'
import { fetchGet, processError } from '../utils/fetch-utils'

function useGateway(): GatewayEntity {
  const dispatch = useDispatch()
  const [gateway, setGateway] = useState(new GatewayEntity())

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await fetchGet('/api/gateways')
        if (fetchedData?.[0]) {
          setGateway(fetchedData?.[0])
        }
      } catch (e) {
        processError(e, dispatch)
      }
    }

    fetchData()
  }, [dispatch])

  return gateway
}

export default useGateway
