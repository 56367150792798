import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GatewayEntity } from '../entity/system-settings'
import { fetchGet, processError } from '../utils/fetch-utils'

function useData<T>(uri: string, initialState: T, gateway: GatewayEntity, lpName?: any): T {
  const dispatch = useDispatch()
  const [data, setData] = useState(initialState)

  useEffect(() => {
    const fetchData = async () => {
      try {
        let fetchedData = null
        if (lpName) {
          fetchedData = await fetchGet(uri, { gateway: gateway.Name }, lpName)
        } else {
          fetchedData = await fetchGet(uri, { gateway: gateway.Name })
        }

        setData(fetchedData)
      } catch (e) {
        processError(e, dispatch)
      }
    }

    if (gateway.Name) {
      fetchData()
    }
  }, [uri, gateway.Name, dispatch])

  return data
}

export default useData
