import { AppAccordion } from '@t4b/core/lib'
import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchPlatformExtSymbolsUpdate } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { blankInput, buildControlsExtTwoPerLine, checkboxInput, sselectInput, textInput } from '../../utils/controls'
import { IRightbar } from './rightbar-types'
import { alreadyExistFixExtSymbols, alreadyTest } from './lpRightbarUtils/utils'
import { transformEmptyStringMaxLength } from '../../utils/schema-utils'
import { buildMultiselectOptionsFromArray, buildSelectOption } from '../../utils/multiselect-utils'

const schema = {
  PlatformSymbol: yup.string().required(),
  ContractMultiplier: yup
    .string()
    .required()
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
  ContractSize: yup
    .number()
    .required()
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
  Type: yup.string().required(),
  BaseCurrency: yup
    .string()
    .transform((_, val) => (val === String(val) ? val : null))
    .required(),
  QuoteCurrency: yup.string().required(),
  CanBeUsedForConvert: yup.boolean(),
  Digits: yup
    .number()
    .integer()
    .required()
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value >= 0),
  VolumeStep: yup
    .string()
    .matches(/^[0-9.]+$/gi)
    .transform(transformEmptyStringMaxLength)
    .test('', '', value => value > 0)
    .required(),
}

const ExtSymbolsRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const { extSymbolMap } = useSelector((state: any) => state.extConfiguration)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation({ ...item, Type: buildSelectOption(item.Type) }, schema)

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    dispatch(
      fetchPlatformExtSymbolsUpdate({
        action: type,
        params: { ...params },
        body: inputState.Type.value !== 'CFD' ? { ...inputState, Type: inputState.Type.value } : { ...inputState, Type: inputState.Type.value, BaseCurrency: null, CanBeUsedForConvert: false },
      }),
    )
    dispatch(hideRightBar())
  }

  useEffect(() => {
    setInputState((prev: any) => {
      return {
        ...prev,
        BaseCurrency: prev.BaseCurrency === 'N/A' ? '' : prev.BaseCurrency,
        CanBeUsedForConvert: prev.CanBeUsedForConvert !== false,
      }
    })
  }, [])

  const inputs = buildControlsExtTwoPerLine(
    [
      textInput('PlatformSymbol')
        .disabled(type === 'modify')
        .errorMessage(alreadyExistFixExtSymbols(type, [...extSymbolMap.data], inputState, errors)),
      textInput('ContractMultiplier'),
      textInput('ContractSize'),
      sselectInput('Type', buildMultiselectOptionsFromArray(['Forex', 'CFD'])),
      textInput('BaseCurrency')
        .skipWhen(inputState.Type.value === 'CFD')
        .errorMessage(alreadyTest(inputState, errors)),

      textInput('QuoteCurrency'),
      blankInput(),
      checkboxInput('CanBeUsedForConvert').skipWhen(inputState.Type.value === 'CFD'),
      textInput('Digits'),
      textInput('VolumeStep'),
    ],
    inputState,
    setInputState,
    'fix-symbols',
    touched,
    setTouched,
    errors,
  )

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`symbol-map.title`} />,
          item: inputs,
        }}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" />
      </Button>
    </>
  )
})

export default ExtSymbolsRightbar
