import { AppAccordion } from '@t4b/core/lib'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { ConnectionString } from '../../entity/configuration'
import { convertPlatformFields, Mt5PlatformEntity } from '../../entity/platforms'
import withGateway, { IGatewayProp } from '../../hocs/withGateway'
import { useDefaultDB } from '../../hooks/useDefaultDbSettings'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchAddPlatform, modifyPlatform } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, passwordInput, sselectInput, textInput, timespanInput } from '../../utils/controls'
import DbConnectionEditor from '../configuration/system/DbConnectionEditor'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'

interface IMT5PlatformEdit extends IGatewayProp {
  data: any
  isValidParent?(): boolean
}

const MT5PlatformEdit: React.FC<IMT5PlatformEdit> = ({ data: { item, type, port }, isValidParent, gateway, gatewayName }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new Mt5PlatformEntity(item), Mt5PlatformEntity.schema(type))
  const [connectionString, setConnectionString, connTouched, setConnTouched, connErrors, isConnValid] = useFormValidation(
    new ConnectionString(item.ConnectionString),
    ConnectionString.schema(type),
  )

  const ref = useRef<any>(null)
  const dbRef = useRef<any>(null)

  useEffect(() => {
    setInputState({
      ...inputState,
      Name: item.Name,
    })
    setConnectionString({
      ...connectionString,
      Database: item.ConnectionString.Database,
    })
  }, [item.Name]) // eslint-disable-line react-hooks/exhaustive-deps

  const [isDefaultSettings, setDefaultDb] = useDefaultDB(type, connectionString, setConnectionString, gatewayName, inputState)

  const dbEditor = (
    <DbConnectionEditor
      type={type}
      state={connectionString}
      setState={setConnectionString}
      touched={connTouched}
      setTouched={setConnTouched}
      errors={connErrors}
      isDefaultSettings={isDefaultSettings}
      setIsDefaultSettings={setDefaultDb}
    />
  )

  const [, portMt5] = item.GatewayAddress.split(':')
  const newPort = port.map(String).filter((elem: any) => elem !== String(portMt5))

  const alreadyExist = () => {
    if (port.map(String).includes(String(inputState.GatewayPort)) && type === 'add') {
      errors.GatewayPort = true
      return 'Аlready exists'
    }

    if (newPort?.map(String).includes(String(inputState.GatewayPort))) {
      errors.GatewayPort = true
      return 'Аlready exists'
    }
    return ''
  }

  const editPlatform = buildControlsExtTwoPerLine(
    [
      textInput('GatewayAddress'),
      textInput('GatewayPort').errorMessage(alreadyExist()),
      textInput('GatewayLogin'),
      passwordInput('GatewayPassword', type === 'add' ? 'setup' : 'change'),
      textInput('ServerAddress'),
      textInput('ServerPort'),
      textInput('ManagerLogin'),
      passwordInput('ManagerPassword', type === 'add' ? 'setup' : 'change'),
      textInput('ServerOwner'),
    ],
    inputState,
    setInputState,
    'platform.mt5',
    touched,
    setTouched,
    errors,
  )

  const options = [
    { value: 'Disabled', label: 'Disabled' },
    { value: 'ExecutionOnly', label: 'Only for LPs with executions' },
    { value: 'All', label: 'For all LPs in aggregation pool' },
  ]

  const editSnapshots = buildControlsExtTwoPerLine(
    [sselectInput('SaveLpBookSnapshots', options), checkboxInput('SavePlatformBookSnapshot')],
    inputState,
    setInputState,
    'Snapshots',
    touched,
    setTouched,
    errors,
  )

  const handleSave = () => {
    const isMainValid = isValid()
    let isDbValid = isConnValid()

    if (isValidParent) {
      let validName = isValidParent()
      if (!validName) {
        return
      }
    }

    if (!isMainValid) {
      ref.current?.open()
    }

    if (!isDbValid) {
      dbRef.current?.open()
    }

    if (!isMainValid || !isDbValid) {
      return
    }

    dispatch(hideRightBar())
    if (type === 'add') {
      dispatch(
        fetchAddPlatform({
          action: 'add',
          params: { Gateway: gateway.Name },
          body: {
            ...convertPlatformFields(inputState),
            ConnectionString: connectionString,
            SaveLpBookSnapshots: inputState.SaveLpBookSnapshots.value,
          },
        }),
      )
    } else if (type === 'modify') {
      dispatch(
        modifyPlatform({
          action: 'modify',
          params: { Gateway: gateway.Name },
          body: {
            ...convertPlatformFields(inputState),
            ConnectionString: connectionString,
            SaveLpBookSnapshots: inputState.SaveLpBookSnapshots.value,
          },
        }),
      )
    }
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.edit.mt5" values={{ Type: type === 'add' ? 'MT5' : `Modify ${item.Name} (MT5)` }} />,
          item: editPlatform,
        }}
        ref={ref}
        render={ref?.current}
        isHidden={false}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.conn-string" />,
          item: dbEditor,
        }}
        ref={dbRef}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.Snapshots" />,
          item: editSnapshots,
        }}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" />
      </Button>
    </>
  )
}

export default withGateway(MT5PlatformEdit)
