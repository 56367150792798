export class PaginationState {
  public Page: number
  public Pages: number
  public Count: number

  constructor(page: number, pages: number, count: number) {
    this.Page = page
    this.Pages = pages
    this.Count = count
  }
}

export const paginationDefault = new PaginationState(1, 1, 250)

export const defaultPaginationSizes = [25, 50, 100, 250]
export const defaultPaginationSizesAll = [25, 50, 100, 250, 'All']
