export interface IQuote {
  Volume: number
  Price: number
}

export interface IOrderBook {
  Symbol?: string
  Time?: number
  Bid: IQuote[]
  Ask: IQuote[]
}

export interface IQuoteData {
  SourceName?: string
  SourceType?: string
  OrderBook: IOrderBook
}

export function emptyQuoteData() {
  return {
    SourceName: '',
    SourceType: '',
    OrderBook: {
      Symbol: '',
      Time: 0,
      Ask: [],
      Bid: [],
    },
  }
}

export function makeKey(sourceData: any, symbol: string): string {
  return `${sourceData.SourceType}_${sourceData.SourceName}_${symbol}`
}
