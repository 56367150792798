import React, { FC, useEffect, useRef, useState } from 'react'
import { useFormValidation } from '../../hooks/useFormValidation'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { useDispatch, useSelector } from 'react-redux'
import { FeedExecPresetEntity } from '../../entity/Preset-entitu'
import { buildControlsExtTwoPerLine, checkboxInput, textInput } from '../../utils/controls'
import { AppAccordion } from '@t4b/core'
import {
  addProcessingRule,
  addRoutingRule,
  changeAggregationPool,
  changeAggregationPoolPreset,
  changeExecPreset,
  changeExecutionLpOnePreset,
  changeProcessingRuleSelect,
  changeRoutingRule,
  disableProcessingRule,
  enableProcessingRule,
  executionConfigurationChanged,
} from '../../redux/actions/execution/execution-actions'
import { Action } from '../../redux/actions/actions'
import { alreadyExistPresetBar } from './lpRightbarUtils/utils'
import { Schedule } from '../../entity/configuration'
import AppSchedule from '../AppSchedule'

const ExecPresetBar: FC<any> = ({ data: { type, item, prevPresetName } }) => {
  const dispatch = useDispatch()
  const executionConfiguration = useSelector((state: any) => state.executionConfiguration)
  const scheduleRef = useRef<any>(null)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new FeedExecPresetEntity(item), FeedExecPresetEntity.schema)
  const [schedule, setSchedule, scheduleTouched, setScheduleTouched, scheduleErrors, isScheduleValid] = useFormValidation(
    new Schedule({ ...item, ScheduleType: { value: item.ScheduleType, label: item.ScheduleType }, Begin: item.ScheduleType !== 'Range' ? Date.now() : item.Begin, End: item.ScheduleType !== 'Range' ? Date.now() : item.End }),
    Schedule.schema,
  )

  const table: any = {}
  const tablePreset = executionConfiguration.Profile.filter((item: FeedExecPresetEntity) => !table[item?.Name] && (table[item?.Name] = 1))

  const [findIdMax, setFindIdMax] = useState(executionConfiguration.Profile.map((item: any) => Math.abs(item.Id)))
  useEffect(() => {
    setFindIdMax(Math.max(...executionConfiguration.Profile.map((item: any) => Math.abs(item.Id))))
  }, [executionConfiguration.Profile, executionConfiguration.Profile.lenght])

  const handleSave = () => {
    const cond1 = isValid()
    if (!cond1) {
      return
    } else {
      if (type === 'add') {
        dispatch({
          type: Action.GotExecPreset,
          data: new FeedExecPresetEntity({
            ...inputState,
            ...schedule,
            ScheduleType: schedule.ScheduleType.value,
            Id: -1 * (executionConfiguration.Profile.length === 0 ? executionConfiguration.Profile.length + 1 : findIdMax + 1),
          }),
        })
        dispatch(executionConfigurationChanged())
        dispatch(hideRightBar())
      }
      if (type === 'modify') {
        executionConfiguration.lps.forEach((el: any) => {
          if (el.bunchProfiles.includes(prevPresetName)) {
            dispatch(
              changeExecutionLpOnePreset({
                ...el,
                bunchProfiles: [...el.bunchProfiles.filter((item: any) => item !== prevPresetName), inputState.Name],
                Profile: inputState.Name,
              }),
            )
          }
        })
        executionConfiguration.routingRules.forEach((el: any) => {
          if (el.Profile === prevPresetName) {
            dispatch(
              changeRoutingRule({
                ...el,
                Profile: inputState.Name,
                Enabled: inputState.Enabled,
                Schedule: schedule.ScheduleType.value !== 'Custom' ? { ...schedule, ScheduleType: schedule.ScheduleType.value } : { ...el.Schedule, ScheduleType: el.Schedule.ScheduleType.value },
              }),
            )
          }
        })
        executionConfiguration.aggregationPools.forEach((el: any) => {
          if (el.Profile === prevPresetName) {
            dispatch(
              changeAggregationPoolPreset({
                ...el,
                Profile: inputState.Name,
                bunchProfiles: [...el.bunchProfiles.filter((item: any) => item !== prevPresetName), inputState.Name],
              }),
            )
          }
        })
        executionConfiguration.processingRules.forEach((el: any) => {
          if (el.Profile === prevPresetName) {
            dispatch(
              changeProcessingRuleSelect(
                {
                  ...el,
                  Profile: inputState.Name,
                  Enabled: inputState.Enabled,
                },
                true,
              ),
            )
            if (!inputState.Enabled) {
              dispatch(disableProcessingRule(el))
            } else {
              dispatch(enableProcessingRule(el))
            }
          }
        })
        dispatch(
          changeExecPreset({
            ...item,
            ...schedule,
            ScheduleType: schedule.ScheduleType.value,
            Id: item.Id,
            Enabled: inputState.Enabled,
            Name: inputState.Name,
            Description: inputState.Description,
            flag: true,
            bunchProfiles: [...item.bunchProfiles, inputState.Name],
          }),
        )
        dispatch(executionConfigurationChanged())
        dispatch(hideRightBar())
      }

      if (type === 'clone') {
        dispatch({
          type: Action.GotExecPreset,
          data: new FeedExecPresetEntity({
            ...inputState,
            Id: -1 * (executionConfiguration.Profile.length === 0 ? executionConfiguration.Profile.length + 1 : executionConfiguration.Profile.length + 1),
            ...schedule,
            ScheduleType: schedule.ScheduleType.value,
          }),
        })

        executionConfiguration.lps.forEach((el: any) => {
          if (el.bunchProfiles.includes(prevPresetName)) {
            dispatch(
              changeExecutionLpOnePreset({
                ...el,
                bunchProfiles: [...el.bunchProfiles, inputState.Name],
                Profile: inputState.Name,
              }),
            )
          }
        })

        executionConfiguration.processingRules.forEach((el: any) => {
          if (el.Profile === prevPresetName) {
            const newRule = el.Rules.map((item: any) => {
              return {
                ...item,
                Id: el.Id - 1000,
              }
            })
            dispatch(
              addProcessingRule({
                ...el,
                Profile: inputState.Name,
                LpPool: el.LpPool,
                flag: true,
                Name: `${el.Name}`,
                Id: el.Id - 1000,
                Rules: newRule,
              }),
            )
          }
        })
        executionConfiguration.routingRules.forEach((el: any) => {
          if (el.Profile === prevPresetName) {
            dispatch(
              addRoutingRule({
                ...el,
                Id: el.Id - 1000,
                Schedule: { ...schedule, ScheduleType: schedule.ScheduleType.value },
                ProcessorId: -1 * (el.ProcessorId - 1000),
                Profile: inputState.Name,
                Name: `${el.Name}`,
                flag: true,
              }),
            )
          }
        })
        executionConfiguration.aggregationPools.forEach((el: any) => {
          if (el.Profile === prevPresetName) {
            dispatch(
              changeAggregationPool({
                ...el,
                Profile: inputState.Name,
                bunchProfiles: [...el.bunchProfiles, inputState.Name],
                flag: true,
              }),
            )
          }
        })

        dispatch(executionConfigurationChanged())
        dispatch(hideRightBar())
      }
    }
  }

  const controls = buildControlsExtTwoPerLine(
    [textInput('Name').errorMessage(alreadyExistPresetBar(type, [...tablePreset], inputState, errors)), textInput('Description'), checkboxInput('Enabled')],
    inputState,
    setInputState,
    'preset',
    touched,
    setTouched,
    errors,
  )

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`preset.${type}`} />,
          item: controls,
        }}
        isHidden={false}
      />
      <AppSchedule flagSchedule={type} state={schedule} touched={scheduleTouched} setTouched={setScheduleTouched} errors={scheduleErrors} isValid={isScheduleValid} setState={setSchedule} ref={scheduleRef} type={type} />
      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
}

export default ExecPresetBar
