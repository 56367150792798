import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import MouseOver from './MouseOver';

function prepareColorName(colorName) {
  if (colorName.startsWith('clr')) {
    return colorName.slice(3);
  }
  return colorName;
}

const ColorPickerValue = React.memo(({ color, onDelete }) => {

  // console.log('render value');
  const handleDelete = () => {
    onDelete(color)
  }

  const iconStyles = isOver => ({
    width: '22px',
    height: '22px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    color: 'gray'
  });

  const previewStyles = {
    width: '22px',
    height: '22px',
    borderRadius: '4px',
    backgroundColor: color.color,
    border: '1px solid lightgray'
  }

  return (
    <div className='color-picker_value'>
      <div style={previewStyles}/>
      <div style={{
        padding: '3px 3px 3px 6px',
        fontSize: '85%',
        boxSizing: 'border-box',
        color: 'black'
      }}>
        {prepareColorName(color.name).replace(/([a-z](?=[A-Z]))/g, '$1 ')}
      </div>

      <MouseOver className='d-flex'>
        {(isOver) => (
          <div
            onClick={handleDelete}
            style={iconStyles(isOver)}
          >
            <div style={{height: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'gray'}}>
              <FontAwesomeIcon icon={faTimes} size='xs'/>
            </div>
          </div>
        )}
      </MouseOver>
    </div>
  )
});

export default ColorPickerValue;