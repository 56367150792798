import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

const NotEnoughPrivelege: React.FC = () => {
  const { auth: isAuthenticated } = useSelector((state: any) => state)

  return !isAuthenticated ? (
    <Redirect to="/login" />
  ) : (
    <h2>
      <FormattedMessage id="not-enough-privelege" />
    </h2>
  )
}
export default NotEnoughPrivelege
