import { faArrowLeftRotate, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { modifyGlobalSymbolMap } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildHTTPGetOptions, checkResponse, processError } from '../../utils/fetch-utils'
import { buildMultiselectOptionsFromArray, buildSelectOption, optionsToStrings } from '../../utils/multiselect-utils'
import { buildTableHeadersFromArray } from '../../utils/table-utils'
import MultiSelectInput from '../inputs/MultiSelectInput'
import TextInput from '../inputs/TextInput'
import { useFormValidation } from '../../hooks/useFormValidation'
import * as yup from 'yup'
import { AppAccordion, AppTable } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'
import { buildControlsExtTwoPerLine, sselectInput, textInput } from '../../utils/controls'
import NewSearchableSelectInput from '../inputs/NewSearchableSelectInput'

const GlobalSymbolMapRightbar: React.FC<IRightbar> = props => {
  const dispatch = useDispatch()
  const { type, item, params, SyntSymbols, flag, SyntSymbolsState, flagIndex, SyntIndex, SyntIndexState } = props.data
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    {
      Symbol: item?.Symbol,
      Description: item?.Description,
      Aliases: item?.Aliases,
      Digits: item?.Digits,
      State: { value: item?.State, label: item?.State },
      Type: { value: item?.Type, label: item?.Type },
    },
    {
      Symbol: yup
        .string()
        .matches(/^[^,]+$/gi)
        .required(),
      Digits: yup
        .string()
        .matches(/^[0-9]+$/gi)
        .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value >= 0),
    },
  )

  const { gateway } = useSelector((state: any) => state.gateways)

  useEffect(() => {
    if (inputState.State.value === '' || inputState.State.value === 0) {
      setInputState((prev: any) => {
        return {
          ...prev,
          State: { value: 'Active', label: 'Active' },
        }
      })
    }
    if (inputState.Type.value === '' || inputState.Type.value === 0) {
      setInputState((prev: any) => {
        return {
          ...prev,
          Type: { value: 'Forex', label: 'Forex' },
        }
      })
    }
  }, [inputState]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gateway.Name) {
      const newState = {
        Gateway: gateway.Name,
      }

      const url = new URL('/api/globalSymbolMap/symbols', document.location.origin)
      url.searchParams.set('gateway', gateway.Name)

      fetch(url.toString(), buildHTTPGetOptions())
        .then((response: Response) => checkResponse(response))
        .then((data: any) => {
          const lpsData: any = {}
          data.Lps.forEach((lp: any) => {
            lpsData[lp.Name] = lp.Symbols
          })

          const platformsData: any = {}
          data.Platforms.forEach((platform: any) => {
            platformsData[platform.Name] = buildMultiselectOptionsFromArray(platform.Symbols)
          })

          setInputState({
            ...inputState,
            ...newState,
            PlatformsData: platformsData,
            LpsData: lpsData,
          })
        })
        .catch((error: Error) => processError(error, dispatch))
    }
  }, [gateway]) // eslint-disable-line react-hooks/exhaustive-deps

  const [lpState, setLpState] = useState(
    item.LpSymbols.map((item: any) => {
      return {
        Lp: buildSelectOption(item.Lp),
        Symbol: buildSelectOption(item.Symbol),
      }
    }),
  )

  const buildLpTable = () => {
    const filterDisabled = lpState.map((item: any) => item.Lp.value)
    const newLp = Object.keys(inputState.LpsData || {}).filter((item: any) => !filterDisabled.includes(item))

    const handlePlus = () => {
      if (!newLp.length) {
        return
      }
      let symbol = ''
      if (newLp[0]) {
        symbol = (inputState.LpsData[newLp[0]] && inputState.LpsData[newLp[0]][0]) || ''
      }

      setLpState([...lpState, { Lp: { value: newLp[0], label: newLp[0] }, Symbol: { value: symbol, label: symbol } }])
    }

    const lpRows = lpState.map((elem: any, index: number) => {
      const handleDelete = () => {
        const newState = [...lpState]
        newState.splice(index, 1)
        setLpState(newState)
      }

      const handleStateChange = (newState: any) => {
        const newStateArr = [...lpState]
        newStateArr.splice(index, 1, newState)
        setLpState(newStateArr)
      }

      const options = buildMultiselectOptionsFromArray(Object.keys(inputState.LpsData || {})).map((item: any) => {
        if (!filterDisabled.includes(item.value)) {
          return {
            ...item,
            isDisabled: false,
          }
        } else {
          return {
            ...item,
            isDisabled: true,
          }
        }
      })

      return (
        <tr key={index}>
          <td style={{ fontSize: '16px', textAlign: 'start' }}>
            <NewSearchableSelectInput
              placement="bottom"
              state={lpState[index]}
              setState={handleStateChange}
              name="Lp"
              options={options}
              className=" settings-block__field mb-3 w-300"
              isSearchable={false}
            />
          </td>
          <td style={{ fontSize: '16px', textAlign: 'start' }}>
            <NewSearchableSelectInput
              state={lpState[index]}
              placement="bottom"
              setState={handleStateChange}
              name="Symbol"
              options={buildMultiselectOptionsFromArray(inputState.LpsData && inputState.LpsData[elem.Lp.value])}
              className=" settings-block__field mb-3 w-300"
              isSearchable={false}
            />
          </td>
          <td className="h-100">
            <Button variant="link" className="t4b-text-gray p-0 mt-1" onClick={handleDelete}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </td>
        </tr>
      )
    })

    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      newLp.length
        ? lpRows.push(
            <tr key="plus-row" className="cursor-pointer" onClick={handlePlus}>
              <td colSpan={3}>
                <FontAwesomeIcon icon={faPlus} />
              </td>
            </tr>,
          )
        : null
    }

    const header = buildTableHeadersFromArray(
      ['Lp', 'Symbol', 'Actions'].map((elem: string) => ({
        name: elem,
        show: true,
      })),
      'global-symbol-map.Lp',
    )

    const tableLps = <AppTable tableHeader={header} tableData={lpRows} scrollOptions={{ x: 'scroll', y: 'visible-hidden' }} />
    return <div style={{ position: 'relative', zIndex: 3232323 }}>{tableLps}</div>
  }

  const [platformState, setPlatformState] = useState(
    item.PlatformSymbols.map((platform: any) => {
      return {
        ...platform,
        Platform: buildSelectOption(platform.Platform),
        Symbol: buildMultiselectOptionsFromArray(platform.Symbol),
      }
    }),
  )

  const filterDisabledPlatform = platformState.map((item: any) => item.Platform.value)
  const newLp = Object.keys(inputState.PlatformsData || {}).filter((item: any) => !filterDisabledPlatform.includes(item))

  const handlePlus = () => {
    if (!newLp.length) {
      return
    }
    setPlatformState([
      ...platformState,
      {
        Platform: { value: newLp[0] || '', label: newLp[0] || '' },
        Symbol: '',
      },
    ])
  }

  const platformRows = platformState.map((elem: any, index: number) => {
    const handleDelete = () => {
      const newState = [...platformState]
      newState.splice(index, 1)
      setPlatformState(newState)
    }

    const handleStateChange = (newState: any) => {
      const newStateArr = [...platformState]
      newStateArr.splice(index, 1, newState)
      setPlatformState(newStateArr)
    }

    const handlePlatformChange = (newState: any) => {
      const newStateArr = [...platformState]
      newStateArr.splice(index, 1, { ...newState, Symbol: [] })
      setPlatformState(newStateArr)
    }

    const options = buildMultiselectOptionsFromArray(Object.keys(inputState.PlatformsData || {})).map((item: any) => {
      if (!filterDisabledPlatform.includes(item.value)) {
        return {
          ...item,
          isDisabled: false,
        }
      } else {
        return {
          ...item,
          isDisabled: true,
        }
      }
    })

    return (
      <tr key={index}>
        <td style={{ fontSize: '16px', textAlign: 'start' }}>
          <NewSearchableSelectInput
            placement="bottom"
            state={platformState[index]}
            setState={handlePlatformChange}
            name="Platform"
            options={options}
            className="settings-block__field mb-3 w-300"
            isSearchable={false}
          />
        </td>
        <td style={{ fontSize: '16px', textAlign: 'start' }}>
          <MultiSelectInput
            state={platformState[index]}
            setState={handleStateChange}
            name="Symbol"
            options={inputState.PlatformsData && inputState.PlatformsData[elem.Platform.value]}
            className="m-0 w-300"
            symbolValid={platformState[index]?.Symbol?.length}
            schema={yup
              .array()
              .of(
                yup.object().shape({
                  value: yup.string().required(),
                  label: yup.string().required(),
                }),
              )
              .required()}
          />
        </td>
        <td className="h-100">
          <Button variant="link" className="t4b-text-gray p-0 mt-1" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </td>
      </tr>
    )
  })

  {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    newLp.length
      ? platformRows.push(
          <tr key="plus-row" className="cursor-pointer" onClick={handlePlus}>
            <td colSpan={3}>
              <FontAwesomeIcon icon={faPlus} />
            </td>
          </tr>,
        )
      : null
  }

  const header = buildTableHeadersFromArray(
    ['Platform', 'PlatformSymbols', 'Actions'].map((elem: string) => ({
      name: elem,
      show: true,
    })),
    'global-symbol-map',
  )

  const tablePlatforms = <AppTable tableHeader={header} tableData={platformRows} scrollOptions={{ x: 'visible-hidden', y: 'visible-hidden' }} />

  const handleSave = () => {
    if (!isValid()) {
      return
    }

    const platformValid = platformState.filter((platform: any) => !platform.Symbol.length)
    if (platformValid.length !== 0) {
      return
    }

    dispatch(
      modifyGlobalSymbolMap({
        action: type,
        params: { ...params },
        body: {
          ...item,
          Symbol: inputState.Symbol,

          Aliases: inputState?.Aliases.includes(',') ? inputState?.Aliases?.split(',') : [inputState?.Aliases].flat(Infinity),
          Digits: inputState.Digits,
          State: inputState.State.value,
          Type: inputState.Type.value,

          Description: inputState.Description,
          LpSymbols: lpState.map((item: any) => {
            return {
              Lp: item.Lp.value,
              Symbol: item.Symbol.value,
            }
          }),
          PlatformSymbols: platformState.map((platform: any) => ({
            Platform: platform.Platform.value,
            Symbol: optionsToStrings(platform.Symbol),
          })),
        },
      }),
    )
    if (SyntSymbols || SyntIndex) {
      const type = SyntIndexState?.Id ? 'modify' : 'add'
      const typeS = SyntSymbolsState?.Id ? 'modify' : 'add'

      ;(flag &&
        SyntSymbols({
          type: typeS,
          SyntSymbolsState: {
            Id: SyntSymbolsState?.Id ? SyntSymbolsState?.Id : null,
            Children: SyntSymbolsState.Children,
            Digits: SyntSymbolsState.Digits,
            FinalCoefficient: SyntSymbolsState.FinalCoefficient,
            GlobalSymbolName: inputState.Symbol ? { value: inputState.Symbol, label: inputState.Symbol } : SyntSymbolsState.GlobalSymbolName?.value,
            TickSize: SyntSymbolsState.TickSize,
            Description: SyntSymbolsState.Description,
          },
        })) ||
        (flagIndex &&
          SyntIndex({
            type: type,
            SyntIndexState: {
              Id: SyntIndexState?.Id ? SyntIndexState?.Id : null,
              Children: SyntIndexState.Children,
              Digits: SyntIndexState.Digits,
              FinalCoefficient: SyntIndexState.FinalCoefficient,
              GlobalSymbolName: inputState.Symbol ? { value: inputState.Symbol, label: inputState.Symbol } : SyntIndexState.GlobalSymbolName?.value,
              TickSize: SyntIndexState.TickSize,
              Description: SyntIndexState.Description,
            },
          }))
    } else {
      dispatch(hideRightBar())
    }
  }

  const inputs = buildControlsExtTwoPerLine(
    [
      textInput('Aliases'),
      textInput('Digits'),
      sselectInput('State', buildMultiselectOptionsFromArray(['Active', 'Inactive', 'Expired', 'Deleted']), false),
      sselectInput('Type', buildMultiselectOptionsFromArray(['Forex', 'CFD', 'Stock', 'Futures', 'Bond', 'Option']), false),
    ],
    inputState,
    setInputState,
    'global-symbol-map',
    touched,
    setTouched,
    errors,
  )

  const ref = useRef(null)

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <div className="arrow-back">
          {(flag || flagIndex) && (
            <FontAwesomeIcon
              icon={faArrowLeftRotate}
              onClick={() => {
                const type = SyntIndexState?.Id ? 'modify' : 'add'
                const typeS = SyntSymbolsState?.Id ? 'modify' : 'add'

                flag &&
                  SyntSymbols({
                    type: typeS,
                    SyntSymbolsState: {
                      Id: SyntSymbolsState.Id,
                      Children: SyntSymbolsState.Children,
                      Digits: SyntSymbolsState.Digits,
                      GlobalSymbolName: SyntSymbolsState.GlobalSymbolName,
                      Description: SyntSymbolsState.Description,
                      FinalCoefficient: SyntSymbolsState.FinalCoefficient,
                    },
                  })

                flagIndex &&
                  SyntIndex({
                    type: type,
                    SyntIndexState: {
                      Id: SyntIndexState.Id,
                      Description: SyntIndexState.Description,
                      Children: SyntIndexState.Children,
                      Digits: SyntIndexState.Digits,
                      FinalCoefficient: SyntIndexState.FinalCoefficient,
                      GlobalSymbolName: SyntIndexState.GlobalSymbolName,
                      TickSize: SyntIndexState.TickSize,
                    },
                  })
              }}
            />
          )}
          <FormattedMessage id={`global-symbol-map.${type}`} />
        </div>
      </Card.Header>
      <Card.Body>
        <TextInput state={inputState} setState={setInputState} touched={touched} setTouched={setTouched} errors={errors} name="Symbol" label="global-symbol-map.Symbol" />

        <TextInput state={inputState} setState={setInputState} touched={touched} setTouched={setTouched} errors={errors} name="Description" label="global-symbol-map.Description" />

        {inputs}

        <AppAccordion
          item={{
            title: <FormattedMessage id={`global-symbol-map.lp.${type}`} />,
            item: buildLpTable(),
          }}
          style={{ margin: '0 -20px' }}
          isHidden={false}
          ref={ref}
          render={ref.current}
        />

        <AppAccordion
          item={{
            title: <FormattedMessage id={`global-symbol-map.platform.${type}`} />,
            item: tablePlatforms,
          }}
          style={{ margin: '0 -20px' }}
          isHidden={false}
          ref={ref}
          render={ref.current}
        />

        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" />
        </Button>
      </Card.Body>
    </Card>
  )
}
export default GlobalSymbolMapRightbar
