import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { buildHTTPGetOptions, checkResponse, processError } from '../../utils/fetch-utils'
import { buildInfoItems, buildVolumeDistribution, infoDirection, infoPrice, infoProfit, infoTime } from '../../utils/info-item-utils'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'

const FixPositions: React.FC<IRightbar> = ({ data: params }) => {
  const [position, setPosition] = useState<any>({})
  const dispatch = useDispatch()

  useEffect(() => {
    const url = new URL('/api/position', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('platform', params.Platform)
    url.searchParams.set('session', params.position.Session)
    url.searchParams.set('account', params.position.Account)
    url.searchParams.set('symbol', params.position.Symbol)
    url.searchParams.set('pool', params.Pool)
    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => setPosition(data))
      .catch((error: Error) => processError(error, dispatch))
  }, [params, dispatch])
  const additionalComponent = (
    <div className="row">
      <div className="col-6">{buildInfoItems([infoTime('OpenTime'), infoProfit('Profit')], position, 'fixapipositions')}</div>
      <div className="col-6">{buildInfoItems([infoTime('UpdateTime'), 'Group'], position, 'fixapipositions')}</div>
    </div>
  )

  return (
    <Card>
      <Card.Header
        style={{
          color: 'rgb(141, 171, 196)',
          fontWeight: 500,
          fontSize: '13px',
          textTransform: 'uppercase',
        }}
      >
        <FormattedMessage id="position-details" />
      </Card.Header>
      <Card.Body style={{ fontSize: '13px' }}>
        <div className="row mb-3">
          <div className="col-6">{buildInfoItems(['Account', 'Symbol', infoDirection('Direction'), '', 'Session'], position, 'fixapipositions')}</div>
          <div className="col-6">{buildInfoItems([infoPrice('CurrentPrice'), 'Volume', '', 'PlacementType'], position, 'fixapipositions')}</div>
        </div>

        <AppAccordion
          item={{
            title: <FormattedMessage id="additional" />,
            item: additionalComponent,
          }}
          style={{ margin: '0 -20px' }}
          isHidden={false}
        />

        <AppAccordion
          item={{
            title: <FormattedMessage id="volume-distribution" />,
            item: buildVolumeDistribution(position.Distribution, 'FixPositions'),
          }}
          style={{ margin: '0 -20px -20px', marginTop: '-1px' }}
          isHidden={false}
          render={position?.Distribution?.length}
        />
      </Card.Body>
    </Card>
  )
}

export default FixPositions
