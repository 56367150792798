import { Action } from '../actions/actions'

function inProgressReducer(state: number = 0, action: any) {
  switch (action.type) {
    case Action.InProgressStart:
      return state + 1
    case Action.InProgressEnd:
      return state - 1
    default:
      return state
  }
}

export default inProgressReducer
