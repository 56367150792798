import React from 'react'
import { Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { localAsUTC, UTCAsLocal } from '../../utils/time-utils'
import classNames from 'classnames'
import moment from 'moment'

interface IInputProps {
  state: any
  name: string
  label: string
  disabled?: boolean
  className?: string
  zIndex?: number

  setState(newState: any): void
}

const TimespanInput: React.FC<IInputProps> = ({ state, name, setState, label, disabled, className, zIndex }) => {
  const intl = useIntl()
  const onChange = (date: Date | null) => {
    if (date === null) {
      date = moment().startOf('day').toDate()
    }

    let updDate = localAsUTC(date).getTime() - moment(date).utc().startOf('day').toDate().getTime()
    updDate = updDate % (24 * 60 * 60 * 1000)

    setState({
      ...state,
      [name]: updDate,
    })
  }

  let isInvalid = false

  try {
    yup.number().required().validateSync(state[name])
  } catch (e) {
    isInvalid = true
  }

  const value = moment().utc().startOf('day').toDate().getTime()
  let selectedDate = new Date(value + state[name])
  selectedDate = UTCAsLocal(selectedDate)

  return (
    <Form.Group style={{ zIndex: zIndex }} className={classNames('d-flex', 'flex-column', className)}>
      <Form.Label>
        <FormattedMessage id={label} tagName="span" />
        <span>:</span>
      </Form.Label>
      <DatePicker
        locale={intl.locale}
        disabled={disabled}
        className={classNames('form-control', isInvalid && 'isInvalid')}
        selected={selectedDate}
        onChange={onChange}
        timeCaption="time"
        dateFormat="HH:mm:ss"
        timeFormat="HH:mm:ss"
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={5}
      />
      {isInvalid ? (
        <div className="d-block invalid-feedback">
          <FormattedMessage id="field-validation-error" tagName="span" />
        </div>
      ) : null}
    </Form.Group>
  )
}

export default TimespanInput
