import { GlobalSymbol } from '../../entity/system-settings'
import { Action } from '../actions/actions'

function globalSymbolMapReducer(state = { data: [], totalCount: 0 }, action: any) {
  switch (action.type) {
    case Action.GotGlobalSymbolMap:
      return {
        totalCount: action.data.TotalCount,
        data: action.data.Data.map((item: any) => new GlobalSymbol(item)),
      }
    default:
      return state
  }
}

export default globalSymbolMapReducer
