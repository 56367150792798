import { fetchBuilder, modifyBuilder, onlyFetchBuilder, buildHTTPGetOptions, checkResponse, fetchGet, processError, fetchPost } from '../../utils/fetch-utils'
import { Dispatch } from 'redux'
import { Action } from './actions'
import HedgedSyntheticVolumeRightbar from '../../components/rightbar/HedgedSyntheticBar/HedgedSyntheticVolumeRightbar'

const onlyFetchGatewaySettings = onlyFetchBuilder('/api/systemSettings/commonGateway')
const onlyFetchDatabaseSettings = onlyFetchBuilder('/api/systemSettings/commonDatabase')
const onlyFetchSmtpSettings = onlyFetchBuilder('/api/systemSettings/common/smtp')

export const fetchGetawaySettings = fetchBuilder(onlyFetchGatewaySettings, Action.GotGateway)
export const fetchDatabaseSettings = fetchBuilder(onlyFetchDatabaseSettings, Action.GotDatabase)
export const fetchSmtpSettings = fetchBuilder(onlyFetchSmtpSettings, Action.GotSmtp)

export const modifyGatewaySettings = modifyBuilder('/api/systemSettings/commonGateway', onlyFetchGatewaySettings, Action.GotGateway)
export const modifyDatabaseSettings = modifyBuilder('/api/systemSettings/commonDatabase', onlyFetchDatabaseSettings, Action.GotDatabase)
export const modifySmtpSettings = modifyBuilder('/api/systemSettings/common/smtp', onlyFetchSmtpSettings, Action.GotSmtp)

export const onlyFetchLps = onlyFetchBuilder('/api/lps')
export const fetchLps = fetchBuilder(onlyFetchLps, Action.GotSysLps)
export const fetchLpsUpdate = modifyBuilder('/api/lps', onlyFetchLps, Action.GotSysLps)
export const fetchLpsAdd = modifyBuilder('/api/lps', onlyFetchLps, Action.GotSysLps)
export const fetchLpsDelete = modifyBuilder('/api/lps', onlyFetchLps, Action.GotSysLps)

export const fetchLpsAddBased = modifyBuilder('/api/lps/AddApiBasedLp', onlyFetchLps, Action.GotSysLps)
export const fetchLpsUpdateBased = modifyBuilder('/api/lps/ModifyApiBasedLp', onlyFetchLps, Action.GotSysLps)

export const fetchLpsAddHedgingBBook = modifyBuilder('/api/lps/AddHedgingBBook', onlyFetchLps, Action.GotSysLps)
export const fetchLpsUpdateHedgingBBook = modifyBuilder('/api/lps/ModifyHedgingBBook', onlyFetchLps, Action.GotSysLps)

export const fetchLpsAddSynteticLP = modifyBuilder('/api/lps/AddSyntheticSymbolLp', onlyFetchLps, Action.GotSysLps)
export const fetchLpsUpdateSynteticLP = modifyBuilder('/api/lps/UpdateSyntheticSymbolLP', onlyFetchLps, Action.GotSysLps)

export const fetchLpsAddSynteticIndexLP = modifyBuilder('/api/lps/AddSyntheticIndexLp', onlyFetchLps, Action.GotSysLps)
export const fetchLpsUpdateSynteticIndexLP = modifyBuilder('/api/lps/UpdateSyntheticIndexLP', onlyFetchLps, Action.GotSysLps)

//=======
export const fetchLpsAddVolumeTransformer = modifyBuilder('/api/lps/AddVolumeConverter', onlyFetchLps, Action.GotSysLps)
export const fetchLpsModifyVolumeTransformer = modifyBuilder('/api/lps/ModifyVolumeConverter', onlyFetchLps, Action.GotSysLps)
//=======

export const fetchLpsDeleteSyntheticIndexLP = modifyBuilder(
  '/api/lp/DeleteSyntheticIndex',
  () => {
    return
  },
  Action.GotSysLps,
)
export const fetchLpsSynteticIndexLPAdd = modifyBuilder(
  '/api/lp/AddSyntheticIndex',
  () => {
    return
  },
  Action.GotSysLps,
)
export const fetchLpsSynteticLPIndexModify = modifyBuilder(
  '/api/lp/UpdateSyntheticIndex',
  () => {
    return
  },
  Action.GotSysLps,
)

export const fetchLpsDeleteSyntheticSymbolsLP = modifyBuilder(
  '/api/lp/DeleteSyntheticSymbol',
  () => {
    return
  },
  Action.GotSysLps,
)
export const fetchLpsSynteticSymbolsLPAdd = modifyBuilder(
  '/api/lp/AddSyntheticSymbol',
  () => {
    return
  },
  Action.GotSysLps,
)
export const fetchLpsSynteticLPSymbolsModify = modifyBuilder(
  '/api/lp/UpdateSyntheticSymbol',
  () => {
    return
  },
  Action.GotSysLps,
)

export const ImportAllLpsSynteticIndexLPAdd = modifyBuilder('/api/lp/SyntheticIndicesImportAdd', onlyFetchLps, '')
export const ImportReplaceLpsSynteticLPIndexModify = modifyBuilder('/api/lp/SyntheticIndicesImportReplace', onlyFetchLps, '')
export const ImportDiffLpsSynteticIndexLPAdd = modifyBuilder('/api/lp/SyntheticIndicesImportDiff', onlyFetchLps, '')
export const ImportRewriteLpsSynteticLPIndexModify = modifyBuilder('/api/lp/SyntheticIndicesImportRewrite', onlyFetchLps, '')

export const ImportAllLpsSynteticSymbolsLPAdd = modifyBuilder('/api/lp/SyntheticSymbolsImportAdd', onlyFetchLps, '')
export const ImportReplaceLpsSynteticLPSymbolsModify = modifyBuilder('/api/lp/SyntheticSymbolsImportReplace', onlyFetchLps, '')
export const ImportDiffLpsSynteticSymbolsLPAdd = modifyBuilder('/api/lp/SyntheticSymbolsImportDiff', onlyFetchLps, '')
export const ImportRewriteLpsSynteticLPSymbolsModify = modifyBuilder('/api/lp/SyntheticSymbolsImportRewrite', onlyFetchLps, '')

// export const fetchLpsDeleteSynteticLP = modifyBuilder('/api/lp/syntheticSymbolsDelete', onlyFetchLps, Action.GotSysLps)
// export const fetchLpsSynteticLPAdd = modifyBuilder('/api/lp/syntheticSymbolsAdd', onlyFetchLps, Action.GotSysLps)
// export const fetchLpsSynteticLPModify = modifyBuilder('/api/lp/syntheticSymbolsModify', onlyFetchLps, Action.GotSysLps)

export const onlyFetchLpTypes = onlyFetchBuilder('/api/lps/types')
export const onlyFetchLpPool = onlyFetchBuilder('/api/hedgedBBook/getAvaliablePools')

export const onlyFetchBinanceSelect = onlyFetchBuilder('/api/lps/BinanceConfigAllowedValues')
export const onlyFetchBinanceSelectRedux = fetchBuilder(onlyFetchBinanceSelect, Action.GotSysLpBinanceSelect)

export const fetchLpPool = fetchBuilder(onlyFetchLpPool, Action.GotLPPool)
export const fetchLpTypes = fetchBuilder(onlyFetchLpTypes, Action.GotLPTypes)

export const onlyFetchLpPoolSynetic = onlyFetchBuilder('/api/lps/FeedAggregations')
export const fetchLpPoolSynetic = fetchBuilder(onlyFetchLpPoolSynetic, Action.GotLPPoolSynetic)

export const onlyFetchLpSymbolMap = onlyFetchBuilder('/api/lp/symbolMap', {
  Lp: true,
  Page: true,
  Count: true,
  Search: true,
})
export const fetchLpSymbolMap = fetchBuilder(onlyFetchLpSymbolMap, Action.GotSysLpSymbolMap)

export const onlyFetchLpSymbolMapSynthetic = onlyFetchBuilder('api/lp/GetSyntheticSymbols', {
  lp: true,
  Page: true,
  Count: true,
  Search: true,
})
export const fetchLpSymbolMapSynthetic = fetchBuilder(onlyFetchLpSymbolMapSynthetic, Action.GotSysLpSymbolMapSynthetic)

export const onlyFetchLpSymbolMapSyntheticIndex = onlyFetchBuilder('api/lp/GetSyntheticIndices', {
  lp: true,
  Page: true,
  Count: true,
  Search: true,
})
export const fetchLpSymbolMapSyntheticIndex = fetchBuilder(onlyFetchLpSymbolMapSyntheticIndex, Action.GotSysLpSymbolMapSyntheticIndex)

export const onlyExportLpSymbolMapSyntheticIndex = onlyFetchBuilder('api/lp/SyntheticIndicesExport', {
  lp: true,
})
export const exportLpSymbolMapSyntheticIndex = fetchBuilder(onlyExportLpSymbolMapSyntheticIndex, '')

export const onlyExportLpSymbolMapSyntheticSymbols = onlyFetchBuilder('api/lp/SyntheticSymbolsExport', {
  lp: true,
})
export const exportLpSymbolMapSyntheticSymbols = fetchBuilder(onlyExportLpSymbolMapSyntheticSymbols, '')

export const fetchLpSymbolMapUpdate = modifyBuilder('/api/lp/symbolMap', onlyFetchLpSymbolMap, Action.GotSysLpSymbolMap, { Lp: true })

export const onlyFetchLpSymbols = onlyFetchBuilder('/api/lp/symbols', {
  Lp: true,
})

export const onlyFetchGlobalSymbols = onlyFetchBuilder('/api/globalSymbols')

export const onlyFetchGlobalSymbolsWithAliases = onlyFetchBuilder('/api/GlobalSymbolsWithAliases')

export const fetchSyntheticIndexPreview = modifyBuilder(
  '/api/lps/SyntheticIndexPreview',
  () => {
    return
  },
  Action.SyntheticIndexPreview,
)

export const fetchSyntheticSymbolsPreview = modifyBuilder(
  '/api/lps/SyntheticSymbolPreview',
  () => {
    return
  },
  Action.SyntheticSymbolsPreview,
)

export function fetchHedgedBbookVolume(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/hedgedBBook/currentVolumes', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('hbbookName', window.location.search.split('%20').join(' ').slice(4))
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)
    return fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => dispatch({ type: Action.GotHedgedBbookVolume, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchHedgedBbookHistory(setLoading: (isLoading: boolean) => void, ...queryParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    setLoading(true)

    try {
      const data = await fetchGet('/api/hedgedBBook/history', ...queryParams)
      dispatch({ type: Action.GotHedgedBbookHistory, data })
    } catch (e) {
      processError(e, dispatch)
    }

    setLoading(false)
    dispatch({ type: Action.InProgressEnd })
  }
}

export function fetchVolumeTransformerCumulative(setLoading: (isLoading: boolean) => void, ...queryParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    setLoading(true)

    try {
      const data = await fetchGet('/api/VolumeConverter/CurrentVolumes', ...queryParams)
      dispatch({ type: Action.GotVolumeTransformerCumulative, data })
    } catch (e) {
      processError(e, dispatch)
    }

    setLoading(false)
    dispatch({ type: Action.InProgressEnd })
  }
}

export function fetchHedgedConfig(setLoading: (isLoading: boolean) => void, LpType: string, ...queryParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    setLoading(true)

    try {
      const data = await fetchGet(`/api/lp/get${LpType}Config`, ...queryParams)
      dispatch({ type: Action.HedgedConfig, data })
    } catch (e) {
      processError(e, dispatch)
    }

    setLoading(false)
    dispatch({ type: Action.InProgressEnd })
  }
}

export function fetchHedgedSyntheticCe(LpType: string, body: any, ...queryParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })

    try {
      await fetchPost(`/api/lp/set${LpType}ContinuousExecutionOptions`, body, ...queryParams)
    } catch (e) {
      processError(e, dispatch)
    }

    dispatch({ type: Action.InProgressEnd })
  }
}

export function fetchHedgedSyntheticVolume(LpType: string, body: any, ...queryParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })

    try {
      await fetchPost(`/api/lp/set${LpType}Thresholds`, body, ...queryParams)
    } catch (e) {
      processError(e, dispatch)
    }

    dispatch({ type: Action.InProgressEnd })
  }
}

export function updateVolumeTransformerCumulative(setLoading: (isLoading: boolean) => void, body: any, ...queryParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    setLoading(true)
    try {
      await fetchPost('/api/VolumeConverter/SetTargetSymbols', body, ...queryParams)
    } catch (e) {
      processError(e, dispatch)
    }

    setLoading(false)
    dispatch({ type: Action.InProgressEnd })
  }
}

export function fetchVolumeTransformerSymbolMapDelete(setLoading: (isLoading: boolean) => void, body: any, ...queryParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    setLoading(true)

    try {
      await fetchPost('/api/VolumeConverter/SymbolMapDelete', body, ...queryParams)
    } catch (e) {
      processError(e, dispatch)
    }

    setLoading(false)
    dispatch({ type: Action.InProgressEnd })
  }
}

export function fetchVolumeTransformerSymbolMap(setLoading: (isLoading: boolean) => void, ...queryParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    setLoading(true)

    try {
      const data = await fetchGet('/api/VolumeConverter/SymbolMap', ...queryParams)
      dispatch({ type: Action.GotVolumeTransformerSymbolMap, data })
    } catch (e) {
      processError(e, dispatch)
    }

    setLoading(false)
    dispatch({ type: Action.InProgressEnd })
  }
}

export function fetchVolumeTransformerSymbolMapAdd(setLoading: (isLoading: boolean) => void, body: any, ...queryParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    setLoading(true)

    try {
      await fetchPost('/api/VolumeConverter/SymbolMapAdd', body, ...queryParams)
    } catch (e) {
      processError(e, dispatch)
    }

    setLoading(false)
    dispatch({ type: Action.InProgressEnd })
  }
}

export function fetchVolumeTransformerSymbolMapModify(setLoading: (isLoading: boolean) => void, body: any, ...queryParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    setLoading(true)

    try {
      await fetchPost('/api/VolumeConverter/SymbolMapModify', body, ...queryParams)
    } catch (e) {
      processError(e, dispatch)
    }

    setLoading(false)
    dispatch({ type: Action.InProgressEnd })
  }
}
