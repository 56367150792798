import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { hideModal } from '../../redux/actions/modal-actions'
import { RootState } from '../../redux/reducers/rootReducer'
import { AppCheckboxCustom } from '../app-checkbox-custom'

const ModalGeneral: React.FC = () => {
  const dispatch = useDispatch()
  const { modalData } = useSelector((state: RootState) => state.modals)
  const [checked, setChecked] = useState({ All: false, Rule: true })
  const handleYes = () => {
    if (modalData.onYes) {
      if (modalData.gateway || modalData.platform) {
        modalData.onYes(modalData.gateway, modalData.platform)
      } else if (modalData.checked) {
        modalData.onYes(checked)
      } else {
        modalData.onYes()
        if (modalData?.newPagination) {
          modalData?.newPagination()
        }
      }
    }
    dispatch(hideModal())
  }

  const handleNo = () => {
    dispatch(hideModal())
  }

  const onChange = (item: any) => {
    if (item.ModeAll) {
      setChecked({ All: true, Rule: false })
    }

    if (item.ModeRule) {
      setChecked({ All: false, Rule: true })
    }
  }

  const bodyCheckbox = (
    <div>
      <AppCheckboxCustom size={'18px'} text={'ModeAll'} borderColor={'#2A5B88'} color={'#2A5B88'} sizeChecked={'10px'} checked={checked.All} onChange={onChange} />
      <AppCheckboxCustom size={'18px'} text={'ModeRule'} borderColor={'#2A5B88'} color={'#2A5B88'} sizeChecked={'10px'} checked={checked.Rule} onChange={onChange} />
    </div>
  )

  const headerText = typeof modalData.headerText === 'function' ? modalData.headerText() : <FormattedMessage id={modalData.headerText} />
  const bodyText = typeof modalData.bodyText === 'function' ? modalData.bodyText() : <FormattedMessage id={modalData.bodyText} />

  return (
    <Modal show={true} onHide={handleNo}>
      <Modal.Header closeButton={true} className="color-dark font-500">
        {headerText}
      </Modal.Header>
      <Modal.Body>
        {bodyText}
        {modalData.checked && bodyCheckbox}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleYes}>
          <FormattedMessage id="yes" />
        </Button>
        <Button onClick={handleNo}>
          <FormattedMessage id="no" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(ModalGeneral)
