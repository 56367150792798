import * as yup from 'yup'
import { transformEmptyStringMaxLength } from '../utils/schema-utils'

export class BinanceRuleEntity {
  Id: string | number
  Symbols: string
  Groups: string
  Platform: string
  PositiveSwapsMultiplier: any
  NegativeSwapsMultiplier: any

  static schema = {
    Symbols: yup.string().required(),
    Groups: yup.string().required(),
    Platform: yup.object({
      value: yup.string().required(),
      label: yup.string().required(),
    }),
    PositiveSwapsMultiplier: yup
      .string()
      .matches(/^-?[0-9.]+$/gi)
      .transform(transformEmptyStringMaxLength)
      .required(),

    NegativeSwapsMultiplier: yup
      .string()
      .matches(/^-?[0-9.]+$/gi)
      .transform(transformEmptyStringMaxLength)
      .required(),
  }

  constructor(item?: any) {
    if (item) {
      this.Id = item.Id
      this.Symbols = item.Symbols
      this.Groups = item.Groups
      this.Platform = item.Platform
      this.PositiveSwapsMultiplier = item.PositiveSwapsMultiplier
      this.NegativeSwapsMultiplier = item.NegativeSwapsMultiplier
    } else {
      this.Id = Math.floor(Math.random() * (1000 + 1 - 1))
      this.Symbols = ''
      this.Groups = ''
      this.Platform = ''
      this.PositiveSwapsMultiplier = 1
      this.NegativeSwapsMultiplier = 1
    }
  }
}
