export enum ModalType {
  General = 'GENERAL',
  License = 'LICENSE',
  Confirm = 'CONFIRM',
  Info = 'INFO',
  Feed = 'Feed',
  GatewaySetReboot = 'GATEWAY_SET_REBOOT',
  SymbolsMap = 'SYMBOLS_MAP',
  Preset = 'PRESET',
  ModalDetails = 'MODAL_DETAILS',
  ModalCheckTimeout = 'MODAL_CHECK_TIMEOUT',
}
