import { buildMultiselectOptionsFromArray } from '../utils/multiselect-utils'
import { IMultiSelectItem, MultiselectItem } from './multiselect'
import * as yup from 'yup'
import { transformEmptyStringMaxLength } from '../utils/schema-utils'
import { expToNum } from '../utils/num-utils'

function nullOrItem(item: string | object | undefined): IMultiSelectItem | null {
  if (typeof item === 'string') {
    return new MultiselectItem(item)
  } else if (typeof item === 'object' && item !== null) {
    return { ...item } as IMultiSelectItem
  }

  return null
}

export class ConsolidationRuleEntity {
  Id: number
  Symbol: IMultiSelectItem | null
  Lps: IMultiSelectItem[]
  Start: number
  Finish: number
  MaxSpread: number
  MaxOrderVolume: string
  Timeout: number;
  [key: string]: any

  static schema = {
    Symbol: yup.object().required(),
    Lps: yup.array().required(),
    Start: yup.number().required(),
    Finish: yup.number().required(),
    MaxSpread: yup
      .string()
      .matches(/^-?[0-9.]+$/gi)
      .transform(transformEmptyStringMaxLength)
      .required(),
    MaxOrderVolume: yup
      .string()
      .matches(/^[0-9.]+$/gi)
      .transform(transformEmptyStringMaxLength)
      .test('', '', value => value > 0)
      .required(),
    Timeout: yup.number().min(1).integer().required(),
  }

  constructor(item?: any) {
    this.Id = item?.Id ?? 0
    this.Symbol = nullOrItem(item?.Symbol)
    this.Lps = buildMultiselectOptionsFromArray(item?.Lps)
    this.Start = item?.Start ?? 0
    this.Finish = item?.Finish ?? 0
    this.MaxSpread = item?.MaxSpread ?? 0
    this.MaxOrderVolume = expToNum(item?.MaxOrderVolume) ?? ''
    this.Timeout = item?.Timeout ?? 100
  }
}

export class ConsolidationHistoryEntity {
  Symbol: string
  Lps: string[]
  ExecutionTime: number
  AverageBuyPrice: number
  AverageSellPrice: number
  ExecutedVolume: number
  StartTime: number
  Duration: number

  constructor(item: any) {
    this.Symbol = item.Symbol
    this.Lps = item.Lps
    this.ExecutionTime = item.ExecutionTime
    this.AverageBuyPrice = item.AverageBuyPrice
    this.AverageSellPrice = item.AverageSellPrice
    this.ExecutedVolume = item.ExecutedVolume
    this.StartTime = item.StartTime
    this.Duration = item.Duration
  }
}
