import React from 'react'
import Moment from 'react-moment'
import { useIntl } from 'react-intl'
import 'moment/locale/ja'
import 'moment/locale/en-gb'

const CurrentTime: React.FC = () => {
  const intl = useIntl()
  return (
    <div className="d-flex flex-column justify-content-center text-muted" key="time-component">
      <Moment interval={1000} tz="utc" format="MMM Do, HH:mm:ss" locale={intl.locale} />
    </div>
  )
}

export default CurrentTime
