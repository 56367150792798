import { CeActiveEntity, CeFailedEntity, CeHistoryEntity, CeRuleEntity, BbookHedged, BbookHedgedValue } from '../../entity/ce-entity'
import { IPaginatedData } from '../../entity/paginated-data'
import { Action } from '../actions/actions'

interface IState {
  ceActive: IPaginatedData<CeActiveEntity>
  ceFailed: IPaginatedData<CeFailedEntity>
  ceHistory: IPaginatedData<CeHistoryEntity>
  ceRules: IPaginatedData<CeRuleEntity>
  HedgedBbookHistory: IPaginatedData<BbookHedged>
  HedgedBbookVolume: any
}

const INITIAL_STATE: IState = {
  ceActive: {
    data: [],
    totalCount: 0,
  },
  ceFailed: {
    data: [],
    totalCount: 0,
  },
  ceHistory: {
    data: [],
    totalCount: 0,
  },
  ceRules: {
    data: [],
    totalCount: 0,
  },
  HedgedBbookHistory: {
    data: [],
    totalCount: 0,
  },
  HedgedBbookVolume: {
    data: [],
  },
}

function ceReducer(state = INITIAL_STATE, action: any): IState {
  switch (action.type) {
    case Action.GotCeActive:
      return {
        ...state,
        ceActive: {
          data: action.data.Data.map((item: any) => new CeActiveEntity(item)),
          totalCount: action.data.TotalCount,
        },
      }
    case Action.GotCeFailed:
      return {
        ...state,
        ceFailed: {
          data: action.data.Data.map((item: any) => new CeFailedEntity(item)),
          totalCount: action.data.TotalCount,
        },
      }
    case Action.GotCeHistory:
      return {
        ...state,
        ceHistory: {
          data: action.data.Data.map((item: any) => new CeHistoryEntity(item)),
          totalCount: action.data.TotalCount,
        },
      }
    case Action.GotHedgedBbookHistory:
      return {
        ...state,
        HedgedBbookHistory: {
          data: action.data.Data.map((item: any) => new BbookHedged(item)),
          totalCount: action.data.TotalCount,
        },
      }
    case Action.GotHedgedBbookVolume:
      return {
        ...state,
        HedgedBbookVolume: {
          data: action.data.map((item: any) => new BbookHedgedValue(item)),
        },
      }
    case Action.GotCeRules:
      return {
        ...state,
        ceRules: {
          data: action.data.Data.map((item: any) => new CeRuleEntity(item)),
          totalCount: action.data.TotalCount,
        },
      }
    case Action.ChangeCeRulesOrder:
      const newRules = Array.from(state.ceRules.data)
      newRules.splice(action.data.source.index, 1)
      newRules.splice(action.data.destination.index, 0, state.ceRules.data[action.data.source.index])
      return {
        ...state,
        ceRules: {
          ...state.ceRules,
          data: newRules,
        },
      }
    default:
      return state
  }
}

export default ceReducer
