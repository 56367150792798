import React, { KeyboardEventHandler, useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { withTooltip } from '@t4b/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

const components = {
  DropdownIndicator: null,
}

interface Option {
  readonly label: string
  readonly value: string
}

const createOption = (label: string) => ({
  label,
  value: label,
})

interface IMultiSelectInput {
  state: any
  name: string
  options: any[]
  className?: string
  style?: any
  touched?: any
  errors?: any
  errorText?: string
  label?: string
  isDisabled?: boolean
  isCreatable?: boolean
  placeholder?: string
  tooltip?: string
  tooltipFlag?: boolean
  CreateTitle?: string
  schema?: any
  flag?: boolean
  zIndex?: boolean
  zIndexNumber?: number
  symbolValid?: number
  textWarning?: any

  setState(state: any): void

  setTouched?(touched: any): void

  onCreateOption?(value: any): void
}
const CreateInput: React.FC<IMultiSelectInput> = props => {
  const { state, tooltip, setState, label, schema, name, className, touched, errors, flag, errorText, zIndex, zIndexNumber, textWarning } = props

  const [inputValue, setInputValue] = React.useState('')
  const [value, setValue] = React.useState<readonly Option[]>(state[name])

  const handleKeyDown: KeyboardEventHandler = event => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue((prev: any) => (state[name]?.length ? [...prev, createOption(inputValue)] : [createOption(inputValue)]))
        setInputValue('')
        event.preventDefault()
    }
  }

  useEffect(() => {
    setState((prev: any) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }, [value])

  const renderTooltip = (props?: any, tooltip?: string) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )

  const danger = withTooltip(
    <span className="text-warning mr-1">
      <FontAwesomeIcon icon={faExclamationTriangle} />
    </span>,
    <FormattedMessage id="dangerStatementsEmails" />,
    Math.random(),
  )

  let isInvalidYup = false
  const isInvalid = touched && touched[name] && errors && errors[name]

  if (schema) {
    try {
      schema.validateSync(state[name])
    } catch (e) {
      isInvalidYup = true
    }
  }

  return (
    <Form.Group className={className} style={zIndex ? { zIndex: zIndexNumber, position: 'relative', cursor: 'text' } : {}}>
      {label ? (
        <Form.Label>
          {textWarning && (
            <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, tooltip)}>
              {danger}
            </OverlayTrigger>
          )}
          <FormattedMessage id={label} tagName="span" />
          <span>:</span>
        </Form.Label>
      ) : null}

      <div style={{ position: 'relative' }}>
        <OverlayTrigger placement="top" delay={{ show: 200, hide: 0 }} overlay={renderTooltip({}, 'Type email address and press Enter.')}>
          <CreatableSelect
            styles={
              {
                clearIndicator: (baseStyles: any) => ({
                  ...baseStyles,
                  cursor: 'pointer',
                }),
                multiValueRemove: (baseStyles: any) => ({
                  ...baseStyles,
                  ':hover': {
                    cursor: 'pointer',
                  },
                }),
                control: baseStyles => ({
                  ...baseStyles,
                  cursor: 'text',
                  '&:hover': {
                    cursor: 'text',
                  },
                  '&:focus': {
                    cursor: 'text',
                  },
                }),
              } ?? undefined
            }
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={(newValue: any) => setValue(newValue)}
            onInputChange={(newValue: any) => setInputValue(newValue)}
            onKeyDown={handleKeyDown}
            placeholder="None"
            value={value}
          />
        </OverlayTrigger>
      </div>

      {isInvalidYup ? (
        <div className={flag ? 'd-block invalid-feedback test' : 'd-block invalid-feedback test position-button'}>
          <FormattedMessage id="field-validation-error" tagName="span" />
        </div>
      ) : null}
      <div className={`invalid-feedback ${isInvalid && 'd-block'}`}>
        <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
      </div>
    </Form.Group>
  )
}

export default CreateInput
