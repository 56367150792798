import React from 'react'
import { useSelector } from 'react-redux'
import ModalGeneral from './ModalGeneral'
import ModalLicense from './ModalLicense'
import ModalConfirm from './ModalConfirm'
import { ModalType } from './modals'
import ModalInfo from './ModalInfo'
import ModalFeed from './ModalFeed'
import ModalSymbolsMap from './ModalSymbolsMap'
import ModalGatewaySetReboot from './GatewaySetReboot'
import { RootState } from '../../redux/reducers/rootReducer'
import ModalPreset from './ModalPreset'
import { ModalDetails } from './ModalDetailsTradingHistory/ModalDetails'
import { ModalCheckTimeout } from './ModalCheckTimeout'

const ModalContainer: React.FC = () => {
  const { modalName } = useSelector((state: RootState) => state.modals)
  let modalComponent = null
  if (modalName === ModalType.General) {
    modalComponent = <ModalGeneral />
  } else if (modalName === ModalType.License) {
    modalComponent = <ModalLicense />
  } else if (modalName === ModalType.Feed) {
    modalComponent = <ModalFeed />
  } else if (modalName === ModalType.Confirm) {
    modalComponent = <ModalConfirm />
  } else if (modalName === ModalType.Info) {
    modalComponent = <ModalInfo />
  } else if (modalName === ModalType.GatewaySetReboot) {
    modalComponent = <ModalGatewaySetReboot />
  } else if (modalName === ModalType.SymbolsMap) {
    modalComponent = <ModalSymbolsMap />
  } else if (modalName === ModalType.Preset) {
    modalComponent = <ModalPreset />
  } else if (modalName === ModalType.ModalDetails) {
    modalComponent = <ModalDetails />
  } else if (modalName === ModalType.ModalCheckTimeout) {
    modalComponent = <ModalCheckTimeout />
  }

  return <div>{modalComponent}</div>
}

export default React.memo(ModalContainer)
