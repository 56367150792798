import React from 'react';
import ColorPickerValue from './ColorPickerValue';

const ValueContainer = ({ selected, onValueDelete, setReferenceElement, placeholder = 'Select color...' }) => {
  return (
    <div
      ref={setReferenceElement}
      className='color-picker_value-container'
    >
      {selected.length === 0 && <span className='color-picker_placeholder'>{placeholder}</span>}
      {selected.length > 0 && selected.map(item => <ColorPickerValue key={item.name} color={item} onDelete={onValueDelete}/>)}
    </div>
  )
}

export default ValueContainer;