import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { buildHTTPGetOptions } from '../../utils/fetch-utils'
import { buildInfoItems, buildVolumeDistribution, infoDirection, infoPrice, infoProfit, infoTime } from '../../utils/info-item-utils'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'

const FixOrdersRightbar: React.FC<IRightbar> = ({ data: params }) => {
  const [order, setOrder] = useState<any>({})

  useEffect(() => {
    const url = new URL('/api/deals/order', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('platform', params.Platform)
    url.searchParams.set('id', params.position.OrderId)

    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => response.json())
      .then((data: any) => setOrder(data))
  }, [params])

  const additionalComponent = (
    <div className="row">
      <div className="col-6">{buildInfoItems(['Status', 'ExternalId'], order, 'fixOrders')}</div>
      <div className="col-6">{buildInfoItems(['PlacementType', infoTime('TransactionTime')], order, 'fixOrders')}</div>
    </div>
  )

  return (
    <Card>
      <Card.Header
        style={{
          color: 'rgb(141, 171, 196)',
          fontWeight: 500,
          fontSize: '13px',
          textTransform: 'uppercase',
        }}
      >
        <FormattedMessage id="order-detail" />
      </Card.Header>
      <Card.Body style={{ fontSize: '13px' }}>
        <div className="row mb-3">
          <div className="col-6">
            {buildInfoItems(['OrderId', 'Symbol', infoDirection('Side'), 'OrderType', 'TimeInForce', '', 'FilledVolume', 'LotQuantity'], order, 'fixOrders')}
          </div>
          <div className="col-6">
            {buildInfoItems(
              ['ClientOrderId', 'Account', 'UserGroup', 'Session', '', '', infoPrice('AveragePrice'), infoProfit('BrokerProfit')],
              order,
              'fixOrders',
            )}
          </div>
        </div>

        <AppAccordion item={{ title: 'Additional', item: additionalComponent }} style={{ margin: '0 -20px' }} isHidden={false} />

        <AppAccordion
          item={{
            title: 'Volume distribution',
            item: buildVolumeDistribution(order.Distribution),
          }}
          style={{ margin: '0 -20px -20px' }}
          isHidden={false}
          render={order?.Distribution?.length}
        />
      </Card.Body>
    </Card>
  )
}

export default FixOrdersRightbar
