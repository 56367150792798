import { DropCopySessionEntity, DropCopySettingsEntity } from '../../entity/drop-copy-entity'
import { Action } from '../actions/actions'

class DropCopyState {
  public dropCopySessions: any
  public dropCopySettings: DropCopySettingsEntity

  constructor() {
    this.dropCopySessions = {
      data: [],
      totalCount: 0,
    }
    this.dropCopySettings = new DropCopySettingsEntity()
  }
}

function dropCopyReducer(state = new DropCopyState(), action: any) {
  switch (action.type) {
    case Action.GotDropCopySessions:
      return {
        ...state,
        dropCopySessions: {
          data: action.data.Data.map((item: DropCopySessionEntity) => new DropCopySessionEntity(item)),
          totalCount: action.data.TotalCount,
        },
      }
    case Action.GotDropCopySettings:
      return {
        ...state,
        dropCopySettings: new DropCopySettingsEntity(action.data),
      }
    default:
      return state
  }
}

export default dropCopyReducer
