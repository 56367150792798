import { Action } from './actions'
import { ModalType } from '../../components/modals/modals'

export function showModal(modalName: string | ModalType, modalData: any) {
  return {
    type: Action.ShowModal,
    payload: { modalName, modalData },
  }
}

export function hideModal() {
  return {
    type: Action.HideModal,
  }
}
