import * as yup from 'yup'
import { passwordSchema, portSchema, transformEmptyString } from '../../../utils/schema-utils'
import { OkxLive } from './lpType'

export function getFeedSchema() {
  const s = () => yup.string().notRequired()
  return {
    SenderCompId: s(),
    TargetCompId: s(),
    BeginString: s(),
    Username: s(),
    Password: s(),
    ReconnectInterval: yup.number().min(0).transform(transformEmptyString).nullable().notRequired(),
    FileStorePath: s(),
    FileLogPath: s(),
    WriteLogs: s(),
    SocketConnectHost: yup.string().required(),
    SocketConnectPort: portSchema().required(),
    Server: s(),
    Login: s(),
    ResubscribeOnRejectTimeout: yup.number().min(0).transform(transformEmptyString).nullable().notRequired(),
  }
}

export function getExecSchema() {
  const s = () => yup.string().notRequired()
  return {
    ClientId: s(),
    AccountId: s(),
    SenderCompId: s(),
    TargetCompId: s(),
    BeginString: s(),
    Username: s(),
    Password: s(),
    ReconnectInterval: yup.number().min(0).transform(transformEmptyString).nullable().notRequired(),
    FileStorePath: s(),
    FileLogPath: s(),
    SocketConnectHost: yup.string().required(),
    SocketConnectPort: portSchema().required(),
    MaxMessagesPerSec: yup.number().min(0).integer().transform(transformEmptyString).nullable().notRequired(),
    OnBehalfOfCompId: yup.string().matches(/^true$|^false$|^$/gi),
    PartyIds: yup.array().of(
      yup.object().shape({
        PartyId: yup.string().required(),
        PartyIdSource: yup.string().max(1).required(),
        PartyRole: yup
          .string()
          .matches(/^[0-9.]+$/gi)
          .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
      }),
    ),
  }
}

export const leverateSchema = {
  ApiKey: yup.string().required(),
  ApiSecret: yup.string().required(),
  ReconnectTimeout: yup.number().required(),
  EnableFeederLogging: yup.boolean().required(),
}

export const alpacaSchema = {
  ApiKey: yup.string().required(),
  ApiSecret: yup.string().required(),
  Live: yup.boolean().required(),
}

export const schema = {
  Name: yup
    .string()
    // eslint-disable-next-line no-control-regex
    .matches(/^(?!(?:CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])(?:\.[^.]*)?$)[^<>:"/\\|?*\x00-\x1F,]*[^<>:"/\\|?*\x00-\x1F.,]$/)
    .max(32)
    .required(),
  Type: yup.string().required(),
}

export const LMAXCryptoClassSchema = {
  PriceDocumentLink: yup.string().required(),
  CheckInterval: yup
    .string()
    .matches(/^[0-9]+$/gi)
    .required(),
}

export const bbookHedged = {
  Thresholds: yup.array().of(
    yup.object().shape({
      Mask: yup.string(),
      Threshold: yup
        .string()
        .matches(/^[0-9.]+$/gi)
        .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
    }),
  ),
  AggregationPool: yup.object().shape({
    value: yup.string().required(),
    label: yup.string().required(),
  }),
}

export const binanceSchema = (MinAllowedLeverage: any, MaxAllowedLeverage: any, type: any, lp?: any, useDefaultServers?: boolean) => {
  return {
    RestAddress: useDefaultServers ? yup.string() : yup.string().required(),
    SocketStreamAddress: useDefaultServers || lp !== 'BinanceStock' ? yup.string() : yup.string().required(),
    SocketApiAddress: useDefaultServers ? yup.string() : yup.string().required(),
    ApiKey: yup.string().required(),
    ApiSecret: passwordSchema(type),
    LogPath: yup.string().required(),
    AllowedCancellationsForUser: yup.number().required(),
    AllowedCancellationsForSession: yup.number().required(),
    CancellationRatingResetTimeout: yup.number().required(),
    ReconnectTimeout: yup
      .string()
      .matches(/^[0-9]+$/gi)
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => Number(value) > 0),

    TasksLimit: yup.number().required(),
    PassPhrase: OkxLive.has(lp) ? yup.string().required() : yup.string(),
    StreamLastPrices: yup.boolean().required(),
    Leverage: yup
      .string()
      .matches(/^[0-9]+$/gi)
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => Number(value) >= MinAllowedLeverage)
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => Number(value) <= MaxAllowedLeverage),
  }
}
