import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { buildHTTPGetOptions, checkResponse, processError } from '../../utils/fetch-utils'
import ExecutionLpARightbar from './ExecutionLpARightbar'
import ExecutionLpBRightbar from './ExecutionLpBRightbar'
import { isBbookLp } from '../../entity/configuration'
import { IRightbar } from './rightbar-types'
import { RootState } from '../../redux/reducers/rootReducer'

const ExecutionLpRightbar: React.FC<IRightbar> = props => {
  const dispatch = useDispatch()
  const [lpTypes, setLpTypes] = useState<string[]>([])
  const { gateway } = useSelector((state: RootState) => state.gateways)

  useEffect(() => {
    const url = new URL('/api/lps/types', document.location.origin)
    url.searchParams.set('gateway', gateway?.Name)

    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => setLpTypes(data))
      .catch((error: Error) => processError(error, dispatch))
  }, [gateway, dispatch])

  return isBbookLp(props.data.item) ? <ExecutionLpBRightbar lpTypes={lpTypes} {...props} /> : <ExecutionLpARightbar lpTypes={lpTypes} {...props} />
}

export default ExecutionLpRightbar
