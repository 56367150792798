import { ConsolidationHistoryEntity, ConsolidationRuleEntity } from '../../entity/consolidation-entity'
import { Action } from '../actions/actions'

class ConsolidationState {
  public consolidationRules: any
  public consolidationHistory: any

  constructor() {
    this.consolidationRules = {
      data: [],
      totalCount: 0,
    }
    this.consolidationHistory = {
      data: [],
      totalCount: 0,
    }
  }
}

function consolidationReducer(state = new ConsolidationState(), action: any): ConsolidationState {
  switch (action.type) {
    case Action.GotConsolidationRules:
      return {
        ...state,
        consolidationRules: {
          data: action.data.Data.map((item: any) => new ConsolidationRuleEntity(item)),
          totalCount: action.data.TotalCount,
        },
      }
    case Action.GotConsolidationHistory:
      return {
        ...state,
        consolidationHistory: {
          data: action.data.Data.map((item: ConsolidationHistoryEntity) => new ConsolidationHistoryEntity(item)),
          totalCount: action.data.TotalCount,
        },
      }
    default:
      return state
  }
}

export default consolidationReducer
