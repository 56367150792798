import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router'
import { User } from '../redux/actions/authentication'
import { RouteProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { hideRightBar } from '../redux/actions/rightbar-actions'
import { hideModal } from '../redux/actions/modal-actions'

interface IPrivateRoute extends RouteProps {
  component: any
  isAuthenticated: boolean
  path: string
}

const PrivateRoute = ({ component, ...rest }: IPrivateRoute) => {
  const executionConfiguration = useSelector((state: any) => state.executionConfiguration)
  const feedingConfiguration = useSelector((state: any) => state.feedingConfiguration)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!executionConfiguration.noChanges || !feedingConfiguration.noChanges) {
      window.onunload = function () {
        return '...'
      }
      window.onbeforeunload = function () {
        return '...'
      }
    } else {
      dispatch(hideRightBar())
      dispatch(hideModal())
    }
    return () => {
      window.onbeforeunload = function () {}
      window.onunload = function () {}
    }
  }, [rest.path]) // eslint-disable-line react-hooks/exhaustive-deps

  const user = new User()
  const toRender = () => {
    if (rest.isAuthenticated) {
      if (user.hasAccess(rest.path)) {
        return component
      }
      return <Redirect to={{ pathname: '/not-enough-privilege' }} />
    }
    return <Redirect to={{ pathname: '/login' }} />
  }
  return <Route {...rest} render={toRender} />
}

export default PrivateRoute
