import React from 'react'
import { IRightbar } from '../rightbar-types'
import { useDispatch } from 'react-redux'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { CeRuleEntity } from '../../../entity/ce-entity'
import { hideRightBar } from '../../../redux/actions/rightbar-actions'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { buildControlsExtTwoPerLine, textInput } from '../../../utils/controls'
import { fetchHedgedConfig, fetchHedgedSyntheticCe } from '../../../redux/actions/system-settings-actions'
import { transformGateway } from '../../../utils/fetch-utils'

const HedgedSyntheticCeRightbar: React.FC<IRightbar> = React.memo(({ data: { item, LpType, gateway, setLoading, lp } }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    {
      ...item,
      MaxOrderVolume: String(item.MaxOrderVolume),
      Duration: String(item.Duration),
      Timeout: String(item.Timeout),
    },
    CeRuleEntity.schema,
  )

  const handleClick = async () => {
    if (!isValid()) {
      return
    }

    const body = [
      {
        MaxOrderVolume: inputState.MaxOrderVolume,
        Duration: inputState.Duration,
        Timeout: inputState.Timeout,
        Symbol: inputState.Symbol,
      },
    ]

    await dispatch(fetchHedgedSyntheticCe(LpType, body, transformGateway(gateway), { lp: lp.trim() }))
    await dispatch(fetchHedgedConfig(setLoading, LpType, transformGateway(gateway), { lp: lp.trim() }))
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`ce-rules.modify`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine([textInput('Symbol', true), textInput('MaxOrderVolume'), textInput('Duration'), textInput('Timeout')], inputState, setInputState, 'ce-rulesHedg', touched, setTouched, errors)}

        <Button className="t4b-bg-dark-button mt-3" onClick={handleClick}>
          <FormattedMessage id="save" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default HedgedSyntheticCeRightbar
