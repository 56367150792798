import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'
import cls from '../chatBot.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAstronaut, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { Spinner } from 'react-bootstrap'

export const Messages = memo(() => {
  const { Messages, SuggestedQuestions, FetchStatus }: any = useSelector((state: RootState) => state.ChatBot)

  return (
    <div className={cls.content}>
      {Messages.map((item: { role: string; text: string }) => (
        <div className={item.role === 'bot' ? cls.msgBot : cls.msgUser} key={item.text}>
          <FontAwesomeIcon icon={item.role === 'bot' ? faUserSecret : faUserAstronaut} className={cls.svgIcon} />
          <span>{item.text}</span>
        </div>
      ))}
      {(FetchStatus.loading || (!SuggestedQuestions?.length && FetchStatus.status === 0)) && (
        <div className={cls.msgBot}>
          <FontAwesomeIcon icon={faUserSecret} className={cls.svgIcon} />
          {[...Array(3)].map((_, index) => (
            <Spinner key={index} animation="grow" variant="secondary" size="sm" />
          ))}
        </div>
      )}
      {FetchStatus.status !== 200 && FetchStatus.status > 0 && (
        <div className={cls.msgBot} key={FetchStatus.status}>
          <FontAwesomeIcon icon={faUserSecret} className={cls.svgIcon} />
          <span>AI chatbot is unavailable, contact TP support to fix it.</span>
        </div>
      )}
    </div>
  )
})
