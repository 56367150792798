import React, { memo, useEffect, useLayoutEffect } from 'react'
import { ITableColumn } from '../../../utils/table-types'
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd'

import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { useFormValidation } from '../../../hooks/useFormValidation'
import {
  activeLeverageRules,
  addLeverageRules,
  changeLeverageRulesItemPriority,
  cloneLeverageRules,
  deleteLeverageRules,
  ILeverageRules,
} from '../../../redux/reducers/leverageProfileReducer'
import { useDispatch, useSelector } from 'react-redux'
import { LeverageProfileTableIRuleRightbar } from './LeverageProfileTableIRuleRightbar'
import { RootState } from '../../../redux/reducers/rootReducer'

const tableHeader: ITableColumn[] = [
  { name: 'RuleName', show: true, sortable: false, className: 'widthRuleName' },
  { name: 'Symbols', show: true, sortable: false, className: 'w-300' },
  { name: 'Leverage', show: true, sortable: false, className: 'widthLeverage' },
  { name: 'Actions', show: true, sortable: false, skip: true },
]
export const LeverageProfileTableRightbar = memo(({ item, setValidTable }: any) => {
  const dispatch = useDispatch()
  const { Rules } = useSelector((state: RootState) => state.leverageProfile)
  const findRules = Rules[item.Id]
  const [inputState, setInputState] = useFormValidation(findRules, {})

  useEffect(() => {
    setInputState(findRules)
  }, [findRules])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    if (result.destination.index === result.source.index) return

    dispatch(
      changeLeverageRulesItemPriority({
        result,
        params: { ...inputState, Id: item.Id },
      }),
    )
  }

  const onClone = (elem: ILeverageRules) => {
    dispatch(cloneLeverageRules({ Id: item.Id, rule: { ...elem, Name: '', Id: -Math.floor(Math.random() * (10000 - 1 + 1)) + 1 } }))
  }

  const onDelete = (elem: ILeverageRules) => {
    dispatch(deleteLeverageRules({ Id: item.Id, ruleId: elem.Id }))
  }

  const onActive = (elem: ILeverageRules) => {
    dispatch(activeLeverageRules({ Id: item.Id, ruleId: elem.Id }))
  }

  const handelAdd = () => {
    dispatch(addLeverageRules({ Id: item.Id, rule: { Name: '', Symbols: [], Leverage: '', Id: -Math.floor(Math.random() * (10000 - 1 + 1)) + 1, IsActive: true } }))
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-end mb-1 mt-4">
        <div className="cursor-pointer" onClick={handelAdd}>
          <span className="color-checked color-checked-hov">Add rule</span>
          <FontAwesomeIcon icon={faPlus} className=" ml-2 cursor-pointer" />
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <table className="table app-table table-hover" style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr style={{ color: '#8dabc4' }}>
              {tableHeader.map((item: any) => {
                return (
                  <th className={item.className}>
                    <FormattedMessage id={`${item.name}`} />
                  </th>
                )
              })}
            </tr>
          </thead>
          <Droppable droppableId="table">
            {(droppableProvided: DroppableProvided) => (
              <tbody
                ref={ref => {
                  droppableProvided.innerRef(ref)
                }}
                {...droppableProvided.droppableProps}
              >
                {inputState.slice(0, inputState.length - 1).map((rule: any, index: number) => {
                  const handleClone = () => onClone(rule)
                  const handleDelete = () => onDelete(rule)
                  const handleActive = () => onActive(rule)

                  const handleStateChange = (newState: ILeverageRules) => {
                    const newStateArr = [...inputState]
                    newStateArr.splice(index, 1, newState)
                    setInputState(newStateArr)
                  }

                  return (
                    <Draggable draggableId={rule.Id.toString()} index={index} key={rule.Id.toString()}>
                      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                        <tr
                          key={index}
                          className={rule.IsActive ? 'isActive' : 'isDisabled'}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={
                            snapshot.isDragging
                              ? {
                                  display: 'table',
                                  tableLayout: 'fixed',
                                  ...provided.draggableProps.style,
                                }
                              : {
                                  display: 'table-row',
                                  ...provided.draggableProps.style,
                                }
                          }
                        >
                          <LeverageProfileTableIRuleRightbar
                            Id={item.Id}
                            setValidTable={setValidTable}
                            item={rule}
                            handleClone={handleClone}
                            handleDelete={handleDelete}
                            handleActive={handleActive}
                            handleStateChange={handleStateChange}
                          />
                        </tr>
                      )}
                    </Draggable>
                  )
                })}
                {inputState.slice(-1).map((rule: ILeverageRules, index: number) => {
                  const handleClone = () => onClone(rule)
                  const handleDelete = () => onDelete(rule)

                  const handleStateChange = (newState: any) => {
                    const newStateArr = [...inputState]
                    newStateArr.splice(inputState.length - 1, 1, newState)

                    setInputState(newStateArr)
                  }

                  return (
                    <tr key={index}>
                      <LeverageProfileTableIRuleRightbar
                        item={rule}
                        Id={item.Id}
                        key={rule.Id}
                        handleClone={handleClone}
                        setValidTable={setValidTable}
                        handleDelete={handleDelete}
                        handleStateChange={handleStateChange}
                      />
                    </tr>
                  )
                })}

                {inputState && inputState.length === 0 && (
                  <tr>
                    <td className="text-center" colSpan={4}>
                      <FormattedMessage id="table.nodata" />
                    </td>
                  </tr>
                )}
                {droppableProvided.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
    </>
  )
})
