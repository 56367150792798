import React, { ChangeEvent, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

export type PasswordInputMode = 'setup' | 'change'

interface IPasswordInput {
  className?: string
  state: any
  touched?: any
  errors?: any
  errorText?: string
  name: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
  mode: PasswordInputMode

  setState(state: any): any

  setTouched?(touched: any): any
}

const PasswordInput: React.FC<IPasswordInput> = ({
  state,
  setState,
  touched,
  setTouched,
  errors,
  errorText,
  name,
  className,
  placeholder,
  label,
  mode,
  isDisabled,
}) => {
  const [isChange, setIsChange] = useState(mode === 'setup')

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [name]: event.target.value,
      flag: event.target.checked,
    })
  }
  const handleBlur = () => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
  }

  const handleChangeMode = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChange(event.target.checked)

    if (event.target.checked) {
      setState({
        ...state,
        [name]: '',
        flag: true,
      })
    } else {
      setState({
        ...state,
        [name]: '',
        flag: false,
      })

      setTimeout(() =>
        setState({
          ...state,
          [name]: null,
          flag: false,
        }),
      )
    }
  }

  return (
    <Form.Group className={className}>
      {label && (
        <div className="d-flex justify-content-between">
          <Form.Label>
            <FormattedMessage id={label} tagName="span" />
            <span>:</span>
          </Form.Label>
          {mode === 'change' && (
            <label className="d-flex align-items-center">
              <input type="checkbox" className="mr-1" onChange={handleChangeMode} />
              <FormattedMessage id="change-password" tagName="span" />
            </label>
          )}
        </div>
      )}
      <Form.Control
        type="text"
        placeholder={placeholder}
        value={state[name] || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={!isChange || isDisabled}
        isInvalid={touched && touched[name] && errors && errors[name]}
      />
      <Form.Control.Feedback type="invalid">
        <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default React.memo(PasswordInput)
