import React, { useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { buildControlsExtTwoPerLine, textInput } from '../../../utils/controls'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { hideRightBar } from '../../../redux/actions/rightbar-actions'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { LeverageProfileTableRightbar } from './LeverageProfileTableRightbar'
import { fetchAddOrUpdateLeverageProfile, fetchLeverageProfile } from '../../../redux/actions/platforms-actions'
import { useQuery } from '../../../hooks/useQuery'
import { RootState } from '../../../redux/reducers/rootReducer'
import { ILeverageProfile, ILeverageRules } from '../../../redux/reducers/leverageProfileReducer'
import { IMultiSelectItem } from '../../../entity/multiselect'

export const LeverageProfileRightbar = ({ data: { item, type, params } }: any) => {
  const dispatch = useDispatch()
  const query = useQuery()
  const { Data, Rules } = useSelector((state: RootState) => state.leverageProfile)
  const { gateway } = useSelector((reduxState: RootState) => reduxState.gateways)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(item, { Name: yup.string().required() })
  const [validTable, setValidTable] = useState([])
  const alreadyExist = () => {
    if ((type === 'add' || type === 'clone') && Data.find((item: ILeverageProfile) => item.Name === inputState.Name && item.Id !== inputState.Id)) {
      errors.Name = true
      return 'LeverageProfile.exists'
    }

    if (type === 'clone' && Data.find((item: ILeverageProfile) => item.Name === inputState.Name && item.Id === inputState.Id)) {
      errors.Name = true
      return 'LeverageProfile.exists'
    }

    if (type === 'modify' && Data.find((item: ILeverageProfile) => item.Name === inputState.Name && item.Id !== inputState.Id)) {
      errors.Name = true
      return 'LeverageProfile.exists'
    }
    return ''
  }

  const handleClick = async () => {
    const valid = Object.keys(validTable).reduce((acc: any, rec: any) => [...acc, validTable[rec]], [])

    if (Boolean(valid.filter((item: boolean) => !Boolean(item)).length) || !isValid()) {
      return
    }

    const data = {
      Id: item.Id,
      Name: inputState.Name,
      Rules: Rules[item.Id].map((item: ILeverageRules) => {
        return {
          ...item,
          Symbols: item?.Symbols?.map((item: IMultiSelectItem) => item.value) ?? [],
        }
      }),
    }

    if (type === 'add') {
      await fetchAddOrUpdateLeverageProfile({
        type,
        body: data,
        Gateway: gateway.Name,
        Platform: query.get('platform'),
      })
    }

    if (type === 'clone') {
      await fetchAddOrUpdateLeverageProfile({
        type,
        body: {
          ...data,
          Id: -1 * item.Id,
        },
        Gateway: gateway.Name,
        Platform: query.get('platform'),
      })
    }

    if (type === 'modify') {
      await fetchAddOrUpdateLeverageProfile({
        type,
        body: data,
        Gateway: gateway.Name,
        Platform: query.get('platform'),
      })
    }

    dispatch(
      fetchLeverageProfile({
        setLoading: params.setLoading,
        ...params.pagination,
        Gateway: gateway.Name,
        Platform: query.get('platform'),
      }),
    )
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`LeverageProfileRightbar.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine([textInput('Name').errorMessage(alreadyExist())], inputState, setInputState, 'LeverageProfileRightbar', touched, setTouched, errors)}
        <LeverageProfileTableRightbar item={item} setValidTable={setValidTable} key={item.id} />
        <Button className="t4b-bg-dark-button mt-3" onClick={handleClick}>
          <FormattedMessage id="save" />
        </Button>
      </Card.Body>
    </Card>
  )
}
