import { Action } from '../actions/actions'

const INITIAL_STATE = {
  originChannel: [],
  channel: [],
  receiver: [],
  receiverTotalCount: 0,
  originRules: [],
  rulesTotalCount: 2,
  rulesTable: [],
  rules: [],
  initiators: [],
  initiatorsMask: [],
}

function NotificationReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case Action.NotificationChannel:
      return {
        ...state,
        channel: action.data,
        originChannel: action.data,
      }

    case Action.NotificationInitiators:
      return {
        ...state,
        initiators: action.data.reduce((acc: any, rec: any) => {
          return {
            ...acc,
            [rec.EventyType]: rec.Initiators,
          }
        }, {}),
        initiatorsMask: action.data
          .map((item: any) => {
            if (!item.HasDetails) {
              return item.EventyType
            }
          })
          .filter(Boolean),
      }

    case Action.addNotificationChannel:
      return {
        ...state,
        channel: [...state.channel, action.data],
      }

    case Action.deleteNotificationChannel:
      return {
        ...state,
        channel: state.channel.map((item: any) => {
          if (item.Id !== action.data.Id) {
            return item
          } else {
            return {
              ...item,
              Deleted: true,
            }
          }
        }),
      }

    case Action.updateNotificationChannel:
      const newChanel = state.channel.map((item: any) => {
        if (item.Id === action.data.Id) {
          item = action.data
          return item
        } else {
          return item
        }
      })

      return {
        ...state,
        channel: newChanel,
      }

    case Action.originNotificationChannel:
      return {
        ...state,
        channel: state.originChannel,
      }

    ////////////////////////////
    case Action.NotificationReceiver:
      const map = new Map()
      const channels: any = state.channel.reduce((acc: any, rec: any) => {
        return {
          ...acc,
          [rec.Name]: [
            acc[rec?.Name],
            rec.Receivers.map((item: any) => {
              return item.Id
            }),
          ].flat(Infinity),
        }
      }, {})

      for (const [key, value] of Object.entries(channels)) {
        // @ts-ignore
        for (const item of value) {
          if (!map.has(item)) {
            map.set(item, [key])
          } else {
            map.set(item, [...map.get(item), key])
          }
        }
      }

      const data = action.data.Data.map((item: any) => {
        return {
          ...item,
          Channel: map.get(item.Id),
        }
      })

      return {
        ...state,
        receiver: data,
        receiverTotalCount: action.data.TotalCount,
      }

    case Action.deleteNotificationReceiver:
      return {
        ...state,
        receiver: state.receiver.filter((item: any) => item.Id !== action.data.Id),
      }

    case Action.updateNotificationReceiver:
      const newReceiver = state.receiver.map((item: any) => {
        if (item.Id === action.data.Id) {
          item = action.data
          return item
        } else {
          return item
        }
      })

      return {
        ...state,
        receiver: newReceiver,
      }

    ////////////////////////////////////////

    case Action.NotificationRule:
      return {
        ...state,
        originRules: action.data.Data,
        rules: action.data.Data,
        rulesTotalCount: action.data.TotalCount,
        rulesTable: action.data.Data.map((item: any) => {
          return {
            ...item,
            Channels: item.Channels?.map((item: any) => item.Name).join(', '),
            Receivers: item.Receivers?.map((item: any) => item.Name).join(', '),
          }
        }),
      }

    case Action.originNotificationRule:
      return {
        ...state,
        rules: state.originRules,
      }

    case Action.deleteNotificationRule:
      return {
        ...state,
        rules: state.rules.filter((item: any) => item.Id !== action.data.Id),
        rulesTable: state.rulesTable.filter((item: any) => item.Id !== action.data.Id),
      }

    case Action.addNotificationRuleChannel:
      const newDataChannel = {
        Name: '',
        Id: -Math.floor(Math.random() * (10000 - 1 + 1)) + 1,
      }
      return {
        ...state,
        rules: state.rules.map((item: any) => {
          if (item.Id === action.data.id) {
            return {
              ...item,
              Channels: [...item.Channels, newDataChannel],
            }
          } else {
            return item
          }
        }),
      }

    case Action.addNotificationRule:
      return {
        ...state,
        rules: [...state.rules, action.data],
      }

    case Action.deleteNotificationRuleReceiver:
      return {
        ...state,
        rules: state.rules.map((item: any) => {
          if (item.Id === action.data.id) {
            return {
              ...item,
              Receivers: item.Receivers.filter((item: any) => item.Id !== action.data.value),
            }
          } else {
            return item
          }
        }),
      }

    case Action.deleteNotificationRuleChannel:
      return {
        ...state,
        rules: state.rules.map((item: any) => {
          if (item.Id === action.data.id) {
            return {
              ...item,
              Channels: item.Channels.filter((item: any) => item.Id !== action.data.value),
            }
          } else {
            return item
          }
        }),
      }

    case Action.addNotificationRuleReceiver:
      const newDataReceiver = {
        Name: '',
        Id: -Math.floor(Math.random() * (10000 - 1 + 1)) + 1,
      }
      return {
        ...state,
        rules: state.rules.map((item: any) => {
          if (item.Id === action.data.id) {
            return {
              ...item,
              Receivers: [...item.Receivers, newDataReceiver],
            }
          } else {
            return item
          }
        }),
      }

    case Action.updateNotificationRuleChannel:
      return {
        ...state,
        rules: state.rules.map((item: any) => {
          if (item.Id === action.data.id) {
            return {
              ...item,
              Channels: action.data.data,
            }
          } else {
            return item
          }
        }),
      }

    case Action.updateNotificationRuleReceiver:
      return {
        ...state,
        rules: state.rules.map((item: any) => {
          if (item.Id === action.data.id) {
            return {
              ...item,
              Receivers: state.receiver
                .map((item: any) => {
                  if (action.data.data.includes(item.Id)) {
                    return {
                      Id: item.Id,
                      Name: item.Name,
                    }
                  }
                })
                .filter(Boolean),
              // Receivers: item.Receivers.map((item: any) => {
              //   if (action.data.data.includes(item.Id)) {
              //     console.log(item)
              //     console.log(state.receiver)
              //     item = {
              //       Id: action.data.data.value,
              //       Name: action.data.data.label,
              //     }
              //     return item
              //   } else {
              //     return item
              //   }
              // }),
            }
          } else {
            return item
          }
        }),
      }

    case Action.updateNotificationRuleAll:
      return {
        ...state,
        rules: state.rules.map((item: any) => {
          if (item.Id === action.data.id) {
            return {
              ...item,
              ...action.data.data,
            }
          } else {
            return item
          }
        }),
      }

    default:
      return state
  }
}

export function NotificationReceiver(data: any) {
  return { type: Action.NotificationReceiver, data }
}

export function deleteNotificationReceiver(data: any) {
  return { type: Action.deleteNotificationReceiver, data }
}

export function updateNotificationReceiver(data: any) {
  return { type: Action.updateNotificationReceiver, data }
}

/////////////////////////////////////
export function NotificationChannel(data: any) {
  return { type: Action.NotificationChannel, data }
}
export function addNotificationChannel(data: any) {
  return { type: Action.addNotificationChannel, data }
}

export function deleteNotificationChannel(data: any) {
  return { type: Action.deleteNotificationChannel, data }
}

export function originNotificationChannel() {
  return { type: Action.originNotificationChannel }
}

export function updateNotificationChannel(data: any) {
  return { type: Action.updateNotificationChannel, data }
}

/////////////////////////////////
export function NotificationRule(data: any) {
  return { type: Action.NotificationRule, data }
}

export function NotificationInitiators(data: any) {
  return { type: Action.NotificationInitiators, data }
}

export function originNotificationRule() {
  return { type: Action.originNotificationRule }
}

export function deleteNotificationRule(data: any) {
  return { type: Action.deleteNotificationRule, data }
}

export function addNotificationRuleChannel(data: any) {
  return { type: Action.addNotificationRuleChannel, data }
}

export function addNotificationRuleReceiver(data: any) {
  return { type: Action.addNotificationRuleReceiver, data }
}

export function deleteNotificationRuleChannel(data: any) {
  return { type: Action.deleteNotificationRuleChannel, data }
}

export function deleteNotificationRuleReceiver(data: any) {
  return { type: Action.deleteNotificationRuleReceiver, data }
}

export function updateNotificationRuleChannel(data: any) {
  return { type: Action.updateNotificationRuleChannel, data }
}

export function updateNotificationRuleReceiver(data: any) {
  return { type: Action.updateNotificationRuleReceiver, data }
}

export function updateNotificationRuleAll(data: any) {
  return { type: Action.updateNotificationRuleAll, data }
}

export function addNotificationRule(data: any) {
  return { type: Action.addNotificationRule, data }
}

export default NotificationReducer
