import TextInput from '../../inputs/TextInput'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { buildTableHeadersFromArray } from '../../../utils/table-utils'
import { AppTable } from '@t4b/core'
import React, { useState } from 'react'
import * as yup from 'yup'

export const buildPartyIdTable = (execState: any, setExecState: any, touched: any, setTouched: any) => {
  const handlePlus = () => {
    const newItem = execState.PartyIds.push({})
    setExecState(Object.assign({}, execState, newItem))
  }

  const validate = (object: any, schema: any) => {
    const errors: { [index: string]: any } = {}

    for (const field in schema) {
      if (!schema[field].isValidSync(object[field])) {
        errors[field] = true
      }
    }
    return errors
  }

  const lpRows = execState?.PartyIds?.map((elem: any, index: number) => {
    const handleDelete = () => {
      const newState = [...execState.PartyIds]
      newState.splice(index, 1)
      const newItem = (execState.PartyIds = newState)
      setExecState(Object.assign({}, execState, newItem))
    }
    const handleStateChange = (newState: any) => {
      const newStateArr = execState.PartyIds
      newStateArr.splice(index, 1, newState)
      const newStateMarket = [...newStateArr]
      execState.PartyIds = newStateMarket
      setExecState(Object.assign({}, execState))
    }

    return (
      <tr key={index}>
        <td>
          <TextInput
            className="m-0"
            state={execState.PartyIds[index]}
            setState={handleStateChange}
            name="PartyId"
            errors={validate(execState.PartyIds[index], { PartyId: yup.string().required() })}
            touched={touched}
            setTouched={setTouched}
          />
        </td>
        <td>
          <TextInput
            className="m-0"
            state={execState.PartyIds[index]}
            setState={handleStateChange}
            name="PartyIdSource"
            errors={validate(execState.PartyIds[index], { PartyIdSource: yup.string().max(1).required() })}
            touched={touched}
            setTouched={setTouched}
          />
        </td>
        <td>
          <TextInput
            className="m-0"
            state={execState.PartyIds[index]}
            setState={handleStateChange}
            name="PartyRole"
            touched={touched}
            setTouched={setTouched}
            errors={validate(execState.PartyIds[index], {
              PartyRole: yup
                .string()
                .matches(/^[0-9.]+$/gi)
                .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
            })}
          />
        </td>
        <td className="h-100">
          <Button variant="link" className="t4b-text-gray p-0 mt-1" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </td>
      </tr>
    )
  })

  lpRows?.push(
    <tr key="plus-row" className="cursor-pointer" onClick={handlePlus}>
      <td colSpan={4}>
        <FontAwesomeIcon icon={faPlus} />
      </td>
    </tr>,
  )

  const header = buildTableHeadersFromArray(
    ['PartyId', 'PartyIDSource', 'PartyRole', 'Actions'].map((elem: string) => ({
      name: elem,
      show: true,
    })),
    'PartyIds',
  )

  const marketTable = <AppTable tableHeader={header} tableData={lpRows} scrollOptions={{ x: 'scroll', y: 'visible-hidden' }} />
  return <>{marketTable}</>
}
