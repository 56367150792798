import { Dispatch } from 'redux'
import { buildHTTPGetOptions, buildHTTPPostOptions, checkResponse, processError } from '../../utils/fetch-utils'
import { Action } from './actions'
import axios from 'axios'
import { getJwt } from './authentication'

export function onlyFetchBinance(params: any) {
  const url = new URL('/api/binanceSwap/Rules', window.location.origin)
  url.searchParams.set('gateway', params.Name)
  url.searchParams.set('lpTypeName', params.local.pathname.split('/')[3] === 'binance-futures-coin-swap' ? 'BinanceFuturesCoin' : 'BinanceFutures')
  url.searchParams.set('page', params.pagination.Page)
  url.searchParams.set('count', params.pagination.Count)
  url.searchParams.set('sort', params.sortOptions.field)
  url.searchParams.set('by', params.sortOptions.by)

  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response))
}

export function fetchBinance(params: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return onlyFetchBinance(params)
      .then((data: any) => {
        dispatch({ type: Action.BinanceFutures, data: data })
      })
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export const fetchPlatformMt5 = (params: any) => {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/platforms', document.location.origin)
    url.searchParams.set('gateway', params.gateway.Name)

    return axios(url.toString(), {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    })
      .then((statusResponse: any) => {
        const platformsName = statusResponse.data
          // eslint-disable-next-line array-callback-return
          .map((item: any) => {
            if (item.Type === 'mt5') return item.Name
          })
          .filter(Boolean)
        params.setPlatform(platformsName)
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}

export const updatefetchBinance = (params: any) => {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/binanceSwap/UpdateBinanceSwapRules', window.location.origin)
    url.searchParams.set('gateway', params.Name)
    url.searchParams.set('lpTypeName', params.local.pathname.split('/')[3] === 'binance-futures-coin-swap' ? 'BinanceFuturesCoin' : 'BinanceFutures')

    return fetch(url.toString(), buildHTTPPostOptions(params.binanceS.BinanceFuturesSwap))
      .then((response: Response) => {
        if (response.status === 200) {
        }
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function getBinanceSwapRuleItems(data: any) {
  return { type: Action.GotBinanceSwapRuleItems, data }
}
