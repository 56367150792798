import * as yup from 'yup'

export class DropCopySessionEntity {
  SenderCompId: string
  TargetCompId: string
  BeginString: string
  Username: string
  Password: string
  State: string

  constructor(item?: any) {
    this.SenderCompId = item?.SenderCompId ?? ''
    this.TargetCompId = item?.TargetCompId ?? ''
    this.BeginString = item?.BeginString ?? 'FIX.4.4'
    this.Username = item?.Username ?? ''
    this.Password = item?.Password ?? ''
    this.State = item?.State ?? ''
  }
}

export class DropCopySettingsEntity {
  DropCopyEnabled: boolean
  SocketAcceptPort: number
  UseLocalTime: boolean
  StartTime: number
  EndTime: number
  SSLEnable: boolean
  FileStorePath: string
  FileLogPath: string
  AutoSwitchDST: boolean

  static schema = {
    DropCopyEnabled: yup.boolean().required(),
    UseLocalTime: yup.boolean().required(),
    StartTime: yup.number().required(),
    EndTime: yup.number().required(),
    SSLEnable: yup.boolean().required(),
    FileStorePath: yup.string().required(),
    FileLogPath: yup.string().required(),
  }

  constructor(item?: any) {
    this.AutoSwitchDST = item?.AutoSwitchDST ?? true
    this.DropCopyEnabled = item?.DropCopyEnabled ?? false
    this.SocketAcceptPort = item?.SocketAcceptPort ?? 8813
    this.UseLocalTime = item?.UseLocalTime ?? false
    this.StartTime = item?.StartTime ?? 0
    this.EndTime = item?.EndTime ?? 24 * 60 * 60 * 1000 - 1
    this.SSLEnable = item?.SSLEnable ?? true
    this.FileStorePath = item?.FileStorePath ?? 'Store'
    this.FileLogPath = item?.FileLogPath ?? 'Logs'
  }
}
