import { Action } from '../actions/actions'

const quoteInitial = {
  quotes: new Map(),
  selection: new Set(),
}

function quotesReducer(state = quoteInitial, action: any) {
  switch (action.type) {
    case Action.GotQuotes: {
      const newState = new Map(state.quotes)
      newState.set(`${action.data.SourceType}_${action.data.SourceName}_${action.data.OrderBook.Symbol}`, action.data)
      return {
        ...state,
        quotes: newState,
      }
    }
    case Action.QuoteSourceSelected: {
      let selection = new Set(state.selection)
      // const key = `${action.data.SourceType}_${action.data.SourceName}_${action.data.Symbol}`;
      const key = action.data
      if (selection.has(key)) {
        selection.delete(key)
      } else if (selection.size === 2) {
        selection = new Set([key])
      } else {
        selection.add(key)
      }
      return {
        ...state,
        selection,
      }
    }
    case Action.QuoteChangeSelection: {
      let selection = new Set(state.selection)
      selection.delete(action.data.unselect)
      selection.add(action.data.select)
      return {
        ...state,
        selection,
      }
    }
    default:
      return state
  }
}

export default quotesReducer
