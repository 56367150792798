import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, mselectInput, sselectInput, textInput } from '../../utils/controls'
import { useFormValidation } from '../../hooks/useFormValidation'
import * as yup from 'yup'
import { IRightbar } from './rightbar-types'
import { MaxLength } from '../../utils/schema-utils'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'

const schema = {
  PlatformSymbol: yup.string().required(),
  GatewaySymbol: yup.string().required(),
  Digits: yup.number().min(0).max(28).required(),
  VolumeStep: yup
    .string()
    .matches(/^[0-9.]+$/gi)
    .transform(MaxLength)
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
  ContractSize: yup
    .string()
    .matches(/^[0-9.]+$/gi)
    .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0)
    .transform(MaxLength),
}

const PlatformSymbolRightbar: React.FC<IRightbar> = ({ data: { item, options, onChange, platformName, gateway } }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    { ...item, VolumeStep: String(item.VolumeStep), ContractSize: String(item.ContractSize) },
    schema,
  )

  const findTagsPlatform = gateway?.Platforms?.find((item: any) => item.Name === platformName)?.Tags

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    if (onChange) {
      onChange(inputState)
    }
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id="platform-symbol-settings.title" />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            sselectInput('PlatformSymbol', options).disabled(true),
            sselectInput('GatewaySymbol', options),
            mselectInput('Tags', buildMultiselectOptionsFromArray(findTagsPlatform), true, true, 'Add tag:', 'Mask', true),
            textInput('Digits'),
            textInput('VolumeStep'),
            textInput('ContractSize'),
          ],
          inputState,
          setInputState,
          'platform.symbol-map',
          touched,
          setTouched,
          errors,
        )}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" />
        </Button>
      </Card.Body>
    </Card>
  )
}
export default PlatformSymbolRightbar
