import { Action } from '../actions/actions'

class ModalState {
  public modalName: string | null
  public modalData: any

  constructor(modalName = null, modalData = null) {
    this.modalName = modalName
    this.modalData = modalData
  }
}

export function modalsReducer(state = new ModalState(), action: any) {
  switch (action.type) {
    case Action.ShowModal:
      return {
        ...state,
        modalName: action.payload.modalName,
        modalData: action.payload.modalData,
      }
    case Action.HideModal:
      return new ModalState()
  }
  return state
}
