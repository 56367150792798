import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { buildHTTPGetOptions, checkResponse, processError } from '../../utils/fetch-utils'
import {
  brokerProfit,
  buildInfoItems,
  buildSummaryDeals,
  idVisible,
  infoDirectionDeals,
  infoMs,
  infoProfitDealsMt4,
  infoProfitDerection,
  infoTimeNotUtc,
} from '../../utils/info-item-utils'
import { AppAccordion, AppTable } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'
import { buildTableData, buildTableHeadersConfigured } from '../../utils/table-utils'
import { aggregationBar } from '../../const/TradingHistoryConst'
import { showModal } from '../../redux/actions/modal-actions'
import { ModalType } from '../modals/modals'
import { throwErrorMessage } from '../../utils/errors-utils'
import { Action } from '../../redux/actions/actions'
import { buildDealsUrl } from '../../redux/actions/deals-actions'

const MT5Deals: React.FC<IRightbar> = ({
  data: {
    Gateway,
    Platform,
    position: { Deal, Order },
  },
}) => {
  const dispatch = useDispatch()
  const [position, setPosition] = useState<any>({})

  useEffect(() => {
    const url = new URL('/api/deal', window.location.origin)
    url.searchParams.set('gateway', Gateway)
    url.searchParams.set('platform', Platform)
    url.searchParams.set('id', Deal)
    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => setPosition(data))
      .catch((error: Error) => processError(error, dispatch))
  }, [dispatch, Gateway, Platform, Deal])

  const [sortOptions, setSortOptions] = React.useState({
    field: '',
    by: 'none',
  })

  const table = (
    <AppTable
      tableHeader={buildTableHeadersConfigured(aggregationBar, sortOptions, setSortOptions, 'aggregationDeals')}
      tableData={buildTableData(
        position?.LpExecutions ?? [],
        aggregationBar,
        () => {},
        () => {},
      )}
      scrollOptions={{ x: 'scroll', y: 'visible-hidden' }}
    />
  )

  const directionRowOne = buildInfoItems([idVisible('Deal'), 'Login', 'Symbol', infoDirectionDeals('Action'), 'Volume', 'LotVolume'], position, 'mt5deals', 'default', true)
  const directionRowTwo = buildInfoItems(['LpOrderId', 'ExternalId', 'PositionId', 'OpenPrice', 'Platform', infoTimeNotUtc('Time')], position, 'mt5deals', 'default', true)

  const priceRowOne = buildInfoItems(
    [infoProfitDealsMt4('PlatformRequestPrice', false, position.Action), 'LpRequestPrice', 'TpRequestPrice'],
    position,
    'mt5deals',
    'default',
    true,
  )
  const priceRowTwo = buildInfoItems([infoProfitDealsMt4('Price', false, position.Action), 'GatewayPrice'], position, 'mt5deals', 'default', true)

  const priceRowTree = buildInfoItems(['TpMarkup', 'TotalMarkup'], position, 'mt5deals', 'default', true)
  const priceRowFour = buildInfoItems(['LpPriceSlippage', 'PlatformPriceSlippage'], position, 'mt5deals', 'default', true)

  const profitRowOne = buildInfoItems(
    [infoProfitDerection('TpBrokerProfit', !String(position?.TpBrokerProfit)?.includes('-')), brokerProfit('Profit')],
    position,
    'mt5deals',
    'default',
    true,
  )

  const additionalExecutionTimestampsOne = buildInfoItems(['PlatformRequestTimestamp', 'LpRequestTimestamp', 'LpExecutionTimestamp'], position, 'mt5deals', 'additional', true)
  const additionalExecutionTimestampsTwo = buildInfoItems(['LpAnswerTimestamp', 'TpExeucutionTime', 'PlatformExecutionTimestamp'], position, 'mt5deals', 'additional', true)

  const additionalRowOne = buildInfoItems([infoMs('LpExecutionTime'), infoMs('TpExecutionTime')], position, 'mt5deals', 'default', true)
  const additionalRowTwo = buildInfoItems([infoMs('FullExecutionTime')], position, 'mt5deals', 'default', true)
  const additionalRowTree = buildInfoItems(['UserName', 'MtOrderType', 'Digits', 'Group'], position, 'mt5deals', 'default', true)
  const additionalRowFour = buildInfoItems(['Dealer', 'ContractSize', 'Comment'], position, 'mt5deals', 'default', true)
  const additionalRowFive = buildInfoItems(['Placement'], position, 'mt5deals', 'default', true)

  const showModalDetails = () => {
    const url = buildDealsUrl({
      Gateway,
      Platform,
      Order,
      data: position,
      PageName: 'bookSnapshotReport',
    })
    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then(data => {
        if (data.Errors?.length) {
          throwErrorMessage(data.Errors.join(' '), true)
          return
        } else {
          dispatch({ type: Action.GotReport, data })
          dispatch(
            showModal(ModalType.ModalDetails, {
              headerText: 'ModalDetails',
              params: {
                Gateway,
                Platform,
                Order,
                data: position,
                PageName: 'reportDownload',
              },
            }),
          )
        }
      })
      .catch((error: Error) => processError(error, dispatch))
  }

  return (
    <>
      <Card>
        <Card.Header
          style={{
            color: 'rgb(141, 171, 196)',
            fontWeight: 500,
            fontSize: '13px',
            textTransform: 'uppercase',
          }}
        >
          <FormattedMessage id="deal-details" />
        </Card.Header>
        <Card.Body>
          <div className="mb-2">
            <span style={{ color: '#324766', fontWeight: 600, fontSize: 14 }}>General</span>
            <section className="row mt-2">
              <div className="col-6">{directionRowOne}</div>
              <div className="col-6">{directionRowTwo}</div>
            </section>
            <div className="dealsOrder mt-2"></div>
          </div>
          <div className="mb-2">
            <span style={{ color: '#324766', fontWeight: 600, fontSize: 14 }}>Profit</span>
            <section className="row  mt-2">
              <div className="col-6">{profitRowOne}</div>
              <div className="col-6">
                <div className="row default info-item">
                  <div className="col-6" style={{ fontSize: 13, fontWeight: 400, color: '#7094B2' }}>
                    Total broker profit
                  </div>
                  <div className="col-6">
                    <div
                      className={String(position?.TotalBrokerProfit)?.includes('-') ? 'brokerProfitMinus pl-1 pr-1' : 'brokerProfit pl-1 pr-1'}
                    >{`${position['TotalBrokerProfit']} `}</div>
                    {String(position?.TotalBrokerProfit) === String(position?.TotalBrokerProfitUsd) ? null : (
                      <>
                        <span style={{ height: 21, color: '#30527A', fontSize: 16, fontWeight: 500 }}></span>
                        <div
                          className={String(position?.TotalBrokerProfitUsd)?.includes('-') ? 'brokerProfitMinus pl-1 pr-1' : 'brokerProfit pl-1 pr-1'}
                        >{` ${position['TotalBrokerProfitUsd']}`}</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <div className="dealsOrder mt-2"></div>
          </div>
          <div className="mb-2">
            <span style={{ color: '#324766', fontWeight: 600, fontSize: 14 }}>Price</span>
            <section className="row mt-2 relative">
              <span className="modalDetailsMT5" onClick={showModalDetails}>
                Details...
              </span>
              <div className="col-6">{priceRowOne}</div>
              <div className="col-6">{priceRowTwo}</div>
            </section>
            <div className="dealsOrder mt-2"></div>
          </div>
          <section className="row">
            <div className="col-6">{priceRowTree}</div>
            <div className="col-6">{priceRowFour}</div>
          </section>
        </Card.Body>
      </Card>

      <AppAccordion
        item={{
          title: <FormattedMessage id="additional" />,
          item: (
            <>
              <section className="row mb-2">
                <div className="col-6">{additionalExecutionTimestampsOne}</div>
                <div className="col-6">{additionalExecutionTimestampsTwo}</div>
                <div className="dealsOrder mt-2"></div>
              </section>
              <section className="row mb-2">
                <div className="col-6">{additionalRowOne}</div>
                <div className="col-6">{additionalRowTwo}</div>
                <div className="dealsOrder mt-2"></div>
              </section>
              <section className="row mb-2">
                <div className="col-6">{additionalRowTree}</div>
                <div className="col-6">{additionalRowFour}</div>
                <div className="dealsOrder mt-2"></div>
              </section>
              <section className="row">
                <div className="col-6">{additionalRowFive}</div>
              </section>
            </>
          ),
        }}
        style={{ fontSize: '13px' }}
        isHidden={false}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="mt5.volume-distribution" />,
          item: [
            <div className="mr-2 mt-2 mb-4">{buildSummaryDeals(position.Summary)}</div>,
            <div className="mt-2 mb-4">
              <span style={{ color: '#324766', fontWeight: 600 }} className="mb-4">
                Details
              </span>
              <div style={{ marginTop: 16 }}> {table}</div>
            </div>,
          ],
        }}
        style={{ fontSize: '14px' }}
        isHidden={false}
        render={position?.Summary?.length}
      />
    </>
  )
}

export default MT5Deals
