import React, { useCallback, useEffect, useState, useRef, memo } from 'react'
import cls from './chatBot.module.scss'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { getKeyChatBot } from '../../redux/actions/authentication'
import { Rnd } from 'react-rnd'
import { Button } from './components/Button'
import { Messages } from './components/Messages'
import { SuggestedQuestions } from './components/SuggestedQuestions'
import { Input } from './components/Input'
import { RootState } from '../../redux/reducers/rootReducer'
import { UpdateOpen } from '../../redux/reducers/ChatBotReducer'
import { Header } from './components/Header'

interface IResize {
  width?: number
  height?: number
  x?: number
  y?: number
}

export const ChatBot = memo(() => {
  const dispatch = useDispatch()
  const { FetchStatus, Open } = useSelector((state: RootState) => state.ChatBot)
  const scrollRef = useRef<OverlayScrollbarsComponent>(null)
  const [resize, setResize] = useState<IResize>({})

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.osInstance()?.scroll({ y: '100%' }, 300, 'easeOutQuint')
    }
  }, [FetchStatus, Open])

  useEffect(() => {
    window.addEventListener('keydown', onKeyPressEscape)
    return () => {
      window.removeEventListener('keydown', onKeyPressEscape)
    }
  }, [])

  const onKeyPressEscape = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      dispatch(UpdateOpen(false))
    }
  }, [])

  const onOpen = useCallback(() => {
    dispatch(UpdateOpen(true))
  }, [])

  let content = null

  if (!Open) {
    content = <Button text="Assistant" cb={onOpen} icon={faMessage} cls={cls.wrapperClose} type="prompt" placement="left" />
  } else {
    content = (
      <Rnd
        style={{ zIndex: 1000000 }}
        onResize={(e, direction, ref, delta, position) => {
          setResize({
            width: ref.offsetWidth,
            height: ref.offsetHeight,
            ...position,
          })
        }}
        onDrag={(_, data) => {
          setResize(prev => ({
            ...prev,
            x: data.x,
            y: data.y,
          }))
        }}
        default={{
          x: resize.x ?? window.innerWidth - 460,
          y: resize.y ?? window.innerHeight - 835,
          width: resize.width ?? 450,
          height: resize.height ?? 725,
        }}
      >
        <div className={cls.wrapperOpen} style={{ minWidth: 324, minHeight: 453 }}>
          <Header />
          <OverlayScrollbarsComponent ref={scrollRef} style={{ minWidth: 324, height: resize.height ? resize.height - 225 : '500px', width: '100%', minHeight: 453 - 225 }}>
            <Messages />
          </OverlayScrollbarsComponent>
          <SuggestedQuestions />
          <Input />
        </div>
      </Rnd>
    )
  }

  return !Boolean(getKeyChatBot()?.AIAssistantId) || !Boolean(getKeyChatBot()?.AIAssistantApiKey) ? <></> : content
})
