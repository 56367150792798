import { useFormValidation } from '../../../hooks/useFormValidation'
import * as yup from 'yup'
import { transformEmptyStringMaxLength } from '../../../utils/schema-utils'
import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

export const FeedPlatformGapRow = ({ index, symbol, price, onChange, setValid }: any) => {
  const [inputStatePrices, setInputStatePrices, touchedPrices, setTouchedPrices, errorsPrices, isValidPrices] = useFormValidation(
    { price: String(price) },
    {
      price: yup
        .string()
        .matches(/^[0-9.]+$/gi)
        .transform(transformEmptyStringMaxLength)
        .test('', '', value => value > 0)
        .required(),
    },
  )

  useEffect(() => {
    setValid(isValidPrices())
  }, [inputStatePrices.price])

  const handleBlur = () => {
    setTouchedPrices &&
      setTouchedPrices({
        ...touchedPrices,
        price: true,
      })
  }

  const handleChange = (symbol: any, value: any) => {
    setInputStatePrices({ price: value })
    onChange(symbol, value)
  }

  return (
    <td className="tableTd" style={{ width: '50%' }} key={index}>
      <Form.Control
        type="text"
        placeholder={'price'}
        value={inputStatePrices['price']}
        onChange={event => handleChange(symbol, event.target.value)}
        onBlur={handleBlur}
        isInvalid={errorsPrices['price'] && touchedPrices['price']}
      />
      <Form.Control.Feedback type="invalid">
        <FormattedMessage id={'field-validation-error'} tagName="span" />
      </Form.Control.Feedback>
    </td>
  )
}
