import React from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { withTooltip } from '@t4b/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

interface IMultiSelectInput {
  state: any
  name: string
  options: any[]
  className?: string
  style?: any
  touched?: any
  errors?: any
  errorText?: string
  label?: string
  isDisabled?: boolean
  isCreatable?: boolean
  placeholder?: string
  tooltip?: string
  tooltipFlag?: boolean
  CreateTitle?: string
  schema?: any
  flag?: boolean
  zIndex?: boolean
  zIndexNumber?: number
  symbolValid?: number
  textWarning?: any

  setState(state: any): void

  setTouched?(touched: any): void

  onCreateOption?(value: any): void
}

const MultiSelectInput: React.FC<IMultiSelectInput> = props => {
  const {
    state,
    tooltip,
    tooltipFlag,
    setState,
    options,
    label,
    schema,
    name,
    isDisabled,
    isCreatable,
    className,
    touched,
    setTouched,
    errors,
    flag,
    errorText,
    style,
    onCreateOption,
    placeholder,
    CreateTitle,
    zIndex,
    zIndexNumber,
    symbolValid,
    textWarning,
  } = props

  const handleChange = (event: any) => {
    setState({
      ...state,
      [name]: event ? event : [],
    })
  }

  const handleBlur = () => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
  }

  const isInvalid = touched && touched[name] && errors && errors[name]

  const element = isCreatable ? (
    <CreatableSelect
      isMulti={true}
      options={options}
      aria-invalid={isInvalid}
      onChange={handleChange}
      onBlur={handleBlur}
      onCreateOption={onCreateOption}
      value={state[name]}
      placeholder={placeholder}
      isDisabled={!!isDisabled}
      formatCreateLabel={str => `${CreateTitle} ${str}`}
      styles={
        {
          ...style,
          control: baseStyles => ({
            ...baseStyles,
            borderColor: isInvalid ? '#dc3545' : '#ced4da',
            background: isDisabled ? '#e9ecef' : baseStyles.background,
            '&:hover': {
              borderColor: undefined,
            },
            '&:focus': {
              borderColor: undefined,
            },
          }),
        } ?? undefined
      }
    />
  ) : (
    <Select
      isMulti={true}
      options={options}
      onChange={handleChange}
      onBlur={handleBlur}
      value={state[name]}
      placeholder={placeholder}
      isDisabled={!!isDisabled}
      styles={
        {
          ...style,
          control: baseStyles => ({
            ...baseStyles,
            borderColor: isInvalid || symbolValid === 0 ? '#dc3545' : '#ced4da',
            background: isDisabled ? '#e9ecef' : baseStyles.background,
            '&:hover': {
              borderColor: undefined,
            },
            '&:focus': {
              borderColor: undefined,
            },
          }),
        } ?? undefined
      }
    />
  )

  const renderTooltip = (props?: any, tooltip?: string) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )

  let isInvalidYup = false

  if (schema) {
    try {
      schema.validateSync(state[name])
    } catch (e) {
      isInvalidYup = true
    }
  }

  const danger =
    textWarning &&
    withTooltip(
      <span className="text-warning mr-1">
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </span>,
      <FormattedMessage id="dangerStatementsEmails" />,
      Math.random(),
    )

  return (
    <>
      {tooltipFlag ? (
        <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, tooltip)}>
          <Form.Group className={className} style={zIndex ? { zIndex: zIndexNumber, position: 'relative' } : {}}>
            {label ? (
              <Form.Label>
                {danger}
                <FormattedMessage id={label} tagName="span" />
                <span>:</span>
              </Form.Label>
            ) : null}
            {element}
            {isInvalidYup ? (
              <div className={flag ? 'd-block invalid-feedback test' : 'd-block invalid-feedback test position-button'}>
                <FormattedMessage id="field-validation-error" tagName="span" />
              </div>
            ) : null}
            <div className={`invalid-feedback ${isInvalid && 'd-block'}`}>
              <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
            </div>
          </Form.Group>
        </OverlayTrigger>
      ) : (
        <Form.Group className={className} style={zIndex ? { zIndex: zIndexNumber, position: 'relative' } : {}}>
          {label ? (
            <Form.Label>
              {danger}
              <FormattedMessage id={label} tagName="span" />
              <span>:</span>
            </Form.Label>
          ) : null}
          {element}
          {isInvalidYup ? (
            <div className={flag ? 'd-block invalid-feedback test' : 'd-block invalid-feedback test position-button'}>
              <FormattedMessage id="field-validation-error" tagName="span" />
            </div>
          ) : null}
          <div className={`invalid-feedback ${isInvalid && 'd-block'}`}>
            <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
          </div>
        </Form.Group>
      )}
    </>
  )
}
export default MultiSelectInput
