import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/rootReducer'
import { hideModal } from '../../redux/actions/modal-actions'
import { FormattedMessage } from 'react-intl'
import { Button, Modal } from 'react-bootstrap'
import SearchInput from '../inputs/SearchInput'
import * as yup from 'yup'
import { fetchCheckTimeout, fetchUpdateCheckTimeout } from '../../redux/actions/risk-tool-actions'

const urlSchema = yup.object().shape({
  CheckTimeout: yup
    .string()
    .matches(/^[0-9]+$/gi)
    .test('', '', value => +value > 0)
    .test('', '', value => +value <= 999999999),
})
export const ModalCheckTimeout = () => {
  const dispatch = useDispatch()
  const { modalData } = useSelector((state: RootState) => state.modals)
  const { CheckTimeout } = useSelector((state: RootState) => state.riskTool)
  const [inputState, setInputState] = useState({ CheckTimeout: '' })
  const handleYes = () => {
    if (urlSchema.isValidSync(inputState)) {
      dispatch(
        fetchUpdateCheckTimeout({
          ...modalData.body,
          CheckTimeout: inputState.CheckTimeout,
        }),
      )
      dispatch(hideModal())
    }
  }

  const handleNo = () => {
    dispatch(hideModal())
  }

  useEffect(() => {
    dispatch(
      fetchCheckTimeout({
        ...modalData.body,
      }),
    )
  }, [])

  useEffect(() => {
    setInputState({ CheckTimeout: CheckTimeout })
  }, [CheckTimeout])

  return (
    <Modal show={true} onHide={handleNo}>
      <Modal.Header closeButton={true} className="color-dark font-500">
        <FormattedMessage id={modalData.headerText} />
      </Modal.Header>
      <Modal.Body>
        <div className={'checkTimeoutLabel'}>
          <FormattedMessage id="CheckTimeout.label" values={{ Platform: modalData.body.Platform }} />
        </div>
        <SearchInput
          state={inputState}
          setState={setInputState}
          name="CheckTimeout"
          className="settings-block__field"
          schema={yup
            .string()
            .matches(/^[0-9]+$/gi)
            .max(999999999)
            .test('', '', value => +value > 0)
            .test('', '', value => +value <= 999999999)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleYes}>
          <FormattedMessage id="yes" />
        </Button>
        <Button onClick={handleNo}>
          <FormattedMessage id="no" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
