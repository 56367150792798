import chroma from 'chroma-js';
import colors from './colors.json';
import { NONE } from './special-colors';

export function isNoneColor(color) {
  if (typeof color === 'string') {
    return color === NONE.name || color === NONE.color;
  }
  return color.name === NONE.name || color.color === NONE.color;
}

export function fromArray(array) {
  if (!Array.isArray(array)) {
    return [];
  }

  return array
    .filter(item => chroma.valid(item) || isNoneColor(item))
    .map(item => {
      const descr = colors.find(color => color.color === item);

      if (descr) {
        return descr;
      }

      if (isNoneColor(item)) {
        return NONE;
      }

      return {
        name: item,
        color: item
      }
    })
}

export function toArray(array) {
  if (Array.isArray(array)) {
    return array.map(item => {
      if (isNoneColor(item)) {
        return NONE.name;
      }
      return item.color
    });
  }

  return [];
}