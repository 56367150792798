import { Action } from '../actions/actions'
import { round } from '../../utils/num-utils'

class ExposureState {
  public exposure: any
  public currencies: string[]
  public lps: string[]
  public volumeSeries: any[]
  public profitSeries: any[]

  constructor() {
    this.exposure = {
      positions: [],
      volume: [],
      profit: [],
    }
    this.currencies = []
    this.lps = []
    this.volumeSeries = []
    this.profitSeries = []
  }
}

function roundProfit(profit: any) {
  for (const key in profit) {
    profit[key] = round(profit[key])
  }

  return profit
}

function chartSeries(obj: any) {
  const res = []

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      res.push({
        name: key,
        data: [obj[key]],
        type: 'column',
      })
    }
  }

  return res
}

function exposureReducer(state = new ExposureState(), action: any) {
  switch (action.type) {
    case Action.ExposureInitial:
      return new ExposureState()
    case Action.GotExposure:
      return {
        ...state,
        exposure: {
          ...action.data,
          profit: roundProfit(action.data.profit),
        },
        volumeSeries: chartSeries(action.data.volume),
        profitSeries: chartSeries(action.data.profit),
      }
    case Action.GotExposureCurrencies:
      return {
        ...state,
        currencies: action.data,
      }
    // case Action.GotExposureLps:
    //   return {
    //     ...state,
    //     lps: action.data,
    //   }
    default:
      return state
  }
}

export default exposureReducer
