import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { CeRuleEntity } from '../../entity/ce-entity'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchCeRulesUpdate } from '../../redux/actions/execution/ce-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, textInput } from '../../utils/controls'
import { IRightbar } from './rightbar-types'
import { isTrimString } from '../../utils/multiselect-utils'

const CeRuleRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(item, CeRuleEntity.schema)

  const handleClick = () => {
    if (!isValid()) {
      return
    }

    const newTrimState = {
      ...inputState,
      Symbol: isTrimString(inputState.Symbol),
    }

    dispatch(hideRightBar())
    dispatch(fetchCeRulesUpdate({ action: type, rule: newTrimState, ...params }))
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`ce-rules.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine([textInput('Symbol', false, '', '', 'Mask', true), textInput('MaxOrderVolume'), textInput('Duration'), textInput('Timeout')], inputState, setInputState, 'ce-rules', touched, setTouched, errors)}

        <Button className="t4b-bg-dark-button mt-3" onClick={handleClick}>
          <FormattedMessage id="save" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default CeRuleRightbar
