import React, { FC, useEffect, useRef, useState } from 'react'
import { useFormValidation } from '../../hooks/useFormValidation'
import { Schedule } from '../../entity/configuration'
import AppSchedule from '../AppSchedule'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { useDispatch, useSelector } from 'react-redux'
import { FeedExecPresetEntity } from '../../entity/Preset-entitu'
import { buildControlsExtTwoPerLine, checkboxInput, textInput } from '../../utils/controls'
import { AppAccordion } from '@t4b/core'
import { changeFeedingLp, changeFeedingPreset, feedingConfigurationChanged } from '../../redux/actions/feeding-actions'
import { addFeedPlatform, changeFeedAggregation, changeFeedAggregationPlat, ChangeFeedPlatformPreset } from '../../redux/actions/execution/execution-actions'
import { Action } from '../../redux/actions/actions'
import { alreadyExistPresetBar } from './lpRightbarUtils/utils'

const FeedExecPresetBar: FC<any> = ({ data: { type, item, prevPresetName } }) => {
  const dispatch = useDispatch()
  const feedingConfiguration = useSelector((state: any) => state.feedingConfiguration)
  const scheduleRef = useRef<any>(null)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new FeedExecPresetEntity(item), FeedExecPresetEntity.schema)
  const [schedule, setSchedule, scheduleTouched, setScheduleTouched, scheduleErrors, isScheduleValid] = useFormValidation(
    new Schedule({ ...item, ScheduleType: { value: item.ScheduleType, label: item.ScheduleType }, Begin: item.ScheduleType !== 'Range' ? Date.now() : item.Begin, End: item.ScheduleType !== 'Range' ? Date.now() : item.End }),
    Schedule.schema,
  )

  const [findIdMax, setFindIdMax] = useState(feedingConfiguration.Profile.map((item: any) => Math.abs(item.Id)))
  useEffect(() => {
    setFindIdMax(Math.max(...feedingConfiguration.Profile.map((item: any) => Math.abs(item.Id))))
  }, [feedingConfiguration.Profile.lenght]) // eslint-disable-line react-hooks/exhaustive-deps

  const table: any = {}
  const tablePreset = feedingConfiguration.Profile.filter((item: FeedExecPresetEntity) => !table[item?.Name] && (table[item?.Name] = 1))

  const handleSave = () => {
    const cond1 = isValid()
    if (!cond1) {
      return
    } else {
      if (type === 'add') {
        dispatch({
          type: Action.GotFeedingPreset,
          data: new FeedExecPresetEntity({
            ...inputState,
            ...schedule,
            ScheduleType: schedule.ScheduleType.value,
            Id: -1 * (feedingConfiguration.Profile.length === 0 ? feedingConfiguration.Profile.length + 1 : findIdMax + 1),
          }),
        })
        dispatch(feedingConfigurationChanged())
        dispatch(hideRightBar())
      }
      if (type === 'modify') {
        feedingConfiguration.lp.forEach((el: any) => {
          if (el.bunchProfiles.includes(prevPresetName)) {
            dispatch(
              changeFeedingLp({
                ...el,
                bunchProfiles: [...el.bunchProfiles.filter((item: any) => item !== prevPresetName), inputState.Name],
                Profile: inputState.Name,
                flag: true,
              }),
            )
          }
        })

        feedingConfiguration.feedPlatforms.forEach((el: any) => {
          if (el.Profile === prevPresetName) {
            dispatch(
              ChangeFeedPlatformPreset({
                ...el,
                Profile: inputState.Name,
                Enabled: inputState.Enabled,
                prevPresetName: prevPresetName,
              }),
            )
          }
        })
        feedingConfiguration.feedAgregations.forEach((el: any) => {
          if (el.bunchProfiles.includes(prevPresetName)) {
            dispatch(
              changeFeedAggregationPlat({
                ...el,
                Profile: inputState.Name,
                Enabled: inputState.Enabled,
                bunchProfiles: [...el.bunchProfiles.filter((item: any) => item !== prevPresetName), inputState.Name],
              }),
            )
          }
        })

        dispatch(
          changeFeedingPreset({
            ...item,
            ...schedule,
            ScheduleType: schedule.ScheduleType.value,
            Id: item.Id,
            Enabled: inputState.Enabled,
            Name: inputState.Name,
            Description: inputState.Description,
            flag: true,
            bunchProfiles: [...item.bunchProfiles, inputState.Name],
          }),
        )
        dispatch(feedingConfigurationChanged())
        dispatch(hideRightBar())
      }

      if (type === 'clone') {
        feedingConfiguration.lp.forEach((el: any) => {
          if (el.bunchProfiles.includes(prevPresetName)) {
            dispatch(changeFeedingLp({ ...el, bunchProfiles: [...el.bunchProfiles, inputState.Name], flag: true }))
          }
        })

        feedingConfiguration.feedAgregations.forEach((el: any) => {
          if (el.Profile === prevPresetName) {
            dispatch(
              changeFeedAggregation({
                ...el,
                Profile: inputState.Name,
                Enabled: inputState.Enabled,
                bunchProfiles: [...el.bunchProfiles, inputState.Name],
              }),
            )
          }
        })

        feedingConfiguration.feedPlatforms.forEach((el: any) => {
          if (el.Profile === prevPresetName) {
            dispatch(
              addFeedPlatform({
                ...el,
                AggregatedFeeder: el.AggregatedFeeder,
                Profile: inputState.Name,
                Enabled: inputState.Enabled,
                flag: true,
                Name: `${el.Name}`,
                Id: -1 * (el.Id - 1000),
              }),
            )
          }
        })

        dispatch({
          type: Action.GotFeedingPreset,
          data: new FeedExecPresetEntity({
            ...item,
            ...inputState,
            Id: -1 * (feedingConfiguration.Profile.length === 0 ? feedingConfiguration.Profile.length + 1 : feedingConfiguration.Profile.length + 1),
            ...schedule,
            ScheduleType: schedule.ScheduleType.value,
          }),
        })
        dispatch(feedingConfigurationChanged())
        dispatch(hideRightBar())
      }
    }
  }

  const controls = buildControlsExtTwoPerLine(
    [textInput('Name').errorMessage(alreadyExistPresetBar(type, [...tablePreset], inputState, errors)), textInput('Description'), checkboxInput('Enabled')],
    inputState,
    setInputState,
    'preset',
    touched,
    setTouched,
    errors,
  )

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`preset.${type}`} />,
          item: controls,
        }}
        isHidden={false}
      />
      <div style={{ zIndex: 100 }}>
        <AppSchedule state={schedule} touched={scheduleTouched} setTouched={setScheduleTouched} errors={scheduleErrors} isValid={isScheduleValid} setState={setSchedule} ref={scheduleRef} />
      </div>
      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
}

export default FeedExecPresetBar
