import { Action } from '../actions/actions'
import { UserEntity } from '../../entity/system-settings'
import { UserRoles } from '../actions/authentication'

const managerRoles = [UserRoles.Summary, UserRoles.Exposure, UserRoles.Positions, UserRoles.TradingHistory]
const dealerRoles = [
  ...managerRoles,
  UserRoles.Execution,
  UserRoles.Feeding,
  UserRoles.NewSwitch,
  UserRoles.ActiveSwitches,
  UserRoles.SwitchHistory,
  UserRoles.AutoSwitch,
  UserRoles.ActiveRules,
  UserRoles.VolumeTransfer,
  UserRoles.ConsolidationRules,
  UserRoles.ConsolidationHistory,
]

function usersReducer(
  state = {
    data: [],
    totalCount: 0,
  },
  action: any,
) {
  switch (action.type) {
    case Action.GotUsers:
      return {
        ...state,
        data: action.data.Data.map((item: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          let flagRoleManager = false
          for(let i = 0; i < item.Roles.length; i++) {
            let findRole = managerRoles.find((el:string) => el === item.Roles[i])
            if(findRole) {
              flagRoleManager = true
            } else {
              flagRoleManager = false
            }
          }

          let flagRoleDealer = false
          for(let i = 0; i < item.Roles.length; i++) {
            let findRole = dealerRoles.find((el:string) => el === item.Roles[i])
            if(findRole) {
              flagRoleDealer = true
            } else {
              flagRoleDealer = false
            }
          }

          if (flagRoleDealer && item.Roles.length === 4) {
            item.UserType = 'manager';
          } else if (flagRoleDealer && item.Roles.length === 14) {
            item.UserType = 'dealer';
          } else if (item.Roles.length >= 33) {
            item.UserType = 'admin';
          } else {
            item.UserType = 'custom';
          }
          return new UserEntity(item);
        }),
        totalCount: action.data.TotalCount,
      }
    default:
      return state
  }
}

export default usersReducer
