import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const IconOpen = React.memo(() => {
  return (
    <div className='color-picker_icon-open'>
      <div style={{width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <FontAwesomeIcon icon={faChevronDown}/>
      </div>
    </div>
  )
});

export default IconOpen;