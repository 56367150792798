import React from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import CreatableSelect from 'react-select/creatable'

interface ISearchableSelectInput {
  state: any
  name: string
  options: any
  touched?: any
  errors?: any
  errorText?: string
  label?: string
  isDisabled?: boolean
  className?: string
  styles?: any
  placeholder?: string

  setState(newState: any): any
  onClick?(event: any): any
  setTouched?(touched: any): any
}

const SearchableSelectCreateInput: React.FC<ISearchableSelectInput> = ({ state, setState, name, options, label, className, styles, isDisabled, onClick, placeholder, errors, errorText, touched, setTouched }) => {
  const handleChange = (event: any) => {
    setState({
      ...state,
      [name]: event,
    })
  }

  const handleBlur = () => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
  }

  const isInvalid = touched && touched[name] && errors && errors[name]

  return (
    <Form.Group className={className} onClick={onClick}>
      {label && (
        <Form.Label>
          <FormattedMessage id={label} tagName="span" />:
        </Form.Label>
      )}
      <CreatableSelect
        formatCreateLabel={str => `Create: ${str}`}
        isClearable
        styles={styles}
        isSearchable={true}
        options={options}
        name="symb"
        value={state[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        isDisabled={isDisabled}
        placeholder={placeholder}
      />
      <div className={`invalid-feedback ${isInvalid && 'd-block'}`}>
        <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
      </div>
    </Form.Group>
  )
}

export default SearchableSelectCreateInput
