import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { RootState } from '../redux/reducers/rootReducer'

interface IProgressBar {
  className?: string
}

const ProgressBar: React.FC<IProgressBar> = ({ className }) => {
  const inProgress: number = useSelector((state: RootState) => state.inProgress)

  return inProgress ? (
    <div className={classNames('progress-track', className)}>
      <div className="progress-indicator" />
      <div className="progress-indicator2" />
    </div>
  ) : null
}

export default memo(ProgressBar)
