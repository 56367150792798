import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { buildControlsExtTwoPerLine, sselectInput, textInput } from '../../utils/controls'
import { useFormValidation } from '../../hooks/useFormValidation'
import * as yup from 'yup'
import useData from '../../hooks/useData'
import useGateway from '../../hooks/useGateway'
import { IMultiSelectItem, MultiselectItem } from '../../entity/multiselect'
import { AddVolumeTransformerCumulative, ModifyVolumeTransformerCumulative } from '../../redux/reducers/sysLpsReducer'
import { RootState } from '../../redux/reducers/rootReducer'

type props = {
  data: any
}

function asMultiselectOption(option: string): IMultiSelectItem {
  return new MultiselectItem(option)
}

const VolumeTrancformerCumulative: FC<props> = ({ data: { item, type } }) => {
  const dispatch = useDispatch()
  const gateway = useGateway()
  const { volumeTransformerCumulative, VolumeTransformerSymbolMap } = useSelector((state: RootState) => state.sysLps)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    { ...item, Symbol: asMultiselectOption(item.Symbol), Threshold: item.Threshold ? item.Threshold : '' },
    { Threshold: yup.number().positive().required() },
  )
  const findSymbols = volumeTransformerCumulative.map((item: any) => item.Symbol)
  const symbols = useData('api/hedgedBbook/symbols', [], gateway)
  const findOutputSymbols = VolumeTransformerSymbolMap.map((item: any) => item.SourceSymbol)
  const symbolOptions = useMemo(
    () =>
      symbols
        .filter((item: any) => !findOutputSymbols.includes(item))
        .filter((item: any) => !findSymbols.includes(item))
        .map(asMultiselectOption),
    [symbols], // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    if (symbolOptions.length && type === 'add') {
      setInputState((prevState: any) => ({
        ...prevState,
        Symbol: symbolOptions[0],
      }))
    }
  }, [symbolOptions, type]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    if (!isValid()) {
      return
    }
    if (type === 'add') {
      dispatch(AddVolumeTransformerCumulative({ ...inputState, LastUpdate: new Date().getTime() }))
    }
    if (type === 'modify') {
      dispatch(ModifyVolumeTransformerCumulative(inputState))
    }
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`volume.comul.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [sselectInput('Symbol', symbolOptions).disabled(type === 'modify'), textInput('Threshold')],
          inputState,
          setInputState,
          'volume',
          touched,
          setTouched,
          errors,
        )}

        <Button className="t4b-bg-dark-button mt-3" onClick={handleClick}>
          <FormattedMessage id="save" />
        </Button>
      </Card.Body>
    </Card>
  )
}

export default VolumeTrancformerCumulative
