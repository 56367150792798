import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { hideModal, showModal } from '../../redux/actions/modal-actions'
import { FormattedMessage } from 'react-intl'

import { Button, Modal } from 'react-bootstrap'
import SelectInput from '../inputs/SelectInput'
import { feedingConfigurationChanged } from '../../redux/actions/feeding-actions'
import { executionConfigurationChanged } from '../../redux/actions/execution/execution-actions'
import { ModalType } from './modals'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'
import NewSearchableSelectInput from '../inputs/NewSearchableSelectInput'

const ModalPreset: FC = () => {
  const dispatch = useDispatch()
  const { modalData } = useSelector((state: any) => state.modals)
  const executionConfiguration = useSelector((state: any) => state.executionConfiguration)
  const [state, setState] = useState({ Preset: { value: modalData.option[0], label: modalData.option[0] } })

  const handleYes = () => {
    if (modalData.bodyText() === 'move') {
      modalData.selectMove(state.Preset.value)
      dispatch(feedingConfigurationChanged())
    } else if (modalData.bodyText() === 'copy') {
      modalData.selectCopy(state.Preset.value)
      dispatch(feedingConfigurationChanged())
    } else if (modalData.bodyText() === 'ExecMovePreset') {
      const findProfileProcessing = modalData.isMoveCopy.processingRules.map((item: any) => item.Profile)
      const t = executionConfiguration.routingRules.filter((item: any) => findProfileProcessing.includes(item.Profile)).map((item: any) => item.ProcessorId)
      const findIdProcessing = modalData.isMoveCopy.processingRules.map((item: any) => item.Id)
      const flag = findIdProcessing.filter((item: any) => t.includes(item))

      if (modalData.isMoveCopy.processingRules.length && !modalData.isMoveCopy.routingRules.length && flag.length) {
        dispatch(
          showModal(ModalType.Info, {
            headerText: 'move.exec.processing.header',
            bodyText: () => <FormattedMessage id="move.exec.one.item.body" values={{ Name: modalData.isMoveCopy.processingRules[0].Name }} />,
            onYes: () => {},
          }),
        )
        dispatch(executionConfigurationChanged())
        return
      }

      if (modalData.isMoveCopy.processingRules.length && !modalData.isMoveCopy.routingRules.length) {
        modalData.selectMoveExec(state.Preset.value)
        dispatch(executionConfigurationChanged())
        dispatch(hideModal())
        return
      }

      const searchProcessingRulesProfile = executionConfiguration.processingRules.filter((item: any) => item.Profile === state.Preset.value)
      if (!modalData.isMoveCopy.processingRules.length && modalData.isMoveCopy.routingRules.length && !searchProcessingRulesProfile.length) {
        dispatch(
          showModal(ModalType.Info, {
            headerText: 'move.exec.header',
            bodyText: () => <FormattedMessage id="move.exec.body" />,
            onYes: () => {},
          }),
        )
        dispatch(executionConfigurationChanged())
        return
      }

      modalData.selectMoveExec(state.Preset.value)
      dispatch(executionConfigurationChanged())
      dispatch(hideModal())
    } else if (modalData.bodyText() === 'ExecCopyPreset') {
      if (modalData.isMoveCopy.processingRules.length && !modalData.isMoveCopy.routingRules.length) {
        modalData.execSelectCopy(state.Preset.value)
        dispatch(executionConfigurationChanged())
        dispatch(hideModal())
        return
      }

      const searchProcessingRulesProfile = executionConfiguration.processingRules.filter((item: any) => item.Profile === state.Preset.value)
      if (!modalData.isMoveCopy.processingRules.length && modalData.isMoveCopy.routingRules.length && !searchProcessingRulesProfile.length) {
        dispatch(
          showModal(ModalType.Info, {
            headerText: 'move.exec.header',
            bodyText: () => <FormattedMessage id="move.exec.body.copy" />,
            onYes: () => {},
          }),
        )
        dispatch(executionConfigurationChanged())
        return
      }

      modalData.execSelectCopy(state.Preset.value)
      dispatch(executionConfigurationChanged())
      dispatch(hideModal())
    }
    dispatch(hideModal())
  }

  const handleNo = () => {
    dispatch(hideModal())
  }

  const headerText = typeof modalData.headerText === 'function' ? modalData.headerText() : <FormattedMessage id={modalData.headerText} />

  return (
    <Modal show={true} onHide={handleNo} centered>
      <Modal.Header closeButton={true} className="color-dark font-500">
        {headerText}
      </Modal.Header>
      <Modal.Body>
        <span>{modalData.text}</span>
        <div style={{ width: 450 }} className="mt-2">
          <NewSearchableSelectInput state={state} setState={setState} name="Preset" options={buildMultiselectOptionsFromArray(modalData.option)} className="m-0" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleYes} disabled={state.Preset.value === 'All'}>
          <FormattedMessage id="Save" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalPreset
