import {
  AggregationPoolEntity,
  ExecutionLpAEntity,
  ExecutionLpBEntity,
  ExecutionLpBRule,
  isBbookLp,
  ProcessingRuleEntity,
  ProcessingRuleItem,
  RoutingRuleEntity,
} from '../../entity/configuration'
import { Action } from '../actions/actions'
import { FeedExecPresetEntity } from '../../entity/Preset-entitu'

interface IState {
  noChanges: boolean
  routingRules: RoutingRuleEntity[]
  processingRules: ProcessingRuleEntity[]
  aggregationPools: AggregationPoolEntity[]
  lps: any[]
  highlightAction: string
  from: string
  sourceName: string
  processingRuleItems: {
    data: ProcessingRuleItem[]
    count: number
  }
  executionLpBRules: {
    data: any[]
    count: number
  }
  countProcessingRules: number
  ExecPool: any
  ExecPoolVolume: any
  Profile: any
  Countries: string[]
}

const INITIAL_STATE: IState = {
  noChanges: true,
  routingRules: [],
  processingRules: [],
  aggregationPools: [],
  lps: [],
  highlightAction: '',
  from: '',
  sourceName: '',
  processingRuleItems: {
    data: [],
    count: 0,
  },
  executionLpBRules: {
    data: [],
    count: 0,
  },
  countProcessingRules: 0,
  ExecPool: [],
  Profile: [],
  ExecPoolVolume: [],
  Countries: [],
}

function executionConfigurationReducer(state = INITIAL_STATE, action: any): IState {
  switch (action.type) {
    case Action.ExecutionConfigurationInitial:
      return {
        ...state,
        noChanges: true,
        highlightAction: '',
        from: '',
        sourceName: '',
      }

    case Action.ExecutionConfigurationInitialFind:
      return {
        ...state,
        routingRules: action?.data?.routingRules.map((item: any) => {
          item.highlighted = false
          item.activeSwitches = false
          return item
        }),
        processingRules: state.processingRules.map((item: any) => {
          item.highlighted = false
          item.activeSwitches = false
          return item
        }),
        aggregationPools: state.aggregationPools.map((item: any) => {
          item.highlighted = false
          item.activeSwitches = false
          return item
        }),
        lps: state.lps.map((item: any) => {
          item.highlighted = false
          item.activeSwitches = false
          return item
        }),
        highlightAction: '',
        from: '',
        sourceName: '',
      }

    case Action.ExecutionConfigurationChanged:
      return {
        ...state,
        noChanges: false,
      }

    case Action.GotProfile:
      return {
        ...state,
        Profile: action.data.map((item: any) => new FeedExecPresetEntity(item)),
      }

    case Action.GotRoutingRules:
      return {
        ...state,
        routingRules: action.data.map(
          (item: any, index: number) =>
            new RoutingRuleEntity({
              ...item,
            }),
        ),
      }
    case Action.ChangeRoutingRule:
      const findId = state.processingRules.filter((item: any) => item.Name === action.data.ProcessorName)
      return {
        ...state,
        routingRules: state.routingRules.map((item: RoutingRuleEntity) => {
          if (item.Id === action.data.Id) {
            return new RoutingRuleEntity({ ...action.data, ProcessorId: action.data.ProcessorId ? action.data.ProcessorId : findId[0]?.Id })
          }
          return item
        }),
      }
    case Action.ChangeRoutingRuleMove:
      return {
        ...state,
        routingRules: state.routingRules.map((item: RoutingRuleEntity) => {
          if (item.Id === action.data.Id) {
            return new RoutingRuleEntity({ ...action.data })
          }
          return item
        }),
      }
    case Action.DeleteRoutingRule:
      return {
        ...state,
        routingRules: state.routingRules.filter((item: RoutingRuleEntity) => item.Id !== action.data.Id),
      }
    case Action.AddRoutingRule:
      const a = [
        ...state.routingRules,
        new RoutingRuleEntity({
          ...action.data,
          Id: -1 * Math.floor(Math.random() * (1000 + 1 - 1)),
          ProcessorId: action.data.ProcessorId && !action.data.flag ? -1 * action.data.ProcessorId : action.data.ProcessorId,
        }),
      ]

      const profile = state.Profile.map((item: any) => item.Name)
      const newPriorProfile = a.sort((a, b) => profile.indexOf(a.Profile) - profile.indexOf(b.Profile))

      return {
        ...state,
        routingRules: newPriorProfile,
      }
    case Action.AddRoutingRuleCopy:
      return {
        ...state,
        routingRules: [
          ...state.routingRules,
          new RoutingRuleEntity({
            ...action.data,
            Id: -1 * Math.floor(Math.random() * (1000 + 1 - 1)),
          }),
        ],
      }
    case Action.ToogleRoutingRule:
      return {
        ...state,
        routingRules: state.routingRules.map((item: RoutingRuleEntity) => {
          if (item.Name === action.data.Name) {
            return new RoutingRuleEntity({
              ...action.data,
              Enabled: !action.data.Enabled,
            })
          }
          return item
        }),
      }
    case Action.RoutingRulesChangeOrder:
      const findProfile = state.routingRules
        .map((item: any) => {
          if (Number(action.data.draggableId) === item.Id) {
            return item.Profile
          }
        })
        .join('')
      const findRouting: any = state.routingRules.filter((item: any) => item.Profile === findProfile)

      findRouting.splice(action.data.source.index, 1)
      findRouting.splice(action.data.destination.index, 0, state.routingRules.filter((item: any) => item.Profile === findProfile)[action.data.source.index])

      const obj: any = {}
      for (let i = 0; i < state.routingRules.length; i++) {
        if (obj[state.routingRules[i].Profile] === undefined) {
          obj[state.routingRules[i].Profile] = [state.routingRules[i]]
        } else {
          obj[state.routingRules[i].Profile] = [...obj[state.routingRules[i].Profile], state.routingRules[i]]
        }
      }

      obj[findRouting[0].Profile] = findRouting

      const t = Object.values(obj).flat(Infinity)

      const order = state.Profile.map((item: any) => item.Name)
      const newPrior: any[] = t.sort((a: any, b: any) => order.indexOf(a.Profile) - order.indexOf(b.Profile))

      return {
        ...state,
        routingRules: newPrior,
      }

    case Action.GotProcessingRules:
      return {
        ...state,
        countProcessingRules: action.data.length * 1000,
        processingRules: action.data.map((item: any) => new ProcessingRuleEntity({ ...item })),
      }
    case Action.ChangeProcessingRule:
      return {
        ...state,
        processingRules: state.processingRules.map((item: ProcessingRuleEntity) => {
          if (item.Id === action.data.Id) {
            return new ProcessingRuleEntity(action.data)
          } else {
            return item
          }
        }),
        routingRules: state.routingRules.map((item: RoutingRuleEntity) => {
          if (item.ProcessorId === action.data.Id) {
            return new RoutingRuleEntity({ ...item, ProcessorName: action.data.Name }) // changed: true
          } else {
            return item
          }
        }),
      }

    case Action.ChangeProcessingRuleSelect:
      return {
        ...state,
        processingRules: state.processingRules.map((item: ProcessingRuleEntity) => {
          if ((item.Id === action.data.Id && item.Profile === action.data.Profile) || item.Id === action.data.Id) {
            return new ProcessingRuleEntity(action.data)
          }
          return item
        }),
      }

    case Action.EnableProcessingRule:
      return {
        ...state,
        processingRules: state.processingRules.map((item: ProcessingRuleEntity) => {
          if (item.Id === action.data.Id && item.Profile === action.data.Profile) {
            return new ProcessingRuleEntity({
              ...item,
              changed: true,
              Rules: item.Rules.map((rule: ProcessingRuleItem) => new ProcessingRuleItem({ ...rule, Enabled: true })),
            })
          }
          return item
        }),
      }
    case Action.DisableProcessingRule:
      return {
        ...state,
        processingRules: state.processingRules.map((item: ProcessingRuleEntity) => {
          if (item.Id === action.data.Id && item.Profile === action.data.Profile) {
            return new ProcessingRuleEntity({
              ...item,
              changed: true,
              Rules: item.Rules.map((rule: ProcessingRuleItem) => new ProcessingRuleItem({ ...rule, Enabled: false })),
            })
          }
          return item
        }),
      }
    case Action.DeleteProcessingRule:
      return {
        ...state,
        processingRules: state.processingRules.filter((item: ProcessingRuleEntity) => item.Id !== action.data.Id),
      }
    case Action.AddProcessingRule:
      const b = [...state.processingRules, new ProcessingRuleEntity({ ...action.data, Id: action.data.Id < 0 ? action.data.Id : -1 * action.data.Id })]

      const profile1 = state.Profile.map((item: any) => item.Name)
      const newPriorProfile1 = b.sort((a, b) => profile1.indexOf(a.Profile) - profile1.indexOf(b.Profile))

      return {
        ...state,
        processingRules: newPriorProfile1,
      }
    case Action.GotProcessingRuleItems: {
      const processingRule = state.processingRules.find((item: ProcessingRuleEntity) => item.Name === action.data.Processor && item.Profile === action.data.profile)
      if (processingRule) {
        return {
          ...state,
          processingRuleItems: {
            data: processingRule.Rules.slice((action.data.Page - 1) * action.data.Count, action.data.Page * action.data.Count),
            count: processingRule.Rules.length,
          },
        }
      }
      return state
    }
    case Action.AddProcessingRuleItem:
      return {
        ...state,
        processingRules: state.processingRules.map((item: ProcessingRuleEntity) => {
          if (item.Name === action.data.params.Processor && item.Profile === action.data.Profile) {
            const id = -Math.floor(Math.random() * (2000000000 + 1 - 1))
            const newRule = {
              ...item,
              changed: true,
              Rules: [new ProcessingRuleItem({ ...action.data.rule, Id: id }), ...item.Rules],
            }
            return new ProcessingRuleEntity(newRule)
          }
          return item
        }),
      }
    case Action.AddProcessingRuleItems:
      return {
        ...state,
        processingRules: state.processingRules.map((item: ProcessingRuleEntity) => {
          if ((item.Name === action.data.params.Processor && item.Profile === action.data.Profile) || item.Name === action.data.params.Processor) {
            const id = -Math.floor(Math.random() * (2000000000 + 1 - 1))

            action.data.rules.forEach((rule: ProcessingRuleItem, index: number) => {
              rule.Id = id - index
            })
            const newRule = { ...item, changed: true, Rules: [...action.data.rules, ...item.Rules] }
            return new ProcessingRuleEntity(newRule)
          }
          return item
        }),
      }
    case Action.ChangeProcessingRuleItem:
      return {
        ...state,
        processingRules: state.processingRules.map((item: ProcessingRuleEntity) => {
          if (item.Name === action.data.params.Processor && item.Profile === action.data.Profile) {
            return new ProcessingRuleEntity({
              ...item,
              changed: true,
              Rules: item.Rules.map((rule: ProcessingRuleItem) => {
                if (rule.Id === action.data.rule.Id) {
                  return new ProcessingRuleItem(action.data.rule)
                }
                return rule
              }),
            })
          }
          return item
        }),
      }

    case Action.ChangeProcessing:
      return {
        ...state,
        processingRules: state.processingRules.map((item: ProcessingRuleEntity) => {
          if (item.Name === action.data.Name && item.Profile === action.data.Profile) {
            const flag = item.Rules.filter((item: any) => !action.data.newExecutionPriceSource.includes(item.ExecutionPriceSource))

            return new ProcessingRuleEntity({
              ...item,
              changed: Boolean(flag.length),
              Rules: action.data.Rules.length ? action.data.Rules : item.Rules,
            })
          }
          return item
        }),
      }

    case Action.DeleteProcessingRuleItem:
      return {
        ...state,
        processingRules: state.processingRules.map((item: ProcessingRuleEntity) => {
          if (item.Name === action.data.params.Processor && item.Profile === action.data.params.profile) {
            return new ProcessingRuleEntity({
              ...item,
              changed: true,
              Rules: item.Rules.filter((rule: ProcessingRuleItem) => rule.Id !== action.data.rule.Id),
            })
          }
          return item
        }),
      }
    case Action.DeleteProcessingRuleItems:
      return {
        ...state,
        processingRules: state.processingRules.map((item: ProcessingRuleEntity) => {
          if (item.Name === action.data.params.Processor && item.Profile === action.data.params.profile) {
            return new ProcessingRuleEntity({
              ...item,
              changed: true,
              Rules: item.Rules.slice(-1),
            })
          }
          return item
        }),
      }
    case Action.ChangeProcessingRuleItemPriority: {
      const { result, pagination, params } = action.data
      result.source.index += (pagination.Page - 1) * pagination.Count
      result.destination.index += (pagination.Page - 1) * pagination.Count
      return {
        ...state,
        processingRules: state.processingRules.map((item: ProcessingRuleEntity) => {
          if (item.Name === params.Processor && item.Profile === params.profile) {
            const newRulesArray = Array.from(item.Rules)
            newRulesArray.splice(result.source.index, 1)
            newRulesArray.splice(result.destination.index, 0, item.Rules[result.source.index])
            return new ProcessingRuleEntity({
              ...item,
              changed: true,
              Rules: newRulesArray,
            })
          }
          return item
        }),
      }
    }
    case Action.GotAggregationPools:
      return {
        ...state,
        aggregationPools: action.data.map((item: any) => new AggregationPoolEntity(item)),
      }
    case Action.ChangeAggregationPool:
      return {
        ...state,
        aggregationPools: state.aggregationPools.map((item: AggregationPoolEntity) => {
          if (item.Name === action.data.Name) {
            return new AggregationPoolEntity(action.data)
          }
          return item
        }),
      }
    case Action.DeleteExecPreset:
      return {
        ...INITIAL_STATE,
        aggregationPools: state.aggregationPools,
        lps: state.lps,
      }

    case Action.DeleteExecPresetOne:
      return {
        ...state,
        lps: state.lps.map((item: any) => {
          if (item.Profile === action.data.Name) {
            if (item.bunchProfiles.length === 1) {
              item.bunchProfiles = item.bunchProfiles.filter((item: any) => item !== action.data.Name)
              item.Profile = []
              return item
            } else {
              item.bunchProfiles = item.bunchProfiles.filter((item: any) => item !== action.data.Name)
              return item
            }
          } else {
            item.bunchProfiles = item.bunchProfiles.filter((item: any) => item !== action.data.Name)
            return item
          }
        }),
        processingRules: state.processingRules.filter((item: any) => item.Profile !== action.data.Name),
        aggregationPools: state.aggregationPools.map((item: any) => {
          if (item.Profile === action.data.Name) {
            if (item.bunchProfiles.length === 1) {
              item.bunchProfiles = item.bunchProfiles.filter((item: any) => item !== action.data.Name)
              item.Profile = []
              return item
            } else {
              item.bunchProfiles = item.bunchProfiles.filter((item: any) => item !== action.data.Name)
              return item
            }
          } else {
            item.bunchProfiles = item.bunchProfiles.filter((item: any) => item !== action.data.Name)
            return item
          }
        }),
        routingRules: state.routingRules.filter((item: any) => item.Profile !== action.data.Name),
        Profile: state.Profile.filter((item: any) => item.Name !== action.data.Name),
      }

    case Action.DeleteAggergationPool:
      return {
        ...state,
        aggregationPools: state.aggregationPools.filter((item: AggregationPoolEntity) => item.Name !== action.data.Name),
      }
    case Action.AddAggregationPool:
      return {
        ...state,
        aggregationPools: [...state.aggregationPools, new AggregationPoolEntity(action.data)],
      }

    case Action.GotExecPool:
      return {
        ...state,
        ExecPool: action.data,
      }

    case Action.GotExecPoolVolume:
      return {
        ...state,
        ExecPoolVolume: action.data,
      }

    case Action.GotCountries:
      return {
        ...state,
        Countries: action.data,
      }

    case Action.GotExecPreset:
      return {
        ...state,
        Profile: [...state.Profile, action.data],
      }

    case Action.GotExecutionLP:
      return {
        ...state,
        lps: action.data?.map((item: any) => {
          if (item.Type === 'Bbook') {
            return new ExecutionLpBEntity(item)
          }
          if (item.Type === 'BbookHedged') {
            return new ExecutionLpBEntity(item)
          }
          if (item.Type === 'VolumeConverter') {
            return new ExecutionLpBEntity(item)
          }
          if (item.Type === 'HedgingSyntheticSymbol') {
            return new ExecutionLpBEntity(item)
          }
          if (item.Type === 'HedgingSyntheticIndex') {
            return new ExecutionLpBEntity(item)
          }
          return new ExecutionLpAEntity(item)
        }),
      }
    case Action.ChangeExecutionLp:
      return {
        ...state,
        lps: state.lps.map((item: any) => {
          if (item.Name === action.data.Name) {
            return action.data
          }
          return item
        }),
      }

    case Action.DisableExecutionLp:
      return {
        ...state,
        lps: state.lps.map((item: ExecutionLpAEntity | ExecutionLpBEntity) => {
          if (item.Name === action.data.Name) {
            if (isBbookLp(item)) {
              return new ExecutionLpBEntity({
                ...item,
                Rules: (item as ExecutionLpBEntity).Rules.map(
                  (rule: ExecutionLpBRule) =>
                    new ExecutionLpBRule({
                      ...rule,
                      Enabled: false,
                    }),
                ),
              })
            } else {
              return new ExecutionLpAEntity({ ...item, Enabled: false })
            }
          }
          return item
        }),
      }
    case Action.EnableExecutionLp:
      return {
        ...state,
        lps: state.lps.map((item: ExecutionLpAEntity | ExecutionLpBEntity) => {
          if (item.Name === action.data.Name) {
            if (isBbookLp(item)) {
              return new ExecutionLpBEntity({
                ...item,
                Rules: (item as ExecutionLpBEntity).Rules.map(
                  (rule: ExecutionLpBRule) =>
                    new ExecutionLpBRule({
                      ...rule,
                      Enabled: true,
                    }),
                ),
              })
            } else {
              return new ExecutionLpAEntity({ ...item, Enabled: true })
            }
          }
          return item
        }),
      }
    case Action.GotExecutionLpBRules: {
      const lp = state.lps.find((item: ExecutionLpAEntity | ExecutionLpBEntity) => item.Name === action.data.Lp)
      if ((lp as ExecutionLpBEntity) && isBbookLp(lp)) {
        return {
          ...state,
          executionLpBRules: {
            data: lp.Rules.slice((action.data.Page - 1) * action.data.Count, action.data.Page * action.data.Count),
            count: lp.Rules.length,
          },
        }
      }
      return state
    }
    case Action.AddExecutionLpBRule:
      return {
        ...state,
        lps: state.lps.map((item: ExecutionLpAEntity | ExecutionLpBEntity) => {
          if (item.Name === action.data.params.Lp) {
            const id = -1 + Math.min(0, ...(item as ExecutionLpBEntity).Rules.map((rule: ExecutionLpBRule) => rule.Id))
            return new ExecutionLpBEntity({
              ...item,
              changed: true,
              Rules: [new ExecutionLpBRule({ ...action.data.rule, Id: id }), ...(item as ExecutionLpBEntity).Rules],
            })
          }
          return item
        }),
      }
    case Action.ChangeExecutionLpBRule:
      return {
        ...state,
        lps: state.lps.map((item: ExecutionLpAEntity | ExecutionLpBEntity) => {
          if (item.Name === action.data.params.Lp) {
            return new ExecutionLpBEntity({
              ...item,
              changed: true,
              Rules: (item as ExecutionLpBEntity).Rules.map((rule: ExecutionLpBRule) => {
                if (rule.Id === action.data.rule.Id) {
                  return new ExecutionLpBRule(action.data.rule)
                }
                return rule
              }),
            })
          }
          return item
        }),
      }
    case Action.DeleteExecutionLpBRule:
      return {
        ...state,
        lps: state.lps.map((item: ExecutionLpAEntity | ExecutionLpBEntity) => {
          if (item.Name === action.data.params.Lp) {
            return new ExecutionLpBEntity({
              ...item,
              changed: true,
              Rules: (item as ExecutionLpBEntity).Rules.filter((rule: ExecutionLpBRule) => rule.Id !== action.data.rule.Id),
            })
          }
          return item
        }),
      }
    case Action.DeleteExecutionLpBRules:
      return {
        ...state,
        lps: state.lps.map((item: ExecutionLpBEntity) => {
          if (item.Name === action.data.params.Lp) {
            return new ExecutionLpBEntity({
              ...item,
              changed: true,
              Rules: item.Rules.filter((rule: ExecutionLpBRule) => rule.Id === 0),
            })
          }
          return item
        }),
      }
    case Action.ChangeExecutionLpBRulePriority: {
      const { result, pagination, params } = action.data
      result.source.index += (pagination.Page - 1) * pagination.Count
      result.destination.index += (pagination.Page - 1) * pagination.Count
      return {
        ...state,
        lps: state.lps.map((item: ExecutionLpAEntity | ExecutionLpBEntity) => {
          if (item.Name === params.Lp) {
            const newRulesArray = Array.from((item as ExecutionLpBEntity).Rules)
            newRulesArray.splice(result.source.index, 1)
            newRulesArray.splice(result.destination.index, 0, (item as ExecutionLpBEntity).Rules[result.source.index])
            return new ExecutionLpBEntity({
              ...item,
              changed: true,
              Rules: newRulesArray,
            })
          }
          return item
        }),
      }
    }

    case Action.ChangePriorityExecPreset: {
      const table: any = {}
      const tablePreset = state.Profile.filter((item: any) => !table[item?.Name] && (table[item?.Name] = 1))
      const { result } = action.data
      const newSymbolArray = tablePreset
      newSymbolArray.splice(result.source.index, 1)
      newSymbolArray.splice(result.destination.index, 0, state.Profile[result.source.index])
      return {
        ...state,
        Profile: newSymbolArray,
      }
    }
    case Action.ChangeExecPreset:
      return {
        ...state,
        Profile: state.Profile.map((item: any) => {
          if (item.PresetId === action.data.PresetId || item.Id === action.data.Id) {
            return new FeedExecPresetEntity(action.data)
          }
          return item
        }),
      }

    case Action.ExecutionHighlightRelevant:
      if (action.data.action === 'highlight') {
        if (state.highlightAction === '') {
          return highlight(state, action)
        }

        if (state.highlightAction === 'highlight' && isSameSource(state, action)) {
          return unhighlight(state)
        }
      }

      if (action.data.action === 'hold') {
        if (state.highlightAction === 'highlight' && isSameSource(state, action)) {
          return highlight(state, action)
        }

        if (state.highlightAction === 'hold') {
          if (isSameSource(state, action)) {
            return {
              ...state,
              highlightAction: 'highlight',
            }
          } else {
            return highlight(state, action)
          }
        }

        if (state.highlightAction === 'hide') {
          return highlight(state, action)
        }
      }

      if (action.data.action === 'hide') {
        if (state.highlightAction === '') {
          return highlight(state, action)
        }
        if (state.highlightAction === 'hide') {
          if (isSameSource(state, action) && action.data.from !== 'routingRuleFind') {
            return unhighlight(state)
          } else {
            return highlight(state, action)
          }
        }

        if (state.highlightAction === 'hold') {
          return highlight(state, action)
        }
      }

      return state
    default:
      return state
  }
}

function highlight(state: IState, action: any): IState {
  if (action.data.from === 'routingRule') {
    const routingRulesLinks: { [index: string]: any } = {}
    const processingRulesLinks: { [index: string]: any } = {}
    const lpPoolsLinks: { [index: string]: any } = {}

    return {
      ...state,
      routingRules: fromRoutingRule(state.routingRules, action.data.item, routingRulesLinks),
      processingRules: fromProcessingRule(state.processingRules, routingRulesLinks, processingRulesLinks),
      aggregationPools: fromAggregationPool(state.aggregationPools, processingRulesLinks, lpPoolsLinks),
      lps: fromLp(state.lps, lpPoolsLinks),
      highlightAction: action.data.action,
      from: action.data.from,
      sourceName: action.data.item.Id,
    }
  }

  if (action.data.from === 'routingRuleFind') {
    const routingRulesLinks: { [index: string]: any } = {}
    const processingRulesLinks: { [index: string]: any } = {}
    const lpPoolsLinks: { [index: string]: any } = {}

    return {
      ...state,
      routingRules: fromRoutingRuleFind(state.routingRules, action.data.item, routingRulesLinks),
      processingRules: fromProcessingRuleFind(state.processingRules, routingRulesLinks, processingRulesLinks),
      aggregationPools: fromAggregationPoolFind(state.aggregationPools, processingRulesLinks, lpPoolsLinks),
      lps: fromLpFind(state.lps, lpPoolsLinks),
      highlightAction: action.data.action,
      from: action.data.from,
      sourceName: action.data.item.Id,
    }
  }

  if (action.data.from === 'processingRule') {
    const routingRulesLinks: { [index: string]: any } = { [action.data.item.Id]: true }
    const processingRulesLinks: { [index: string]: any } = {}
    const lpPoolsLinks: { [index: string]: any } = {}

    return {
      ...state,
      routingRules: toProcessingRules(state.routingRules, routingRulesLinks),
      processingRules: fromProcessingRule(state.processingRules, routingRulesLinks, processingRulesLinks),
      aggregationPools: fromAggregationPool(state.aggregationPools, processingRulesLinks, lpPoolsLinks),
      lps: fromLp(state.lps, lpPoolsLinks),
      highlightAction: action.data.action,
      from: action.data.from,
      sourceName: action.data.item.Name,
    }
  }

  if (action.data.from === 'activeSwitches') {
    const routingRulesLinks: { [index: string]: any } = { [action.data.item.routingId]: true }
    const processingRulesLinks: { [index: string]: any } = {}
    const lpPoolsLinks: { [index: string]: any } = {}

    return {
      ...state,
      // routingRules: toProcessingRulesActiveSwitchesFind(state.routingRules, routingRulesLinks),
      processingRules: fromProcessingRuleActiveSwitchesFind(state.processingRules, routingRulesLinks, processingRulesLinks),
      aggregationPools: fromAggregationPoolActiveSwitchesFind(state.aggregationPools, processingRulesLinks, lpPoolsLinks),
      lps: fromLpActiveSwitchesFind(state.lps, lpPoolsLinks),
      highlightAction: action.data.action,
      from: action.data.from,
      sourceName: action.data.item.Name,
    }
  }
  if (action.data.from === 'aggregationPool') {
    const routingRulesLinks: { [index: string]: any } = {}
    const processingRulesLinks: { [index: string]: any } = { [action.data.item.Name]: true }
    const lpPoolsLinks: { [index: string]: any } = {}
    return {
      ...state,
      aggregationPools: fromAggregationPool(state.aggregationPools, processingRulesLinks, lpPoolsLinks),
      lps: fromLp(state.lps, lpPoolsLinks),
      processingRules: toAggregationPools(state.processingRules, processingRulesLinks, routingRulesLinks),
      routingRules: toProcessingRules(state.routingRules, routingRulesLinks),
      highlightAction: action.data.action,
      from: action.data.from,
      sourceName: action.data.item.Name,
    }
  }

  if (action.data.from === 'lp') {
    const routingRulesLinks: { [index: string]: any } = {}
    const processingRulesLinks: { [index: string]: any } = {}
    const lpPoolsLinks: { [index: string]: any } = { [action.data.item.Name]: true }
    return {
      ...state,
      lps: fromLp(state.lps, lpPoolsLinks),
      aggregationPools: toLp(state.aggregationPools, lpPoolsLinks, processingRulesLinks),
      processingRules: toAggregationPools(state.processingRules, processingRulesLinks, routingRulesLinks),
      routingRules: toProcessingRules(state.routingRules, routingRulesLinks),
      highlightAction: action.data.action,
      from: action.data.from,
      sourceName: action.data.item.Name,
    }
  }

  return state
}

function unhighlight(state: IState): IState {
  return {
    ...state,
    routingRules: state.routingRules.map(
      (item: RoutingRuleEntity) =>
        new RoutingRuleEntity({
          ...item,
          highlighted: false,
          activeSwitches: false,
        }),
    ),
    processingRules: state.processingRules.map(
      (item: ProcessingRuleEntity) =>
        new ProcessingRuleEntity({
          ...item,
          activeSwitches: false,
          highlighted: false,
        }),
    ),
    aggregationPools: state.aggregationPools.map(
      (item: AggregationPoolEntity) =>
        new AggregationPoolEntity({
          ...item,
          highlighted: false,
          activeSwitches: false,
        }),
    ),
    lps: state.lps.map((item: any) => ({ ...item, activeSwitches: false, highlighted: false })),
    highlightAction: '',
    from: '',
    sourceName: '',
  }
}

function isSameSource(state: IState, action: any): boolean {
  return state.from === action.data.from && (state.sourceName === action.data.item.Id || state.sourceName === action.data.item.Name)
}

function toProcessingRules(routingRules: RoutingRuleEntity[], roots: any): RoutingRuleEntity[] {
  return routingRules.map((item: RoutingRuleEntity) => {
    if (roots[item.ProcessorId]) {
      // ProcessorName
      // item.activeSwitches = true;
      return new RoutingRuleEntity({ ...item, highlighted: true })
    }
    item.highlighted = false

    return item
  })
}

// function toProcessingRulesActiveSwitchesFind(routingRules: RoutingRuleEntity[], roots: any): RoutingRuleEntity[] {
//   return routingRules.map((item: RoutingRuleEntity) => {
//     if (roots[item.ProcessorId]) {
//       //ProcessorName
//       item.activeSwitches = true
//       return new RoutingRuleEntity({ ...item, highlighted: true })
//     }
//
//     return item
//   })
// }

function toAggregationPools(processingRules: ProcessingRuleEntity[], roots: any, links: any): ProcessingRuleEntity[] {
  return processingRules.map((item: ProcessingRuleEntity) => {
    if (roots[item.LpPool]) {
      links[item.Id] = true
      return new ProcessingRuleEntity({ ...item, highlighted: true })
    }
    item.highlighted = false

    return item
  })
}

function toLp(aggregationPools: AggregationPoolEntity[], roots: any, links: any): AggregationPoolEntity[] {
  return aggregationPools.map((item: AggregationPoolEntity) => {
    for (const lp of item.Lps) {
      if (roots[lp.value]) {
        links[item.Name] = true
        return new AggregationPoolEntity({ ...item, highlighted: true })
      }
    }
    item.highlighted = false
    return item
  })
}

function fromRoutingRule(routingRules: RoutingRuleEntity[], root: RoutingRuleEntity, linkTo: any): RoutingRuleEntity[] {
  return routingRules.map((item: RoutingRuleEntity) => {
    if (item.Id === root.Id) {
      linkTo[root.ProcessorId] = true // ProcessorName

      return new RoutingRuleEntity({ ...item, highlighted: true })
    }
    item.highlighted = false
    return item
  })
}

function fromRoutingRuleFind(routingRules: RoutingRuleEntity[], root: RoutingRuleEntity, linkTo: any): RoutingRuleEntity[] {
  return routingRules.map((item: RoutingRuleEntity) => {
    if (item.Id === root.Id) {
      linkTo[root.ProcessorId] = true // ProcessorName

      return new RoutingRuleEntity({ ...item, highlighted: true })
    }
    // item.highlighted = false
    return item
  })
}

function fromProcessingRule(processingRules: ProcessingRuleEntity[], root: any, linkTo: any): ProcessingRuleEntity[] {
  return processingRules.map((item: ProcessingRuleEntity) => {
    if (root[item.Id]) {
      // || root[item.Name]
      linkTo[item.LpPool] = true

      return new ProcessingRuleEntity({ ...item, highlighted: true })
    }
    item.highlighted = false

    return item
  })
}

function fromProcessingRuleFind(processingRules: ProcessingRuleEntity[], root: any, linkTo: any): ProcessingRuleEntity[] {
  return processingRules.map((item: ProcessingRuleEntity) => {
    if (root[item.Id]) {
      linkTo[item.LpPool] = true

      return new ProcessingRuleEntity({ ...item, highlighted: true })
    }

    return item
  })
}

function fromProcessingRuleActiveSwitchesFind(processingRules: ProcessingRuleEntity[], root: any, linkTo: any): ProcessingRuleEntity[] {
  return processingRules.map((item: ProcessingRuleEntity) => {
    if (root[item.Id]) {
      linkTo[item.LpPool] = true
      item.activeSwitches = true
      return new ProcessingRuleEntity({ ...item, highlighted: true })
    }

    return item
  })
}

function fromAggregationPool(aggregationPools: AggregationPoolEntity[], root: any, linkTo: any): AggregationPoolEntity[] {
  return aggregationPools.map((item: AggregationPoolEntity) => {
    if (root[item.Name]) {
      for (const lp of item.Lps) {
        linkTo[lp.value] = true
      }
      return new AggregationPoolEntity({ ...item, highlighted: true })
    }
    item.highlighted = false

    return item
  })
}

function fromAggregationPoolFind(aggregationPools: AggregationPoolEntity[], root: any, linkTo: any): AggregationPoolEntity[] {
  return aggregationPools.map((item: AggregationPoolEntity) => {
    if (root[item.Name]) {
      for (const lp of item.Lps) {
        linkTo[lp.value] = true
      }
      return new AggregationPoolEntity({ ...item, highlighted: true })
    }

    return item
  })
}

function fromAggregationPoolActiveSwitchesFind(aggregationPools: AggregationPoolEntity[], root: any, linkTo: any): AggregationPoolEntity[] {
  return aggregationPools.map((item: AggregationPoolEntity) => {
    if (root[item.Name]) {
      for (const lp of item.Lps) {
        linkTo[lp.value] = true
      }
      return new AggregationPoolEntity({ ...item, highlighted: true, activeSwitches: true })
    }

    return item
  })
}

function fromLp(lps: any[], root: any): any[] {
  return lps.map((item: any) => {
    if (root[item.Name]) {
      return { ...item, highlighted: true }
    }

    item.highlighted = false

    return item
  })
}

function fromLpFind(lps: any[], root: any): any[] {
  return lps.map((item: any) => {
    if (root[item.Name]) {
      return { ...item, highlighted: true }
    }
    return item
  })
}

function fromLpActiveSwitchesFind(lps: any[], root: any): any[] {
  return lps.map((item: any) => {
    if (root[item.Name]) {
      item.activeSwitches = true
      item.highlighted = true
      return { ...item }
    }

    return item
  })
}

export default executionConfigurationReducer
