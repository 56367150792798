import { BackupActiveEntity, BackupRuleEntity } from '../../entity/backups-entity'
import { Action } from '../actions/actions'

class BackupsState {
  public backupsActive: any
  public backupsHistory: any
  public backupsRules: any

  constructor() {
    this.backupsActive = {
      data: [],
      totalCount: 0,
    }
    this.backupsHistory = {
      data: [],
      totalCount: 0,
    }
    this.backupsRules = []
  }
}

export function backupsReducer(state = new BackupsState(), action: any) {
  switch (action.type) {
    case Action.GotBackupsActiveInitial:
      return new BackupsState()
    case Action.GotBackupsActive:
      return {
        ...state,
        backupsActive: {
          data: action.data.Data.map((item: any) => new BackupActiveEntity(item)),
          totalCount: action.data.TotalCount,
        },
      }
    case Action.GotBackupsRules:
      return {
        ...state,
        backupsRules: action.data.map((item: any) => new BackupRuleEntity(item)),
      }
    default:
      return state
  }
}
