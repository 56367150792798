import { Action } from '../actions/actions'
import { ExtSymbolEntity } from '../../entity/platforms'

class ExtConfiguration {
  public extSymbolMap: any

  constructor() {
    this.extSymbolMap = {
      data: [],
      totalCount: 0,
    }
  }
}

function extConfigurationReducer(state = new ExtConfiguration(), action: any) {
  switch (action.type) {
    case Action.GotExtSymbolMap:
      return {
        ...state,
        extSymbolMap: {
          data: action.data.Data.map((item: any) => new ExtSymbolEntity(item)),
          totalCount: action.data.TotalCount,
        },
      }
    default:
      return state
  }
}

export default extConfigurationReducer
