import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import ColorCreator from './ColorCreator';
import ColorPickerButton from './ColorPickerButton';
import ColorPickerOption from './ColorPickerOption';
import { NONE } from './special-colors';
import { isNoneColor } from './utils';

const ColorPickerOptions = ({ opened, setPopperElement, styles, attributes, options, selected, handleAddColor }) => {
  const [colorCreatorOpened, setColorCreatorOpened] = useState(false);
  const [showButton, setShowButton] = useState(true);

  if (!opened) {
    return null;
  }

  const handleOpen = (event) => {
    setColorCreatorOpened(true);
  }

  const handleClose = (event) => {
    setColorCreatorOpened(false);
  }

  return (
    <div ref={setPopperElement} style={{...styles.popper, flexShrink: '0'}} {...attributes.popper}>
      <div className='color-picker_options-container' onClick={e => e.stopPropagation()}>

        <ColorPickerButton
          onClick={() => handleAddColor(NONE)}
          className='color-picker_button__top'
          disabled={selected.find(isNoneColor)}
        >
          None
        </ColorPickerButton>

        <div className='color-picker_options'>
          {options.map(option => (
            <ColorPickerOption
              key={option.name}
              color={option}
              onClick={handleAddColor}
              disabled={selected.find(item => item.name === option.name)}
            />))}
        </div>

        
        <CSSTransition
          in={colorCreatorOpened}
          timeout={300}
          classNames='color-creator'
          onEnter={() => setShowButton(false)}
          onExited={() => setShowButton(true)}
          unmountOnExit
        >
          <ColorCreator
            onCreate={handleAddColor}
            onClose={handleClose}
          />
        </CSSTransition>
        

        {showButton && (
          <ColorPickerButton
            onClick={handleOpen}
            className='color-picker_button__bottom'
          >
            Custom
          </ColorPickerButton>
        )}
      </div>
    </div>
  )
}

export default ColorPickerOptions;